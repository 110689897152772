import { useEffect, useState } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { Card4 } from '../../../../_metronic/partials/content/cards/Card4';
import axios from 'axios';
import { GET_EMPLOYEE_FAMILYMEMBERS_FOLDERS } from '../../../../apis';
import NoFiles from '../../../../_metronic/helpers/components/NoFiles';
import { BASE_URL_TWO } from '../../../../utils';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';

const EmployeeFamilyMemberPage = () => {
    const [employeesFolders, setEmployeesFolders] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    const { id } = useParams();
    const userId = Cookies.get('userId');
    const token = Cookies.get('token');

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    useEffect(() => {
        axios.get(`${GET_EMPLOYEE_FAMILYMEMBERS_FOLDERS}&userId=${userId}&employeeId=${id}`, { headers }).then((response) => {
            if (response.data && response.data.Data) {
                setEmployeesFolders(response?.data?.Data);
            }
        });
    }, [userId, id]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    }

    const filteredFiles = employeesFolders.flatMap(item => {
        const documents = JSON.parse(item.Column1);
        const matchingDocs = documents?.filter(doc => 
            doc.filename?.toLowerCase().includes(searchQuery.toLowerCase()) && doc.filename
        );

        if (matchingDocs?.length > 0) {
            return matchingDocs.map(doc => ({
                familyMemberName: item.familyMemberName,
                ...doc
            }));
        }

        return [];
    });

    return (
        <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
                <div className='d-flex my-2'>
                    <div className='d-flex align-items-center position-relative me-4'>
                        <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                        <input
                            type='text'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder='Search'
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
                <button className='btn btn-primary btn-sm' onClick={() => navigate(-1)}>Back</button>
            </div>

            {(!searchQuery && employeesFolders?.length === 0) && <NoFiles />}
            {(!searchQuery && employeesFolders?.length > 0) && employeesFolders.map((folder, index) => (
                <div className='col-12 col-sm-12 col-xl' key={index}>
                    <Card4
                        icon='/media/svg/files/download.svg'
                        title={folder?.familyMemberName}
                        check={true}
                        link={`/employer/hr/FilesView/${folder?.familyMemberId}`}
                    />
                </div>
            ))}

            {(searchQuery && filteredFiles?.length === 0) && <NoFiles />}
            {(searchQuery && filteredFiles?.length > 0) && filteredFiles.map((file, index) => (
                <div className='col-12 col-sm-12 col-xl' key={index}>
                    <Card4
                        icon='/media/svg/files/pdf.svg'
                        title={file?.filename}
                        link={`${BASE_URL_TWO}${file?.filePath}`}
                    />
                </div>
            ))}
        </div>
    )
}

export { EmployeeFamilyMemberPage }
