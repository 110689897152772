import React, { useRef, useState } from 'react'
import { Register } from '../../EmployerPages/HR/RegisterEmployees/Register';
import { Button, message } from "antd";
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as Yup from 'yup';
import axios from 'axios';
// import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { KTIcon, toAbsoluteUrl } from '../../../../../src/_metronic/helpers'
import { Grid } from '@mui/material';
import useFetchCommon from '../../../hooks/getCommonData';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import { SentimentVeryDissatisfiedOutlined } from '@mui/icons-material';
// import Cookies from 'js-cookie';


const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required("Email Id is required").email("Invalid email").matches(emailRegex, 'Invalid email'),
    password: Yup.string()
        .min(8, 'Minimum 8 characters')
        .max(50, 'Maximum 50 characters')
        .required('Password is required'),
    fname: Yup.string().required("First name is required"),
    lname: Yup.string().required("Last name is required"),
    compenyId: Yup.string().required("Company is required"),
    roleId:Yup.string().required("Role is required"),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Contact number is required"),
    vpassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Verify password')
});



export default function RegisterNewEmploye() {

    const navigate = useNavigate()

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const compenies = useGetDataWithFullUrl(`${BASE_URL}getCompaniesList&userId=${id}`, [shouldRefetch])?.fetchedData

    const roles = useGetDataWithFullUrl(`${BASE_URL}getRoles&userId=${id}`, [shouldRefetch])?.fetchedData


    const [compenyId, setCompenyId] = useState();

    const [roleId, setRoleId] = useState()

    // console.log("compentId", roles);

    const showMessage = () => {
        message.success({
            content: 'Succesfully Created',
            duration: 4 // Duration in seconds
        });
    };

    const a = () => {
        message.info({
            content: 'Some Problem to create new user',
            duration: 2
        });
    }

    const [image, setImage] = useState(null);

    const fileInputRef = useRef(null);

    const handlImageChange = (e) => {
        const file = e.target.files ? e.target.files[0] : ''
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const handleClear = () => {
        setImage('');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      };

    console.log("image", image);

    const initialValues = {
        // email: 'admin@demo.com',
        fname: '',
        lname: '',
        compenyId: '',
        roleId: '',
        email: '',
        password: '',
        phone: ''
    };



    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values, { resetForm }) => {

            console.log("values", values);
            axios.post(`${BASE_URL_TWO}`, {
                "sp": "insUser",
                "userId": id,
                "companyId": compenyId,
                "firstName": values?.fname,
                "lastName": values?.lname,
                "contactNo": values?.phone,
                "userName": values?.email,
                "roleId": roleId,
                "profileImage": image,
                "password": values?.password,
                // "phone": values?.phone
            }, { headers }).then((res) => {

                showMessage();
                resetForm();
                handleClear();

            }).catch((error) => {


                //a()
                message.error(error?.response?.data?.Message ? error?.response?.data?.Message : 'Failed to create staff');
            }
            )
        },
    });



    return (

        <>
            <Grid container sx={{ bgcolor: '', justifyContent: 'center', mt: { xs: 0, sm: 0, md: 0, md: - 5 } }}>



                <Grid lg={10} container sx={{
                    bgcolor: '', justifyContent: 'center', borderRadius: 4,
                    boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                }}>

                    <Grid container sm={11} xs={11} md={11} lg={10}>


                        <div className='w-100 my-6' >
                
                            <div className='pb-10 pb-lg-15'>
                                <h4 className='fw-bolder text-danger'>Add New staff</h4>

                                <div className='text-gray-400 fw-bold fs-7'>
                                    You need to enter staff information.
                                </div>
                            </div>

                            <form action="" onSubmit={formik.handleSubmit}>
                                <div className='row mb-5'>
                                    <div className='col-lg-40' onClick={handlImageChange}>
                                        <span>
                                            <div
                                                className='image-input image-input-outline col-lg-2'
                                                data-kt-image-input='true'
                                                onClick={handlImageChange}

                                            // style={{ backgroundImage: `url(${toAbsoluteUrl(`/media/avatars/blank.png')})` }}
                                            >
                                                <div
                                                    className='image-input-wrapper w-90px h-90px'
                                                    style={{
                                                        backgroundImage: `url(${toAbsoluteUrl(image ? image : '/media/avatars/blank.png')} )`
                                                    }}
                                                ></div>
                                                <label
                                                    style={{ color: ' #0000FF' }}
                                                    className="fw-bold fs-8 cursor-pointer"
                                                    htmlFor="photo">Select photo!</label>
                                                <input
                                                    type="file"
                                                    id="photo"
                                                    name="photo"
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    onChange={handlImageChange}
                                                    ref={fileInputRef} 
                                                >
                                                </input>
                                            </div>
                                        </span>
                                    </div>
                                </div>


                                <div className='row mb-3'>
                                    <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>First Name<span className='text-danger'>*</span></label>

                                    <div className='col-lg-3 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 fs-7 bg-body-secondary'
                                            placeholder=''
                                            autoComplete='off'
                                            name='fname'
                                            value={formik.values.fname}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span style={{ fontSize: '10px', color: 'red' }}>{formik.errors.fname}</span>

                                    </div>
                                    <label className='col-lg-1'></label>
                                    <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Last Name <span className='text-danger'>*</span></label>

                                    <div className='col-lg-3 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                                            placeholder=''
                                            name='lname'
                                            value={formik.values.lname}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span style={{ fontSize: '10px', color: 'red' }}>{formik.errors.lname}</span>

                                    </div>

                                </div>


                                <div className='row mb-3'>
                                    <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Company Name<span className='text-danger'>*</span></label>

                                    <div className='col-lg-3 fv-row'>
                                        <select
                                            name="compenyId"
                                            id=""
                                            className='form-select form-select-solid border fs-7 bg-body-secondary'
                                            onChange={(e) => {
                                                setCompenyId(e.target.value)
                                                formik.handleChange(e);
                                            }}
                                            value={compenyId}
                                        >
                                           <option value=''>Select Company</option>
                                            {
                                                compenies?.map((data, index) =>

                                                    <option value={data?.companyId}>{data?.companyName}</option>

                                                )
                                            }

                                        </select>
                                        <span style={{ fontSize: '10px', color: 'red' }}>{formik.errors.compenyId}</span>

                                    </div>
                                    <label className='col-lg-1'></label>
                                    <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Role <span className='text-danger'>*</span></label>

                                    <div className='col-lg-3 fv-row'>
                                        <select
                                            name="roleId"
                                            id=""
                                            className='form-select form-select-solid border fs-7 bg-body-secondary'
                                            onChange={(e) => {
                                                setRoleId(e.target.value)
                                                formik.handleChange(e);
                                            }}
                                            value={roleId}
                                        >
                                             <option value=''>
                                                   Select Role
                                                </option>
                                            {roles?.map((data, index) => (
                                                (data?.roleName !== 'Super Admin' && data?.roleName !== 'Employee') && (
                                                <option key={index} value={data?.roleId}>
                                                    {data?.roleName}
                                                </option>
                                                )
                                            ))}

                                        </select>
                                        <span style={{ fontSize: '10px', color: 'red' }}>{formik.errors.roleId}</span>

                                    </div>

                                </div>


                                <div className='row mb-3'>
                                    <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Email Id<span className='text-danger'>*</span></label>

                                    <div className='col-lg-3 fv-row'>
                                        <input
                                            type='email'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 fs-7 bg-body-secondary'
                                            placeholder=''
                                            autoComplete='off'
                                            name='email'
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span style={{ fontSize: '10px', color: 'red' }}>{formik.errors.email}</span>

                                    </div>
                                    <label className='col-lg-1'></label>


                                    <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Contact Number<span className='text-danger'>*</span></label>

                                    <div className='col-lg-3 fv-row'>
                                        <input
                                            type='number'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 fs-7 bg-body-secondary'
                                            placeholder=''
                                            autoComplete='off'
                                            name='phone'
                                            value={formik.values.phone}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span style={{ fontSize: '10px', color: 'red' }}>{formik.errors.phone}</span>

                                        <label className='col-lg-1'></label>


                                    </div>



                                </div>

                                <div className='row mb-3'>
                                    <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Password <span className='text-danger'>*</span></label>

                                    <div className='col-lg-3 fv-row'>
                                        <input
                                            type='password'
                                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                                            placeholder=''
                                            name='password'
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span style={{ fontSize: '10px', color: 'red' }}>{formik.errors.password}</span>

                                    </div>
                                    <label className='col-lg-1'></label>

                                    <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Verify Password <span className='text-danger'>*</span></label>

                                    <div className='col-lg-3 fv-row'>
                                        <input
                                            type='password'
                                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                                            placeholder=''
                                            name='vpassword'
                                            value={formik.values.vpassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span style={{ fontSize: '10px', color: 'red' }}>{formik.errors.vpassword}</span>

                                    </div>

                                </div>

                                <Grid container justifyContent="end">
                                    <button type='submit' className='btn btn-lg btn-primary mt-10 my-5'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='indicator-label'>
                                                Submit
                                            </span>
                                            <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                                        </div>
                                    </button>
                                </Grid>

                            </form>



                        </div>

                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}
