import React, { createContext, useState, useContext } from 'react';

// Create a context object
const FolderPathContext = createContext();

// Custom Provider component
export const FolderPathProvider = ({ children }) => {
  const [folderPath, setFolderPath] = useState([]);

  return (
    <FolderPathContext.Provider value={{ folderPath, setFolderPath }}>
      {children}
    </FolderPathContext.Provider>
  );
};

// Custom hook to use the context
export const useFolderPathContext = () => {
  return useContext(FolderPathContext);
};
