
import { Formik, useFormik } from 'formik';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import useFetchCommon from '../../../hooks/getCommonData';
import axios from 'axios';
import { BASE_URL_TWO } from '../../../../utils';
import Cookies from 'js-cookie';
import * as Yup from 'yup';
import { useState, useRef } from 'react';
import { debounce } from 'lodash';
import { Grid } from '@mui/material';
import { Button, message } from "antd";
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { htmlToText } from 'html-to-text';


const validationSchema = Yup.object().shape({
  contactDuringLeave: Yup.string()
    .required("Contact Number is required")
    .matches(/^[1-9]\d{7,14}$/, "Contact Number must be at least 8 characters"),
  dates: Yup.string().required("Date required"),
  policyId: Yup.string().required("Policy required"), // Rename to "Policy Type"
  comments: Yup.string(),
  //file: Yup.mixed().required("Document is required"),
})


const showMessage = () => {
  message.success({
    content: 'Leave request submited',
    duration: 1 // Duration in seconds
  });
};


const a = () => {
  message.info({
    content: 'Leave request not submited',
    duration: 2
  });
}



const Requestleaves = () => {

  const [showInitialText, setShowInitialText] = useState(true);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [policyId, setPolicyId] = useState();
  const [selectedTicket, setSelectedTicket] = useState('');
  const [startedDate, setStartedDate] = useState();
  const [endedDate, setEndedDate] = useState();
  const [dateState, setDateState] = useState({
    startDate: null,
    endDate: null,
    selectedDays: 0
  });

  let A

  const inputRef = useRef(null);

  const dropDownData = useFetchCommon('getLeaveTypes').commonDataFetch;

  // console.log("startedDate", startedDate);


  const Token = Cookies.get('token')
  const id = Cookies.get('userId')

  const headers = {
    'token': Token,
    'Content-Type': 'application/json'
  };

  const [base64String, setBase64String] = useState('');

  const toggleContent = () => {
    setShowInitialText(false);
    setShowAdditionalInfo(true);
  };

  const [stDate, setStDate] = useState("");
  const [enDate, setEnDate] = useState("")

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });




  const handleDateRangeChange = (event, picker) => {
    const startDate = picker.startDate.toDate();
    const endDate = picker.endDate.toDate();

    const formattedStartDate = startDate.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    });

    const formattedEndDate = endDate.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    });

    const startingDate = new Date(startDate)
    const day = startingDate.getDate().toString().padStart(2, '0');
    const month = (startingDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = startingDate.getFullYear();

    const formattedStartingDate = `${day}/${month}/${year}`;
    setStDate(formattedStartingDate)

    const endingDate = new Date(endDate)
    const day1 = endingDate.getDate().toString().padStart(2, '0');
    const month2 = (endingDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year3 = endingDate.getFullYear();

    const formattedEndingngDate2 = `${day1}/${month2}/${year3}`;

    setEnDate(formattedEndingngDate2)

    setDateRange({
      startDate: startDate,
      endDate: endDate,
    });

    const startDateInMs = new Date(`${year}-${month}-${day}`).getTime();
    const endDateInMs = new Date(`${year3}-${month2}-${day1}`).getTime();

    const differenceBtwDates = endDateInMs - startDateInMs;

    const aDayInMs = 24 * 60 * 60 * 1000;

    const daysDiff = Math.floor(differenceBtwDates / aDayInMs);

    const A = daysDiff;

    formik.setFieldValue('dates', `${formattedStartingDate} - ${formattedEndingngDate2} (${A} days)`);
  };


  const formatDateRange = (startDate, endDate) => {
    if (startDate instanceof Date && endDate instanceof Date) {
      const formattedStartDate = startDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      });

      const formattedEndDate = endDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      });

      if (formattedStartDate && formattedEndDate) {
        return `${formattedStartDate} - ${formattedEndDate} (${dateState.selectedDays} days)`;
      }
    }
    return '';
  };

  const handleRadioChange = (event) => {
    setSelectedTicket(event.target.value);
  };

  const [internationalPhone, setInternationalPhone] = useState()
  const [fileUploaded, setFileUploaded] = useState(false);
  const [dialCode,setDialCode] = useState()


  // const [base64String, setBase64String] = useState('');

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const base64 = reader.result;
        setBase64String(base64);
      };

      reader.readAsDataURL(file);

      setFileUploaded(true);

      formik.setFieldValue('file', file);
      formik.validateForm();
      validateFormDebounced();
    }
  };


  function formatDate(dateString) {
    const parts = dateString.split('/');
    const date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formatedStartingDate = formatDate(stDate)
  const formatedEndingDate = formatDate(enDate)

  const navigate = useNavigate()

  const oneStart = new Date(formatedStartingDate)
  const oneEnd = new Date(formatedEndingDate)

  const startDateInMs = oneStart.getTime();
  const endDateInMs = oneEnd.getTime();

  const differenceBtwDates = endDateInMs - startDateInMs;

  const aDayInMs = 24 * 60 * 60 * 1000;

  const daysDiff = Math.floor(differenceBtwDates / aDayInMs);



  const leaveDays = daysDiff

  A = leaveDays

  const formik = useFormik({
    initialValues: {
      contactDuringLeave: '',
      policyId: '',
      comments: '',
      dates: '',
      file: null,
      airlineTicket: ''
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.post(`${BASE_URL_TWO}`, {
          "sp": "insLeaveRequest",
          "userId": id,
          "contactDuringLeave": `${dialCode}-${internationalPhone}`,
          "startDate": formatedStartingDate,
          "endDate": formatedEndingDate,
          "policyId": policyId,
          "documentPath": base64String,
          "reason": values?.comments,
          "airlineTicket": selectedTicket
        }, { headers });
        resetForm();
        showMessage()
        navigate('/EmployeePages/Dashboard')

      } catch (error) {
        a()
      }
    }
  });

  const validateFormDebounced = debounce(formik.validateForm, 300);

  const { commonDataFetch } = useFetchCommon("getleaveGeneralTermsConditions")

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  return (
    <>
      <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>  {/* icon  */} Employee Request New Leave </h5>
      <div className="container-fluid mt-5">
        <div className="d-flex justify-content-center">
          <div className='card mb-5 mb-xl-10 w-75'>
            <div
              className='card-header border-0 cursor-pointer bg-primary'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0 text-white'>Request Leave</h3>
              </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
              <form onSubmit={formik.handleSubmit} className='form'>
                <div className='card-body border-top p-9 text-primary'>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required text-primary'>
                        <i className="bi bi-telephone-fill text-primary me-3 fs-3"></i>Contact During Leave
                      </span>
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-13 fv-row'>
                          <PhoneInput
                            defaultCountry="ua"
                            value={formik.values.contactDuringLeave}
                            country={'in'}
                            containerClass='col-lg-3'
                            inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary"
                            inputStyle={{ width: "100%", display: 'inline-block' }}
                            inputProps={{
                              name: 'contactDuringLeave',
                              required: true,
                            }} 
                            onChange={(value, country) => {
                              const countryCode = country.dialCode;
                              const phoneNumber = value.replace(countryCode, '').trim();
                              setInternationalPhone(phoneNumber);
                              setDialCode(countryCode);
                              formik.setFieldValue('contactDuringLeave', value);
                            }}
                          />

                          {formik.touched.contactDuringLeave && formik.errors.contactDuringLeave && (
                            <div className="text-danger fw-bold fs-8">{formik.errors.contactDuringLeave}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required text-primary'>
                        <i className="bi bi-calendar-check text-primary me-3 fs-2"></i> Date (Starting Date - Return to Office Date)
                      </span>
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <DateRangePicker
                            initialSettings={{
                              autoUpdateInput: false,
                              minDate: tomorrow, // Disable dates less than today
                            }}
                            onApply={(event, picker) => {
                              const startDate = picker.startDate;
                              const endDate = picker.endDate;
                              if (startDate.isSame(endDate, 'day')) {
                                alert('Start date and end date cannot be the same.');

                              } else {
                                handleDateRangeChange(event, picker);
                              }
                            }}
                          >
                            <div>
                              <input
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary'
                                type="text"
                                readOnly={true}
                                name='dates'
                                value={formik.values.dates}
                              />
                              {formik.touched.dates && formik.errors.dates && (
                                <div className="text-danger fw-bold fs-8">{formik.errors.dates}</div>
                              )}
                            </div>
                          </DateRangePicker>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required text-primary'> <i className="bi bi-collection text-primary me-3 fs-2"></i> Select Leave Type</span>
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-13 fv-row'>

                          <select
                            as="select"
                            name='policyId'
                            onChange={(event) => {
                              setPolicyId(event.target.value);
                              formik.setFieldValue('policyId', event.target.value);
                              formik.setFieldTouched('policyId', true);
                            }}
                            className="form-select form-select-solid bg-body-secondary border border-primary" aria-label="Select example">
                            <option value="">select an option</option>
                            {
                              dropDownData?.map((data, index) =>

                                <option value={data?.policyid}>{data?.policyTitle}</option>

                              )
                            }

                          </select>

                          {formik.touched.policyId && formik.errors.policyId && (
                            <span className="text-danger fw-bold fs-8" role='alert'>{formik.errors.policyId}</span>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='text-primary'> <i className="bi bi-file-earmark-check-fill  text-primary me-3 fs-2"></i> Upload Document </span>
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-13 fv-row'>
                          <input
                            type='file'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary fs-6'
                            placeholder=''
                            onChange={handleFileInputChange}
                            name='file'
                          />
                          <div className='fv-help-block'>
                            <span className="text-danger fw-bold fs-8" role='alert'>{formik.errors.file}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='text-primary'><i className="bi bi bi-text-left text-primary me-3 fs-2 "></i>Other / Comments </span>
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-13 fv-row'>
                          <textarea
                            className="form-control bg-body-secondary border border-primary"
                            name='comments'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.comments}
                          ></textarea>

                          <div className='fv-help-block'>
                            <span className="text-danger fw-bold fs-8" role='alert'>{formik.errors.comments}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='text-primary'> Airline Ticket </span>
                    </label>

                    <div className='col-lg-8' style={{ display: 'flex', alignItems: 'center' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <div className="form-check">
                            <input
                              className="form-check-input border border-primary"
                              type="radio"
                              value="Company"
                              name='airlineTicket'
                              id="radio1"
                              onChange={handleRadioChange}
                              checked={selectedTicket === 'Company'}
                            />
                            <label className="form-check-label fw-bold" htmlFor="radio1">
                              Company
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <div className="form-check">
                            <input
                              className="form-check-input border border-primary"
                              type="radio"
                              value="Self"
                              id="radio2"
                              name='airlineTicket'
                              onChange={handleRadioChange}
                              checked={selectedTicket === 'Self'}
                            />
                            <label className="form-check-label fw-bold" htmlFor="radio2">
                              Self
                            </label>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <div className="form-check">
                            <input
                              className="form-check-input border border-primary"
                              type="radio"
                              value="NA"
                              name='airlineTicket'
                              id="radio3"
                              onChange={handleRadioChange}
                              checked={selectedTicket === 'NA'}
                            />
                            <label className="form-check-label fw-bold" htmlFor="radio3">
                              NA
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className="container-fluid mt-5">
                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                      <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-bold">
                          <div className="fs-7 text-gray-600">

                            <div className="d-flex flex-column me-10" id="additional-info">
                              {
                                commonDataFetch?.map((data, index) =>

                                  <li className="d-flex align-items-center py-2">
                                    <span className="bullet bullet-dot bg-primary me-5 h-6px w-6px"></span><span>{htmlToText(data?.contentText)}</span>
                                  </li>

                                )
                              }


                            </div>
                            <div className='col-lg-9 fv-row'>
                              <span>
                                <div className='d-flex align-items-center mt-3'>
                                  <label className='form-check form-check-inline form-check-solid'>
                                    <input
                                      className='form-check-input border border-primary-subtle'
                                      name='communication[]'
                                      id="flexCheckDefault"
                                      type='checkbox'
                                      required
                                    />
                                    <span className='fw-bold ps-2 fs-6 required text-primary'>I have read and accepted all the terms & conditions.
                                    </span>
                                  </label>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex py-6 px-9 justify-content-end'>
                  <div className=''>
                    <button type="submit" className="btn btn-primary" >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>



    </>
  );


};

export default Requestleaves;