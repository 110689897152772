import React, { useState } from 'react';
import { KTIcon, KTSVG } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import useFetchData from '../../hooks/getData';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import { message } from "antd";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is  required'),
    policyNumber: Yup.string().required('Policy number is required'),
    // familyName: Yup.string().required('Family name is required'),
    // relationship: Yup.string().required('Relationship is required'),
    // gender: Yup.string().required('Gender is required'),
    // dob: Yup.string().required('DOB number is required'),
    // maritalStatus: Yup.string().required('marital status is required'),
    // placeOfWork: Yup.string().required('Place of work is required'),
    // nationality: Yup.string().required('Nationality is required'),
})

const EmployeeInsuranceForm = ({ onAdd, Refetch }) => {

    const [gender, setGender] = useState()
    const [maritalStatus, setMeritalSatus] = useState();
    const [nationality, setNationality] = useState()

    const [employeId, setEmployeName] = useState()

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const employeList = useFetchData('getEmployeesList').fetchedData;


    console.log("employeId", employeList);

    // const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getEmployeeDetailsForInsurance&employeeId=${employeId}&userId=${id}`, [shouldRefetch])

    // const details = fetchedData ? fetchedData[0] : '';

    const policyNumber = employeList ? employeList?.filter((data, index) => data?.userId == employeId)[0]?.healthInsurancePolicyNumber : ''

    console.log("policyNumber", policyNumber);

    const initialValues = {
        name: '',
        policyNumber: '',
        familyName: '',
        relationship: '',
        gender: '',
        dob: '',
        maritalStatus: '',
        placeOfWork: '',
        nationality: ''
    };

    const showMessage = () => {
        message.success({
            content: 'Created new insurance staff',
            duration: 1
        });
    };

    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            // console.log('values', values);
            axios.post(`${BASE_URL_TWO}`, {
                sp: 'insInsuredStaff',
                userId: id,
                employeeId: employeId,
                policyNo: policyNumber
            }, { headers }).then(() => {
                showMessage()
                Refetch(prevState => !prevState)
                setTimeout(function () {
                    window.location.reload()
                }, 2000);
            })
        }
    });

    const [showModal, setShowModal] = useState(true);

    return (


        <div className="modal fade" id="kt_modal_1" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">

            <form style={{ width: '100%' }} onSubmit={formik.handleSubmit} action="">

                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Employee Insurance Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <div className="modal-body">


                            <div className='row mb-1'>
                                <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Name</label>
                                <div className='col-lg-6 fv-row'>
                                    <select
                                        onChange={(e) => {
                                            setEmployeName(e.target.value)
                                            formik.handleChange(e)
                                            setShouldRefetch(prevState => !prevState)
                                        }}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="name"
                                        autoComplete='off'
                                        value={formik.values.name}
                                    >
                                        <option>Select employee</option>
                                        {
                                            employeList?.map((data, index) =>
                                                <option
                                                    value={data?.userId}>{data?.employeeName}</option>
                                            )
                                        }
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.name}</span>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary" >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );
};

export default EmployeeInsuranceForm;


















// <div className='row mb-1'>
// <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Family Name</label>
// <div className='col-lg-6 fv-row'>
//     <input
//         type='text'
//         className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
//         placeholder=''
//         name="familyName"
//         value={details?.familyName}
//         onChange={formik.handleChange}
//     />
//     <span style={{ color: 'red' }} role='alert'>{formik.errors.familyName}</span>
// </div>
// </div>

// <div className='row mb-1'>
// <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Relationship</label>
// <div className='col-lg-6 fv-row'>
//     <input
//         type='text'
//         className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
//         placeholder=''
//         name="relationship"
//         value={details?.relationship}
//         onChange={formik.handleChange}
//     />
//     <span style={{ color: 'red' }} role='alert'>{formik.errors.relationship}</span>
// </div>
// </div>

// <div className='row mb-1'>
// <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Gender</label>
// <div className='col-lg-6 fv-row'>

//     <select
//         type='text'
//         className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
//         placeholder=''
//         name="gender"
//         onChange={(e) => {
//             setGender(e.target.value)
//             formik.setFieldValue('gender', e.target.value)
//         }}
//         style={{ color: "" }}
//     >
//         <option>select</option>
//         <option value="male">Male</option>
//         <option value="female">Female</option>
//         <option value="other">Other</option>
//     </select>
//     <span style={{ color: 'red' }} role='alert'>{formik.errors.gender}</span>
// </div>
// </div>


// <div className='row mb-1'>
// <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Date of Birth</label>
// <div className='col-lg-6 fv-row'>
//     <input
//         type='date'
//         className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
//         placeholder=''
//         name="dob"
//         // value={details?.dateOfBirth}
//         onChange={formik.handleChange}
//     />
//     <span style={{ color: 'red' }} role='alert'>{formik.errors.dob}</span>
// </div>
// </div>


// <div className='row mb-1'>
// <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Marital Status</label>
// <div className='col-lg-6 fv-row'>
//     <select
//         type='text'
//         className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
//         placeholder=''
//         name="maritalStatus"
//         onChange={(e) => {
//             setMeritalSatus(e.target.value)
//             formik.setFieldValue('maritalStatus', e.target.value)
//         }}
//         style={{ color: "" }}
//     >
//         <option value="">-Select Marital Status-</option>
//         <option value="Single">Single</option>
//         <option value="Married">Married</option>
//         <option value="Widowed">Widowed</option>
//         <option value="Separated">Separated</option>
//         <option value="Divorced">Divorced</option>

//     </select>
//     <span style={{ color: 'red' }} role='alert'>{formik.errors.maritalStatus}</span>
// </div>
// </div>

// <div className='row mb-1'>
// <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Place of Work</label>
// <div className='col-lg-6 fv-row'>
//     <input
//         type='text'
//         className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
//         placeholder=''
//         name="placeOfWork"
//         value={details?.workplace}
//         onChange={formik.handleChange}
//     />
//     <span style={{ color: 'red' }} role='alert'>{formik.errors.placeOfWork}</span>

// </div>
// </div>


// <div className='row mb-1'>
// <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Nationality</label>
// <div className='col-lg-6 fv-row'>
//     <input
//         type='text'
//         className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
//         placeholder=''
//         name="nationality"
//         value={details?.nationality}
//         onChange={formik.handleChange}
//     />
//     <span style={{ color: 'red' }} role='alert'>{formik.errors.nationality}</span>
// </div>
// </div>