import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const CommonDataRefetch = (url, dependencies = []) => {

    const [fetchedData, setFetchedData] = useState();

    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axios.get(url);
                setFetchedData(response?.data?.Data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, dependencies);


    return { fetchedData, refetch: fetchedData };
};

export default CommonDataRefetch;