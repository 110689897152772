import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import { message } from "antd";
// import EditDivsion from './EditDivsion';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { KTIcon } from '../../../../_metronic/helpers';
import CreateDeductionType from './CreateDeductionType';
import CreateDepartment from '../Department/CreateDepartment';
import EditDepartment from '../Department/EditDepartment';
import EditDeductionType from './EditDeductionType';
import { faPersonWalkingDashedLineArrowRight } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../../../components/Modal/Modal';
import { Grid } from '@mui/material';



export default function DeductionType() {

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const showMessage = (msg) => {
        message.success({
            content: msg,
            duration: 1
        });
    };

    const a = () => {
        message.info({
            content: "This division can't delet",
            duration: 2
        });
    }

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const [dId, setId] = useState()

    const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getDeductionTypesList&userId=${id}`, [shouldRefetch])

    // console.log("fetchedData", fetchedData);

    const selectedDept = fetchedData?.filter((data, index) => data?.deductionTypeId == dId) ? fetchedData?.filter((data, index) => data?.deductionTypeId == dId)[0] : ''

    const [show, setShow] = useState();
    const [actionStatus, setActionStatus] = useState();
    const [deletId, setDeletId] = useState()

    useEffect(() => {
        if (actionStatus && deletId) {
            axios.post(`${BASE_URL_TWO}`, {
                "sp": "delDeductionTypes",
                "userId": id,
                "deductionTypeId ": deletId
            }, { headers })
                .then(() => {
                    showMessage("Deleted successfully");
                    setShouldRefetch(prevState => !prevState);
                    setActionStatus(false)
                })
                .catch(error => {
                    if (error.response) {
                        console.error("Server Error:", error.response.data);
                        a("Server Error: Please try again later.");
                    } else if (error.request) {
                        console.error("Network Error:", error.request);
                        a("Network Error: Please check your internet connection.");
                    } else {
                        console.error("Error:", error.message);
                        a("An unexpected error occurred. Please try again later.");
                    }
                });
        }
    }, [deletId, actionStatus]);

    return (
        <>

            <PopUp
                show={show}
                setShow={setShow}
                setActionStatus={setActionStatus}
                title="Delete Deduction type"
                message="Are you sure you want to delete"
                btnName="Delete"
            />

            <Grid container sx={{ justifyContent: 'end', mt: { xs: -1, sm: -1, md: -2, lg: - 5 } }}>

                <div className="card-toolbar me-5">
                    {/* Button to open the modal */}
                    <a href="/"
                        className="btn btn-md btn-light-primary border"
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        title="New Entry"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_2">
                        <KTIcon iconName="plus" className="fs-2" />
                        New Entry
                    </a>
                </div>

            </Grid>


            <CreateDeductionType onAdd={() => console.log('Add button clicked')} />

            <EditDeductionType selectedDept={selectedDept} />

            <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 bg-white gy-5 gs-7 shadow">
                <thead>

                    <tr className="fw-bold fs-5 bg-primary rounded text-white">

                        <th>Si no</th>
                        <th>Deduction</th>
                        <th>Action</th>

                    </tr>

                </thead>
                <tbody>

                    {
                        fetchedData?.map((data, index) =>
                            <tr>
                                <td className="text-primary fw-bold"><span className="badge">{index + 1}</span></td>
                                <td className="text-primary fw-bold">{data?.deductionTypeName}</td>



                                <td className="text-primary fw-bold">
                                    <DeleteIcon

                                        onClick={() => {
                                            setShow(true)
                                            setDeletId(data?.deductionTypeId)
                                        }}
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                    />

                                    <a href="/"
                                        onClick={() => setId(data?.deductionTypeId)}
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                        title="New Entry"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_3">
                                        <ModeEditIcon
                                            sx={{
                                                cursor: 'pointer',
                                                ml: 2
                                            }}
                                        />
                                    </a>

                                </td>





                            </tr>

                        )
                    }

                </tbody>
            </table>
        </>
    )
}
