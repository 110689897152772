import { useFormik } from 'formik';
import React, { useState } from 'react';
import { message } from "antd";
import axios from 'axios';
import * as Yup from 'yup';
import { KTSVG } from '../../../../_metronic/helpers';
import { BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object({
    fileName: Yup.string(),
    file: Yup.mixed().required('File is required'),
    fileN: Yup.string().required('File Name is required'),
});

export default function CompneyDocUpload({ paths, saveEmployeesFolderForEmployer }) {
    const [fileType, setFileType] = useState('');
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [folderName, setFolderName] = useState('root');
    const [newFolder, setNewFolder] = useState(false);
    const [base64String, setBase64String] = useState('');

    const headers = {
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'Successfully created',
            duration: 1
        });
    };

    const failedMessage = () => {
        message.info({
            content: 'Failed to create',
            duration: 1
        });
    };

    const formik = useFormik({
        initialValues: {
            fileName: '',  
            file: null,
            fileN: '',
        },
        validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            try {
                await axios.post(`${BASE_URL_TWO}Default.aspx`, {
                    "sp": saveEmployeesFolderForEmployer ? saveEmployeesFolderForEmployer : 'saveTDTMDocuments',
                    "pathToSave": folderName ? paths + '/' + folderName : paths,
                    "fileContent": base64String,
                    "Name": values.fileN,
                    "FileType": values.fileType,
                }, { headers });

                showMessage();
                setShouldRefetch(prevState => !prevState);
                setTimeout(() => { window.location.reload(); }, 1000);
                localStorage.clear();
            } catch (error) {
                console.error('Error occurred during POST request:', error);
                setStatus({ success: false });
                failedMessage();
            } finally {
                setSubmitting(false);
            }
        },
    });

    const handleFileInputChange = (event) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                setBase64String(base64);
            };
            reader.readAsDataURL(file);
            formik.setFieldValue("file", file);
        }
    };

    return (
        <div className="modal fade" id="kt_modal_2" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <form onSubmit={formik.handleSubmit}>
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <h4 className="modal-title text-primary" style={{ marginLeft: '20px', marginTop: "10px" }}>Current Folder: {folderName}</h4>

                        <div className="modal-body">
                            <div className="row g-3">

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasis">Folder Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='fileName'
                                        onChange={(e) => {
                                            setFolderName(e.target.value);
                                            formik.setFieldValue("fileName", e.target.value);
                                        }}
                                        value={formik.values.fileName}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.fileName}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasis">Browse File</label>
                                    <input
                                        type="file"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='file'
                                        onChange={handleFileInputChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.file}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasis">File Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='fileN'
                                        onChange={(e) => {
                                            formik.setFieldValue("fileN", e.target.value);
                                        }}
                                        value={formik.values.fileN}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.fileN}</span>
                                </div>

                                {(saveEmployeesFolderForEmployer === "insEmployeeFamilyMemberForFolderView") && (
                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasis">File Type</label>
                                        <select
                                            className="form-select form-select-lg form-select-solid fs-7 bg-body-secondary"
                                            aria-label="Select file type"
                                            onChange={(e) => {
                                                setFileType(e.target.value);
                                                formik.setFieldValue("fileType", e.target.value);
                                            }}
                                            value={formik.values.fileType}
                                        >
                                            <option value="">Select file type...</option>
                                            <option value="Passport">Passport</option>
                                            <option value="Visa">Visa</option>
                                            <option value="Resident Permit">Resident Permit</option>
                                            <option value="ID/RC">ID/RC</option>
                                            <option value="Photo">Photo</option>
                                            <option value="Agreement">Agreement</option>
                                            <option value="Others">Others</option>
                                        </select>
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.fileType}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Add
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
