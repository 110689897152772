import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { message } from 'antd';
import Cookies from 'js-cookie';
import { BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
});

const UpdateProfile = () => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  const showMessage = (content) => {
    message.success({
      content: content,
      duration: 2
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setSubmitting(true);
      const token = Cookies.get("token");
      const userId = Cookies.get("userId");

      axios.post(`${BASE_URL_TWO}`, {
        ...values,
        "sp": "updateAdminDetails",
        "userId": userId,
      }, {
        headers: {
          'token': `${token}`,
          'Content-Type': 'application/json'
        }
      }).then(() => {
        showMessage('Profile updated successfully');
        //resetForm();
      }).catch(error => {
        console.error('Error occurred during POST request:', error);
        setStatus({ success: false });
        message.error(error?.response?.data?.Message ? error?.response?.data?.Message : 'Failed to update profile');
      }).finally(() => {
        setSubmitting(false);
      });
    },
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = Cookies.get("token");
      const userId = Cookies.get("userId");

      try {
        const response = await axios.get(`${BASE_URL_TWO}?sp=spGetUser&userId=${userId}`, {
            headers: {
              'token': `${token}`,
              'Content-Type': 'application/json'
            }
          });

        if (response.data) {

          formik.setValues({
            firstName: response.data.Data[0].firstName,
            lastName: response.data.Data[0].lastName,
            email: response.data.Data[0].email,
            phoneNumber: response.data.Data[0].phoneNumber
          });
          setLoading(false);
        }
      } catch (error) {
        console.error('Error occurred during GET request:', error);
        message.error('Failed to fetch user details');
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h5 className="text-black-50 mb-10">
        <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>
        Update Profile
      </h5>
      <div className="container-fluid mt-5">
        <div className="d-flex justify-content-center">
          <div className='card mb-5 mb-xl-10 w-75'>
            <div
              className='card-header border-0 cursor-pointer bg-primary'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'
            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0 text-white'>Update Profile</h3>
              </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
              <form onSubmit={formik.handleSubmit} className='form'>
                <div className='card-body border-top p-9 text-primary'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required text-primary'>
                        <i className="bi bi-person-fill text-primary me-3 fs-3"></i>First Name
                      </span>
                    </label>
                    <div className='col-lg-8'>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <div className="text-danger fw-bold fs-8">{formik.errors.firstName}</div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required text-primary'>
                        <i className="bi bi-person-fill text-primary me-3 fs-3"></i>Last Name
                      </span>
                    </label>
                    <div className='col-lg-8'>
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                      />
                      {formik.touched.lastName && formik.errors.lastName && (
                        <div className="text-danger fw-bold fs-8">{formik.errors.lastName}</div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required text-primary'>
                        <i className="bi bi-envelope-fill text-primary me-3 fs-3"></i>Email
                      </span>
                    </label>
                    <div className='col-lg-8'>
                      <input
                        id='email'
                        name='email'
                        type='email'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="text-danger fw-bold fs-8">{formik.errors.email}</div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required text-primary'>
                        <i className="bi bi-phone-fill text-primary me-3 fs-3"></i>Phone Number
                      </span>
                    </label>
                    <div className='col-lg-8'>
                      <input
                        id='phoneNumber'
                        name='phoneNumber'
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                      />
                      {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                        <div className="text-danger fw-bold fs-8">{formik.errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex py-6 px-9 justify-content-end'>
                  <button type="submit" className="btn btn-primary" disabled={submitting}>
                    {submitting ? 'Updating...' : 'Update Profile'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { UpdateProfile };
