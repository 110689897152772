import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup'; // Ensure this import is correct based on your project setup
import { Grid, message } from 'antd';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { BASE_URL } from '../../../../../../utils';
import axios from 'axios';
// or '@mui/material' based on your project

const validationSchema = Yup.object().shape({
  familyMembers: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      dob: Yup.date(),
      passportNumber: Yup.string(),
      passportIssueDate: Yup.date(),
      passportExpiryDate: Yup.date(),
      iDRCNumber: Yup.string(),
      iDRCIssueDate: Yup.date(),
      iDRCExpiryDate: Yup.date(),
      gender: Yup.string(),
      nationality: Yup.string(),
      visaType: Yup.string(),
      healthInsurancePolicyNumber: Yup.string(),
      insurance: Yup.string(),
      email: Yup.string().email('Invalid email'),
      relationship: Yup.string(),
      famOrEmp:Yup.string(),
      maritalStatus: Yup.string(),
    })
  )
});

const Step4 = (props) => {
  const { setFamilyDetails, setSteps } = props;

  // const memberCount = Number(localStorage.getItem('memberCount'))
  const [calcAge, setCalcAge] = useState();
  const familyData = JSON.parse(localStorage.getItem('familyMembers'))
  const [visaTypes, setVisaTypes] = useState([]);
  const [famOrEmp,setFamOrEmp] = useState();
  const [numberOfFamilyMembers, setNumberOfFamilyMembers] = useState(familyData?.length);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      const response = await axios(`${BASE_URL}getvisaTypeForDropdown`)
      setVisaTypes(response?.data);
    }
    fetchDropdownOptions()
  }, [])

  const emptyObj = {
    firstName: '',
    lastName: '',
    dob: '',
    passportNumber: '',
    passportIssueDate: '',
    passportExpiryDate: '',
    iDRCNumber: '',
    iDRCIssueDate: '',
    iDRCExpiryDate: '',
    gender: '',
    nationality: '',
    visaType: '',
    healthInsurancePolicyNumber: '',
    insurance: '',
    email: '',
    age: "",
    relationship: '',
    famOrEmp:"",
    maritalStatus: '',
}


  useEffect(() => {
    setInitialValues({
      familyMembers: familyData?.length == numberOfFamilyMembers ? familyData : (familyData?.length < numberOfFamilyMembers && familyData?.length>0) ?[...familyData,...Array(numberOfFamilyMembers-familyData?.length).fill(emptyObj)] : Array(numberOfFamilyMembers).fill(emptyObj)
    });
  }, [numberOfFamilyMembers]);

  const [initialValues, setInitialValues] = useState({
    familyMembers: familyData?.length == numberOfFamilyMembers ? familyData : (familyData?.length < numberOfFamilyMembers && familyData?.length>0) ?[...familyData,...Array(numberOfFamilyMembers-familyData?.length).fill(emptyObj)] : Array(numberOfFamilyMembers).fill(emptyObj)
  });

  const showMessage = (msg) => {
    message.success({
      content: msg,
      duration: 4 // Duration in seconds
    });
  };

  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();

  const currentDate = `${year}-${month}-${day}`;

  const currentYear = new Date().getFullYear();



  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h4 className='fw-bolder text-danger'>Add Employee Family Members (if any)</h4>
        <div className='text-gray-400 fw-bold fs-7'>
          You need to enter the employee family members details.
        </div>
      </div>
      <div>
        <label htmlFor="familyMembersCount">Number of Family Members: </label>
        <select
          id="familyMembersCount"
          value={numberOfFamilyMembers}
          className='form-select form-select-solid border fs-7 bg-body-secondary'
          onChange={(e) => {
            if(e.target.value >familyData?.length && familyData?.length < initialValues?.familyMembers?.length){
              alert("please save last added members")
            }else{
              setNumberOfFamilyMembers(Number(e.target.value))
            }
          }}
        >
          <option >select an option</option>
          {[...Array(15).keys()].map((i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          )).slice(familyData?.length>0?familyData?.length-1:0)}
        </select>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          localStorage.setItem('familyMembers', JSON.stringify(values.familyMembers));
          setFamilyDetails(values.familyMembers)
          localStorage.setItem('memberCount', values.familyMembers?.length);
        }}
      >
        {({ values, errors, touched, setFieldValue,submitForm }) => (
          <Form>
            <FieldArray name="familyMembers">
              {({ remove }) => (
                <>
                  {values.familyMembers?.map((_, index) => (
                    <div key={index}>
                      <p className="fw-bold mb-5 mt-5">
                        <span className="badge badge-primary fs-9 me-3">
                          <i className="bi bi-person text-white fs-5 me-2"></i> no. {index + 1}
                        </span>
                        <span className='badge badge-primary fs-9 me-3' onClick={()=>{
                          if(familyData?.[index]){
                            familyData.splice(index,1)
                            localStorage.setItem('familyMembers', JSON.stringify(familyData))
                            localStorage.setItem('memberCount', familyData?.length);
                            setNumberOfFamilyMembers(familyData?.length);
                          }
                          remove(index)
                          setNumberOfFamilyMembers(numberOfFamilyMembers-1);
                          showMessage("deleted")
                        }}>
                          <i className='bi bi-trash text-white fs-5 me-1'></i>delete
                        </span>
                        {!familyData?.[index] && <span className="badge badge-primary fs-9 "  onClick={()=>{
                          submitForm();
                          showMessage("saved");
                          }}><i className="bi bi-file-earmark text-white fs-5 me-1"></i>save</span>}
                      </p>
                      <div className='row mb-1'>
                        <label htmlFor={`familyMembers.${index}.firstName`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>First Name</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type="text"
                            id={`familyMembers.${index}.firstName`}
                            name={`familyMembers.${index}.firstName`}
                            autoComplete='off'

                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '10px' }} name={`familyMembers.${index}.firstName`} component="div" className="error" />
                        </div>

                        <label className='col-lg-1'></label>

                        <label htmlFor={`familyMembers.${index}.lastName`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Last Name</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='text'
                            id={`familyMembers.${index}.lastName`}
                            name={`familyMembers.${index}.lastName`}
                            autoComplete='off'
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.lastName`} component="div" className="error" />
                        </div>
                      </div>

                      <div className='row mb-1'>
                        <label htmlFor={`familyMembers.${index}.dob`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Date of Birth</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='date'
                            id={`familyMembers.${index}.dob`}
                            name={`familyMembers.${index}.dob`}
                            autoComplete='off'
                            max={`${currentYear}-01-01`}
                            onChange={(e) => {
                              const dateOfBirth = e.target.value;
                              // calculateAge(dateOfBirth)
                              setFieldValue(`familyMembers.${index}.dob`, dateOfBirth);
                              setFieldValue(`familyMembers.${index}.age`, new Date(currentDate).getFullYear() - new Date(dateOfBirth).getFullYear());
                            }}
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.dob`} component="div" className="error" />
                        </div>

                        <label className='col-lg-1'></label>

                        <label htmlFor={`familyMembers.${index}.age`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Age</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='number'
                            id={`familyMembers.${index}.age`}
                            name={`familyMembers.${index}.age`}
                            autoComplete='off'
                            readOnly
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.age`} component="div" className="error" />
                        </div>
                      </div>

                      <div className='row mb-1'>
                        <label htmlFor={`familyMembers.${index}.insurance`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>insurance</label>
                        <div className='col-lg-3 fv-row'>
                        <Field
                            as='select'
                            className="form-select form-select-solid border fs-7 bg-body-secondary"
                            name={`familyMembers.${index}.insurance`}
                            id={`familyMembers.${index}.insurance`}
                          >
                             <option>select an option</option>
                             <option value="insured">Insured</option>
                             <option value="not insured">Not insured</option>
                          </Field>
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.insurance`} component="div" className="error" />
                        </div>

                        <label className='col-lg-1'></label>

                        <label htmlFor={`familyMembers.${index}.visaType`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Visa</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            as='select'
                            className="form-select form-select-solid border fs-7 bg-body-secondary"
                            name={`familyMembers.${index}.visaType`}
                            id={`familyMembers.${index}.visaType`}
                          >
                            <option>select an option</option>
                            {
                              visaTypes?.Data?.map((data) => (
                                <option value={data?.visaTypeId}>{data?.visaType}</option>
                              ))
                            }
                          </Field>
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.visaType`} component="div" className="error" />
                        </div>
                      </div>

                      <div className='row mb-1'>
                        <label htmlFor={`familyMembers.${index}.nationality`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Nationality</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-select form-select-solid border fs-7 bg-body-secondary'
                            as='select'
                            id={`familyMembers.${index}.nationality`}
                            name={`familyMembers.${index}.nationality`}
                            autoComplete='off'
                          >
                            <option value=''>Select country</option>
                            <option value='Afghanistan'>Afghanistan</option>
                            <option value='Aland Islands'>Aland Islands</option>
                            <option value='Albania'>Albania</option>
                            <option value='Algeria'>Algeria</option>
                            <option value='American Samoa'>American Samoa</option>
                            <option value='Andorra'>Andorra</option>
                            <option value='Angola'>Angola</option>
                            <option value='Anguilla'>Anguilla</option>
                            <option value='Antarctica'>Antarctica</option>
                            <option value='Antigua and Barbuda'>Antigua and Barbuda</option>
                            <option value='Argentina'>Argentina</option>
                            <option value='Armenia'>Armenia</option>
                            <option value='Aruba'>Aruba</option>
                            <option value='Australia'>Australia</option>
                            <option value='Austria'>Austria</option>
                            <option value='Azerbaijan'>Azerbaijan</option>
                            <option value='Bahamas'>Bahamas</option>
                            <option value='Bahrain'>Bahrain</option>
                            <option value='Bangladesh'>Bangladesh</option>
                            <option value='Barbados'>Barbados</option>
                            <option value='Belarus'>Belarus</option>
                            <option value='Belgium'>Belgium</option>
                            <option value='Belize'>Belize</option>
                            <option value='Benin'>Benin</option>
                            <option value='Bermuda'>Bermuda</option>
                            <option value='Bhutan'>Bhutan</option>
                            <option value='Bolivia, Plurinational State of'>Bolivia, Plurinational State of</option>
                            <option value='Bonaire, Sint Eustatius and Saba'>Bonaire, Sint Eustatius and Saba</option>
                            <option value='Bosnia and Herzegovina'>Bosnia and Herzegovina</option>
                            <option value='Botswana'>Botswana</option>
                            <option value='Bouvet Island'>Bouvet Island</option>
                            <option value='Brazil'>Brazil</option>
                            <option value='British Indian Ocean Territory'>British Indian Ocean Territory</option>
                            <option value='Brunei Darussalam'>Brunei Darussalam</option>
                            <option value='Bulgaria'>Bulgaria</option>
                            <option value='Burkina Faso'>Burkina Faso</option>
                            <option value='Burundi'>Burundi</option>
                            <option value='Cambodia'>Cambodia</option>
                            <option value='Cameroon'>Cameroon</option>
                            <option value='Canada'>Canada</option>
                            <option value='Cape Verde'>Cape Verde</option>
                            <option value='Cayman Islands'>Cayman Islands</option>
                            <option value='Central African Republic'>Central African Republic</option>
                            <option value='Chad'>Chad</option>
                            <option value='Chile'>Chile</option>
                            <option value='China'>China</option>
                            <option value='Christmas Island'>Christmas Island</option>
                            <option value='Cocos (Keeling) Islands'>Cocos (Keeling) Islands</option>
                            <option value='Colombia'>Colombia</option>
                            <option value='Comoros'>Comoros</option>
                            <option value='Congo'>Congo</option>
                            <option value='Congo, the Democratic Republic of the'>Congo, the Democratic Republic of the</option>
                            <option value='Cook Islands'>Cook Islands</option>
                            <option value='Costa Rica'>Costa Rica</option>
                            <option value='Croatia'>Croatia</option>
                            <option value='Cuba'>Cuba</option>
                            <option value='Curaçao'>Curaçao</option>
                            <option value='Cyprus'>Cyprus</option>
                            <option value='Czech Republic'>Czech Republic</option>
                            <option value='Denmark'>Denmark</option>
                            <option value='Djibouti'>Djibouti</option>
                            <option value='Dominica'>Dominica</option>
                            <option value='Dominican Republic'>Dominican Republic</option>
                            <option value='Ecuador'>Ecuador</option>
                            <option value='Egypt'>Egypt</option>
                            <option value='El Salvador'>El Salvador</option>
                            <option value='Equatorial Guinea'>Equatorial Guinea</option>
                            <option value='Eritrea'>Eritrea</option>
                            <option value='Estonia'>Estonia</option>
                            <option value='Ethiopia'>Ethiopia</option>
                            <option value='Falkland Islands (Malvinas)'>Falkland Islands (Malvinas)</option>
                            <option value='Faroe Islands'>Faroe Islands</option>
                            <option value='Fiji'>Fiji</option>
                            <option value='Finland'>Finland</option>
                            <option value='France'>France</option>
                            <option value='French Guiana'>French Guiana</option>
                            <option value='French Polynesia'>French Polynesia</option>
                            <option value='French Southern Territories'>French Southern Territories</option>
                            <option value='Gabon'>Gabon</option>
                            <option value='Gambia'>Gambia</option>
                            <option value='Georgia'>Georgia</option>
                            <option value='Germany'>Germany</option>
                            <option value='Ghana'>Ghana</option>
                            <option value='Gibraltar'>Gibraltar</option>
                            <option value='Greece'>Greece</option>
                            <option value='Greenland'>Greenland</option>
                            <option value='Grenada'>Grenada</option>
                            <option value='Guadeloupe'>Guadeloupe</option>
                            <option value='Guam'>Guam</option>
                            <option value='Guatemala'>Guatemala</option>
                            <option value='Guernsey'>Guernsey</option>
                            <option value='Guinea'>Guinea</option>
                            <option value='Guinea-Bissau'>Guinea-Bissau</option>
                            <option value='Guyana'>Guyana</option>
                            <option value='Haiti'>Haiti</option>
                            <option value='Heard Island and McDonald Islands'>Heard Island and McDonald Islands</option>
                            <option value='Holy See (Vatican City State)'>Holy See (Vatican City State)</option>
                            <option value='Honduras'>Honduras</option>
                            <option value='Hong Kong'>Hong Kong</option>
                            <option value='Hungary'>Hungary</option>
                            <option value='Iceland'>Iceland</option>
                            <option value='India'>India</option>
                            <option value='Indonesia'>Indonesia</option>
                            <option value='Iran, Islamic Republic of'>Iran, Islamic Republic of</option>
                            <option value='Iraq'>Iraq</option>
                            <option value='Ireland'>Ireland</option>
                            <option value='Isle of Man'>Isle of Man</option>
                            <option value='Israel'>Israel</option>
                            <option value='Italy'>Italy</option>
                            <option value='Jamaica'>Jamaica</option>
                            <option value='Japan'>Japan</option>
                            <option value='Jersey'>Jersey</option>
                            <option value='Jordan'>Jordan</option>
                            <option value='Kazakhstan'>Kazakhstan</option>
                            <option value='Kenya'>Kenya</option>
                            <option value='Kiribati'>Kiribati</option>
                            <option value='Korea'>Korea</option>
                            <option value='Kuwait'>Kuwait</option>
                            <option value='Kyrgyzstan'>Kyrgyzstan</option>
                            <option value='Lao People'>Lao People's Democratic Republic</option>
                            <option value='Latvia'>Latvia</option>
                            <option value='Lebanon'>Lebanon</option>
                            <option value='Lesotho'>Lesotho</option>
                            <option value='Liberia'>Liberia</option>
                            <option value='Libya'>Libya</option>
                            <option value='Liechtenstein'>Liechtenstein</option>
                            `<option value='Lithuania'>Lithuania</option>
                            <option value='Luxembourg'>Luxembourg</option>
                            <option value='Macao'>Macao</option>
                            <option value='Macedonia, the former Yugoslav Republic of'>Macedonia, the former Yugoslav Republic of</option>
                            <option value='Madagascar'>Madagascar</option>
                            <option value='Malawi'>Malawi</option>
                            <option value='Malaysia'>Malaysia</option>
                            <option value='Maldives'>Maldives</option>
                            <option value='Mali'>Mali</option>
                            <option value='Malta'>Malta</option>
                            <option value='Marshall Islands'>Marshall Islands</option>
                            <option value='Martinique'>Martinique</option>
                            <option value='Mauritania'>Mauritania</option>
                            <option value='Mauritius'>Mauritius</option>
                            <option value='Mayotte'>Mayotte</option>
                            <option value='Mexico'>Mexico</option>
                            <option value='Micronesia, Federated States of'>Micronesia, Federated States of</option>
                            <option value='Moldova, Republic of'>Moldova, Republic of</option>
                            <option value='Monaco'>Monaco</option>
                            <option value='Mongolia'>Mongolia</option>
                            <option value='Montenegro'>Montenegro</option>
                            <option value='Montserrat'>Montserrat</option>
                            <option value='Morocco'>Morocco</option>
                            <option value='Mozambique'>Mozambique</option>
                            <option value='Myanmar'>Myanmar</option>
                            <option value='Namibia'>Namibia</option>
                            <option value='Nauru'>Nauru</option>
                            <option value='Nepal'>Nepal</option>
                            <option value='Netherlands'>Netherlands</option>
                            <option value='New Caledonia'>New Caledonia</option>
                            <option value='New Zealand'>New Zealand</option>
                            <option value='Nicaragua'>Nicaragua</option>
                            <option value='Niger'>Niger</option>
                            <option value='Nigeria'>Nigeria</option>
                            <option value='Niue'>Niue</option>
                            <option value='Norfolk Island'>Norfolk Island</option>
                            <option value='Northern Mariana Islands'>Northern Mariana Islands</option>
                            <option value='Norway'>Norway</option>
                            <option value='Oman'>Oman</option>
                            <option value='Pakistan'>Pakistan</option>
                            <option value='Palau'>Palau</option>
                            <option value='Palestinian Territory, Occupied'>Palestinian Territory, Occupied</option>
                            <option value='Panama'>Panama</option>
                            <option value='Papua New Guinea'>Papua New Guinea</option>
                            <option value='Paraguay'>Paraguay</option>
                            <option value='Peru'>Peru</option>
                            <option value='Philippines'>Philippines</option>
                            <option value='Pitcairn'>Pitcairn</option>
                            <option value='Poland'>Poland</option>
                            <option value='Portugal'>Portugal</option>
                            <option value='Puerto Rico'>Puerto Rico</option>
                            <option value='Qatar'>Qatar</option>
                            <option value='Réunion'>Réunion</option>
                            <option value='Romania'>Romania</option>
                            <option value='Russian Federation'>Russian Federation</option>
                            <option value='Rwanda'>Rwanda</option>
                            <option value='Saint Barthélemy'>Saint Barthélemy</option>
                            <option value='Saint Helena, Ascension and Tristan da Cunha'>Saint Helena, Ascension and Tristan da Cunha</option>
                            <option value='Saint Kitts and Nevis'>Saint Kitts and Nevis</option>
                            <option value='Saint Lucia'>Saint Lucia</option>
                            <option value='Saint Martin (French part)'>Saint Martin (French part)</option>
                            <option value='Saint Pierre and Miquelon'>Saint Pierre and Miquelon</option>
                            <option value='Saint Vincent and the Grenadines'>Saint Vincent and the Grenadines</option>
                            <option value='Samoa'>Samoa</option>
                            <option value='San Marino'>San Marino</option>
                            <option value='Sao Tome and Principe'>Sao Tome and Principe</option>
                            <option value='Saudi Arabia'>Saudi Arabia</option>
                            <option value='Senegal'>Senegal</option>
                            <option value='Serbia'>Serbia</option>
                            <option value='Seychelles'>Seychelles</option>
                            <option value='Sierra Leone'>Sierra Leone</option>
                            <option value='Singapore'>Singapore</option>
                            <option value='Sint Maarten (Dutch part)'>Sint Maarten (Dutch part)</option>
                            <option value='Slovakia'>Slovakia</option>
                            <option value='Slovenia'>Slovenia</option>
                            <option value='Solomon Islands'>Solomon Islands</option>
                            <option value='Somalia'>Somalia</option>
                            <option value='South Africa'>South Africa</option>
                            <option value='South Georgia and the South Sandwich Islands'>South Georgia and the South Sandwich Islands</option>
                            <option value='South Korea'>South Korea</option>
                            <option value='South Sudan'>South Sudan</option>
                            <option value='Spain'>Spain</option>
                            <option value='Sri Lanka'>Sri Lanka</option>
                            <option value='Sudan'>Sudan</option>
                            <option value='Suriname'>Suriname</option>
                            <option value='Svalbard and Jan Mayen'>Svalbard and Jan Mayen</option>
                            <option value='Swaziland'>Swaziland</option>
                            <option value='Sweden'>Sweden</option>
                            <option value='Switzerland'>Switzerland</option>
                            <option value='Syrian Arab Republic'>Syrian Arab Republic</option>
                            <option value='Taiwan, Province of China'>Taiwan, Province of China</option>
                            <option value='Tajikistan'>Tajikistan</option>
                            <option value='Tanzania, United Republic of'>Tanzania, United Republic of</option>
                            <option value='Thailand'>Thailand</option>
                            <option value='Timor-Leste'>Timor-Leste</option>
                            <option value='Togo'>Togo</option>
                            <option value='Tokelau'>Tokelau</option>
                            <option value='Tonga'>Tonga</option>
                            <option value='Trinidad and Tobago'>Trinidad and Tobago</option>
                            <option value='Tunisia'>Tunisia</option>
                            <option value='Turkey'>Turkey</option>
                            <option value='Turkmenistan'>Turkmenistan</option>
                            <option value='Turks and Caicos Islands'>Turks and Caicos Islands</option>
                            <option value='Tuvalu'>Tuvalu</option>
                            <option value='Uganda'>Uganda</option>
                            <option value='Ukraine'>Ukraine</option>
                            <option value='United Arab Emirates'>United Arab Emirates</option>
                            <option value='United Kingdom'>United Kingdom</option>
                            <option value='United States'>United States</option>
                            <option value='Uruguay'>Uruguay</option>
                            <option value='Uzbekistan'>Uzbekistan</option>
                            <option value='Vanuatu'>Vanuatu</option>
                            <option value='Venezuela, Bolivarian Republic of'>Venezuela, Bolivarian Republic of</option>
                            <option value='Vietnam'>Vietnam</option>
                            <option value='Virgin Islands'>Virgin Islands</option>
                            <option value='Wallis and Futuna'>Wallis and Futuna</option>
                            <option value='Western Sahara'>Western Sahara</option>
                            <option value='Yemen'>Yemen</option>
                            <option value='Zambia'>Zambia</option>
                            <option value='Zimbabwe'>Zimbabwe</option>
                          </Field>
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.nationality`} component="div" className="error" />
                        </div>

                        <label className='col-lg-1'></label>

                        <label htmlFor={`familyMembers.${index}.healthInsurancePolicyNumber`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Health Insurance Policy Number</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='text'
                            id={`familyMembers.${index}.healthInsurancePolicyNumber`}
                            name={`familyMembers.${index}.healthInsurancePolicyNumber`}
                            autoComplete='off'
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.healthInsurancePolicyNumber`} component="div" className="error" />
                        </div>
                      </div>

                      <div className='row mb-1'>
                        <label htmlFor={`familyMembers.${index}.passportNumber`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Passport Number</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='text'
                            id={`familyMembers.${index}.passportNumber`}
                            name={`familyMembers.${index}.passportNumber`}
                            autoComplete='off'
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.passportNumber`} component="div" className="error" />
                        </div>

                        <label className='col-lg-1'></label>

                        <label htmlFor={`familyMembers.${index}.passportIssueDate`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Passport Issue Date</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='date'
                            id={`familyMembers.${index}.passportIssueDate`}
                            name={`familyMembers.${index}.passportIssueDate`}
                            autoComplete='off'
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.passportIssueDate`} component="div" className="error" />
                        </div>
                      </div>

                      <div className='row mb-1'>
                        <label htmlFor={`familyMembers.${index}.passportExpiryDate`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Passport Expiry Date</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='date'
                            id={`familyMembers.${index}.passportExpiryDate`}
                            name={`familyMembers.${index}.passportExpiryDate`}
                            autoComplete='off'
                            min={new Date().toLocaleDateString('en-CA')}
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.passportExpiryDate`} component="div" className="error" />
                        </div>

                        <label className='col-lg-1'></label>

                        <label htmlFor={`familyMembers.${index}.iDRCNumber`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>RC ID Number</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='text'
                            id={`familyMembers.${index}.iDRCNumber`}
                            name={`familyMembers.${index}.iDRCNumber`}
                            autoComplete='off'
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.iDRCNumber`} component="div" className="error" />
                        </div>
                      </div>

                      <div className='row mb-1'>
                        <label htmlFor={`familyMembers.${index}.iDRCIssueDate`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>RC ID Issue Date</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='date'
                            id={`familyMembers.${index}.iDRCIssueDate`}
                            name={`familyMembers.${index}.iDRCIssueDate`}
                            autoComplete='off'
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.iDRCIssueDate`} component="div" className="error" />
                        </div>

                        <label className='col-lg-1'></label>

                        <label htmlFor={`familyMembers.${index}.iDRCExpiryDate`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>RC ID Expiry Date</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='date'
                            id={`familyMembers.${index}.iDRCExpiryDate`}
                            name={`familyMembers.${index}.iDRCExpiryDate`}
                            autoComplete='off'
                            min={new Date().toLocaleDateString('en-CA')}
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.iDRCExpiryDate`} component="div" className="error" />
                        </div>
                      </div>

                      <div className='row mb-1'>
                        <label htmlFor={`familyMembers.${index}.gender`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Gender</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            as='select'
                            className="form-select form-select-solid border fs-7 bg-body-secondary"
                            name={`familyMembers.${index}.gender`}
                            id={`familyMembers.${index}.gender`}
                          >
                            <option>select an option</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Field>
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.gender`} component="div" className="error" />
                        </div>

                        <label className='col-lg-1'></label>

                        <label htmlFor={`familyMembers.${index}.relationship`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Relationship</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            as='select'
                            className="form-select form-select-solid border fs-7 bg-body-secondary"
                            name={`familyMembers.${index}.relationship`}
                            id={`familyMembers.${index}.relationship`}
                          >
                            <option>select an option</option>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Spouse">Spouse</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                          </Field>
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.relationship`} component="div" className="error" />
                        </div>
                      </div>

                      <div className='row mb-1'>
                        <label htmlFor={`familyMembers.${index}.maritalStatus`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Marital Status</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            as='select'
                            className="form-select form-select-solid border fs-7 bg-body-secondary"
                            name={`familyMembers.${index}.maritalStatus`}
                            id={`familyMembers.${index}.maritalStatus`}
                          >
                            <option>select an option</option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                            <option value="Divorced">Divorced</option>
                            <option value="Widowed">Widowed</option>
                          </Field>
                          <ErrorMessage style={{ color: 'red', fontSize: '10px' }} name={`familyMembers.${index}.maritalStatus`} component="div" className="error" />
                        </div>

                        <label className='col-lg-1'></label>

                        <label htmlFor={`familyMembers.${index}.email`} className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Email</label>
                        <div className='col-lg-3 fv-row'>
                          <Field
                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                            type='email'
                            id={`familyMembers.${index}.email`}
                            name={`familyMembers.${index}.email`}
                            autoComplete='off'
                          />
                          <ErrorMessage style={{ color: 'red', fontSize: '10px' }} name={`familyMembers.${index}.email`} component="div" className="error" />
                        </div>
                      </div>
                      <div className='row mb-1'>
                        <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>fam/employee<span className='text-danger'>*</span></label>
                        <div className='col-lg-3 fv-row'>
                        <Field
                            as='select'
                            className="form-select form-select-solid border fs-7 bg-body-secondary"
                            name={`familyMembers.${index}.famOrEmp`}
                            id={`familyMembers.${index}.famOrEmp`}
                          >
                            <option >select an option</option>
                            <option value="family">Family</option>
                            <option value="employee">Employee</option>
                          </Field>
                          <ErrorMessage style={{ color: 'red', fontSize: '12px' }} name={`familyMembers.${index}.famOrEmp`} component="div" className="error" />
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </FieldArray>

            <div className=' d-flex justify-between mb-5 mt-5 w-100 ' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className='mr-2'>
                <button
                  onClick={() => setSteps('step3')}
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                >
                  <KTIcon iconName='arrow-left' className='fs-4 me-1 mb-1' />
                  Back
                </button>
              </div>

              <button type='submit' className='btn btn-lg btn-primary' onClick={()=>{
                submitForm();
                setSteps('step5')
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className='indicator-label'>
                    Continue
                  </span>
                  <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0 mt-1' />
                </div>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { Step4 };
