import { useMemo, FC, useState, useEffect } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Box, IconButton } from '@mui/material';
import { data } from './makeData';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { KTIcon, KTSVG } from '../../../_metronic/helpers'
import { Archive, Delete, Edit } from '@mui/icons-material';
import { differenceInDays } from 'date-fns';
import Cookies from 'js-cookie';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import axios from 'axios';
import BankGuaranteeForm from './BankGuaranteeForm';
import { Button, message } from "antd";
import PopUp from '../Modal/Modal';
import EditDepartment from '../../pages/SuperAdminPages/Department/EditDepartment';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import BankGuaranteeFormEdit from './BankGuaranteeFormEdit';

export type BankGuaranteeDetails = {
    projectName: '',
    startDate: '',
    endDate: '',
    type: '',
    GuaranteeAmt: '',
    TotalAmt: '',
    percentageCompletion: '',
    stage: '',
    category: '',
    customerName: ''
};

const BankGuaranteeTable = () => {

    const [recievedData, setData] = useState(data)

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getBankGuaranteeDetails&userId=${id}`, [shouldRefetch]);

    const tableData = fetchedData || [];

    console.log("fetchedData", fetchedData);

    const [show, setShow] = useState();
    const [actionStatus, setActionStatus] = useState();
    const [deletId, setDeletId] = useState()

    useEffect(() => {
        if (actionStatus && deletId) {
            axios.post(`${BASE_URL_TWO}`, {
                "sp": "delBankGuarantee",
                "userId": id,
                "guaranteeId": deletId
            }, { headers })
                .then(() => {
                    showMessage();
                    setShouldRefetch(prevState => !prevState);
                    setActionStatus(false)
                })
                .catch(error => {
                    if (error.response) {
                        console.error("Server Error:", error.response.data);
                        a();
                    } else if (error.request) {
                        console.error("Network Error:", error.request);
                        a();
                    } else {
                        console.error("Error:", error.message);
                        a();
                    }
                });
        }
    }, [deletId, actionStatus]);

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.customerName}`, //accessorFn used to join multiple data into a single cell
                id: 'name',
                header: 'Client Name',
                size: 250,
                filterFn: 'multipleFilter',
                enablePinning: true,
                muiTableHeadCellProps: {
                    style: {
                        paddingLeft: '1rem'
                    }
                },
                muiTableBodyCellProps: {
                    style: {
                        paddingLeft: '1rem'
                    }
                },
                enableSorting: false, // Disable sorting
            },
            {
                accessorFn: (row) => new Date(row.startDate), //convert to Date for sorting and filtering
                id: 'StartDate',
                header: 'Start Date',
                filterFn: 'dateFilter',
                sortingFn: 'datetime',
                size: 200,
                enablePinning: false,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return date.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                },
                Header: ({ column }) => <em>{column.columnDef.header}</em>,
                enableSorting: false, // Disable sorting
            },
            {
                accessorFn: (row) => new Date(row.endDate),
                id: 'EndDate',
                header: 'End Date',
                filterFn: 'dateFilter',
                sortingFn: 'datetime',
                size: 200,
                enablePinning: false,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return date.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                },
                Header: ({ column }) => <em>{column.columnDef.header}</em>,
                enableSorting: false, // Disable sorting
            },
            {
                accessorKey: 'guaranteeType',
                header: 'Type of Guarantee',
                enablePinning: false,
                size: 200,
                enableSorting: false, // Disable sorting
            },
            {
                accessorKey: 'guaranteeAmount',
                filterFn: 'between',
                header: 'Guarantee Amount',
                enablePinning: false,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() < 50_000
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 50_000 &&
                                        cell.getValue() < 75_000
                                        ? theme.palette.warning.dark
                                        : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()?.toLocaleString?.('en-US', {
                            style: 'currency',
                            currency: 'OMR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                    </Box>
                ),
                enableSorting: false, // Disable sorting
            },
        ],
        []
    );


    const showMessage = () => {
        message.success({
            content: 'Deleted Bank Guarantee Report',
            duration: 1 // Duration in seconds
        });
    };

    const a = () => {
        message.info({
            content: "Failed to delete",
            duration: 2
        });
    }

    const [bId, setBid] = useState()

    const selectedData = fetchedData?.filter((data, index) => data?.guaranteeId == bId)

    const filterData = selectedData ? selectedData[0] : ''


    const exportToExcel = () => {
        const modifiedData = fetchedData.map(({ guaranteeId, projectName, completionPercentage, stageOfCompletion, category, ...rest }) => {
            const { customerName, ...remainingColumns } = rest;
            return { customerName, ...remainingColumns };
        });
        const worksheet = XLSX.utils.json_to_sheet(modifiedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `bankguarantee.xlsx`);
    };


    return (
        <div className={`card`}>

            <BankGuaranteeFormEdit selectedData={filterData} setShouldRefetch={setShouldRefetch} />

            <PopUp
                show={show}
                setShow={setShow}
                setActionStatus={setActionStatus}
                title="Deleted Bank Guarantee Report"
                message="Are you sure you want to delete"
                btnName="Delete"
            />


            {/* begin::Header */}
            <div className='card-header border-0 pt-5 pb-2'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Bank Guarantee Report</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>[{fetchedData?.length} entries]</span>
                </h3>
                <div className='card-toolbar'>

                    <div>
                        <div className="card-toolbar me-5">
                            {/* Button to open the modal */}
                            <a href="/"
                                className="btn btn-sm btn-light-primary border"
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                                title="New Entry"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_2">
                                <KTIcon iconName="plus" className="fs-2" />
                                New Entry
                            </a>
                        </div>

                        {/* Include the modal form */}
                        <BankGuaranteeForm onAdd={() => console.log('Add button clicked')} setShouldRefetch={setShouldRefetch} />
                    </div>
                    <div className='card-toolbar'>
                        <a onClick={exportToExcel} className='btn btn-sm btn-primary'>
                            <KTIcon iconName='file-down' className='fs-2' />
                            Export to Excel
                        </a>
                    </div>
                </div>
            </div>
            {/* end::Header */}
            <div className='card-body'>
                <MaterialReactTable
                    columns={columns}
                    data={tableData}
                    enableDensityToggle={false}
                    enableColumnFilters
                    enableColumnOrdering
                    enablePinning
                    enableRowActions
                    initialState={{
                        showColumnFilters: false,
                        sorting: [
                            { id: "name", desc: false },
                        ],
                        expanded: true,
                        columnPinning: { left: ["name"] }
                    }}
                    positionToolbarAlertBanner="bottom"
                    positionActionsColumn='last'
                    renderRowActions={({ row, table }) => [
                        <Box sx={{ justifyContent: 'center' }}>

                            <a href="/"
                                // onClick={() => setDptId(data?.deptId)}
                                onClick={() => setBid(fetchedData[row.index]?.guaranteeId)}
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                                title="New Entry"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_3">
                                <ModeEditIcon
                                    sx={{
                                        cursor: 'pointer',
                                        ml: 2
                                    }}
                                />
                            </a>

                            <IconButton

                                onClick={() => {
                                    setShow(true)
                                    setDeletId(fetchedData[row.index]?.guaranteeId)
                                }}

                            >
                                <Delete className='text-danger' />
                            </IconButton>

                            {/* <IconButton>
                                <Archive className='text-primary' />
                            </IconButton> */}

                        </Box>
                    ]}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Approval Action',
                            size: 100,
                            muiTableHeadCellProps: {
                                className: 'px-7 py-4 text-black',
                                sx: {
                                    justifyContent: 'center',
                                    verticalAlign: 'middle',
                                    backgroundColor: '#EBEBD3 !important',
                                    outline: '6px',
                                    outlineStyle: 'solid',
                                    outlineColor: 'var(--bs-light)',
                                    outlineOffset: '-5px',
                                    borderRadius: '20px',
                                }
                            }
                        },
                    }}
                    sortingFns={{
                        status: (rowA, rowB, columnID) => {
                            const valA = rowA.getValue < String > (columnID)
                            const valB = rowB.getValue < String > (columnID)
                            if (valA === 'Pending' && valB !== 'Pending') {
                                return 1
                            }
                            else if (valB === 'Pending' && valA !== 'Pending') {
                                return -1
                            }
                            else {
                                return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1
                            }
                        }
                    }}
                    filterFns={{
                        dateFilter: (row, columnId, filterValue) => {
                            const filterArray = filterValue.replaceAll(',', ';').split(';');
                            const cellVal = row.getValue(columnId);

                            if (typeof cellVal === 'string' || cellVal instanceof String) {
                                // Convert the cell value to a Date object
                                const cellDate = new Date(cellVal);

                                if (!isNaN(cellDate.getTime())) { // Check if it's a valid date
                                    for (let item of filterArray) {
                                        const trimmedItem = item.trim();
                                        const filterDate = new Date(trimmedItem);

                                        if (!isNaN(filterDate.getTime())) { // Check if filter date is valid
                                            // Compare the dates
                                            if (
                                                cellDate.getFullYear() === filterDate.getFullYear() &&
                                                cellDate.getMonth() === filterDate.getMonth() &&
                                                cellDate.getDate() === filterDate.getDate()
                                            ) {
                                                return true;
                                            }
                                        }
                                    }
                                }
                            }

                            return false;
                        }
                        ,
                        multipleFilter: (row, columnId, filterValue) => {
                            const filterArray = filterValue.replaceAll(',', ';').split(';');
                            const cellVal = row.getValue(columnId);
                            const cellValStr = typeof cellVal === 'string' ? cellVal : String(cellVal);
                            for (let item of filterArray) {
                                if (cellValStr.toLowerCase().includes(item.toLowerCase())) {
                                    return true;
                                }
                            }
                            return false;
                        }
                    }}

                    muiTablePaperProps={{
                        sx: {
                            backgroundColor: 'var(--bs-card-bg)',
                            border: 'none',
                            boxShadow: 'none'
                        }
                    }}
                    muiTableProps={{
                        className: 'table',
                        sx: {
                            borderCollapse: 'separate',
                            borderSpacing: '0px 4px',
                        }
                    }}
                    muiTableContainerProps={{
                        className: 'mb-2',
                        sx: {
                            backgroundColor: 'var(--bs-light)',
                            borderRadius: 'var(--bs-card-border-radius)',
                        }
                    }}
                    muiTableHeadRowProps={{
                        className: 'fw-bold bg-light',
                        sx: {
                            boxShadow: 'none'
                        }
                    }}
                    muiTableHeadCellProps={{
                        className: 'px-7 py-3 text-black',
                        sx: {
                            verticalAlign: 'middle',
                            backgroundColor: '#EBEBD3 !important',
                            outline: '10px',
                            outlineStyle: 'solid',
                            outlineColor: 'var(--bs-light)',
                            outlineOffset: '-5px',
                            borderRadius: '20px',
                        }
                    }}
                    muiTableBodyRowProps={{
                        hover: false,
                        sx: {
                            backgroundColor: 'transparent'
                        }
                    }}
                    muiTableBodyCellProps={{
                        className: 'bg-light bg-hover-secondary'
                    }}
                    muiTopToolbarProps={{
                        className: 'card text-light mb-2',
                        sx: {
                            boxShadow: 'none',
                            backgroundColor: '#143983'
                        },
                    }}
                    muiBottomToolbarProps={{
                        className: 'text-light mb-5 d-flex',
                        sx: {
                            boxShadow: 'none',
                            backgroundColor: '#143983',
                            borderRadius: 'var(--bs-card-border-radius)',
                            minHeight: '50px'
                        },
                    }}
                    muiSearchTextFieldProps={{
                        className: 'bg-light text-light text-muted'
                    }}
                    muiTableHeadCellFilterTextFieldProps={{
                        helperText: false,
                        focused: true,
                        inputProps: {
                            className: "text-black",
                        },
                        sx: {
                            color: 'black'
                        }
                    }}
                    muiTableHeadCellColumnActionsButtonProps={{
                        sx: {
                            color: 'black'
                        }
                    }}
                    muiTableHeadCellDragHandleProps={{
                        sx: {
                            color: 'black'
                        }
                    }}
                    icons={{
                        FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
                        FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
                        ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
                        FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                        FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                        SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
                        CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
                    }}
                    muiTablePaginationProps={{
                        className: 'text-white',

                    }}
                    enableGlobalFilter={false}
                    muiToolbarAlertBannerProps={{
                        className: 'text-white',
                        sx: {
                            backgroundColor: '#143983',
                        }
                    }}
                    muiToolbarAlertBannerChipProps={{
                        className: 'text-white'
                    }}
                />
            </div>
        </div >
    );
};

export default BankGuaranteeTable;