
import { FC, useEffect, useMemo, useState } from 'react';
import { TextField, Autocomplete, Grid, Rating } from '@mui/material'
import { Archive, Delete, Edit } from '@mui/icons-material';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import useFetchData from '../../../hooks/getData';
import useFetchEmployerData from '../../../hooks/useFetchEmployerData';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import { CardsWidget21 } from '../../../../_metronic/partials/widgets';
import { message } from 'antd';

const ReportKPI = () => {
  const presentYear = new Date().getFullYear();
  const [year, setYear] = useState(presentYear);
  const [employeeName, setEmployeeName] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [managerRatings, setManagerRatings] = useState([]);

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();  // Get current year
    return Array.from({ length: 5 }, (_, index) => (currentYear - 4) + index); // Generate array of years
  }, []);
  const employeNames = [];
  const employeList = useFetchData('getEmployeesList').fetchedData;
  employeList?.forEach((data) => employeNames.push({
    label: data?.employeeName,
    id: data?.userId
  }));

  const [userId, setUserId] = useState(employeList?.[0]?.userId);
  const [ratings, setRatings] = useState([]);
  const [comments, setComments] = useState([]);


  const id = Cookies.get('userId');
  const token = Cookies.get("token");

  const permission = Cookies.get('pemissions');
  let permissionArray = [];
  try {
    if (!permission) throw new Error("Permission data is undefined");
    permissionArray = JSON.parse(permission);
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }
  const role = permissionArray ? permissionArray[0]?.roleName : '';

  const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getKpiReport&userId=${id}&employeeId=${userId ? userId : employeList?.[0]?.userId}&year=${year}`, [shouldRefetch]);

  useEffect(() => {
    if (fetchedData) {
      setComments(fetchedData?.map((data) => ({
        comment1: JSON.parse(data?.LineManager1)?.managerComments ? JSON.parse(data?.LineManager1)?.managerComments : "",
        comment2: JSON.parse(data?.LineManager2)?.managerComments ? JSON.parse(data?.LineManager2)?.managerComments : ""
      })));
      setManagerRatings(fetchedData?.map((data) => ({
        rating1: JSON.parse(data?.LineManager1)?.managerRating ? JSON.parse(data?.LineManager1)?.managerRating : 0,
        rating2: JSON.parse(data?.LineManager2)?.managerRating ? JSON.parse(data?.LineManager2)?.managerRating : 0
      })));
    }
  }, [fetchedData]);

  const handleTextAreaChange = (index, area, event) => {
    const newComments = [...comments];
    newComments[index][area] = event.target.value;
    setComments(newComments);
  };

  const handleRating = (index, area, e) => {
    const newRatings = [...managerRatings];
    newRatings[index][area] = e.target.value;
    setManagerRatings(newRatings);
  };

  // const handleRating = (index, event, value) => {
  //   if (value !== null) {
  //     const newRatings = [...ratings];
  //     newRatings[index] = value;
  //     setRatings(newRatings);
  //   }
  // };

  const [type, setType] = useState("All")
  const [days, setdays] = useState({
    Sick: 0,
    Marriage: 0,
    Maternity: 0,
    Annual: 0,
    Compassionate: 0,
    Unpaid: 0,
    Hajj: 0,
    Paternity: 0,
    Flex:0
})
  const leaveTrackingDetails = useGetDataWithFullUrl(`${BASE_URL}getEmployeeLeaveTrackerDetails&userId=${id}&employeeId=${userId ? userId : employeList?.[0]?.userId}&year=${year}`, [shouldRefetch]).fetchedData;


  const handleSubmit = async () => {
    if(fetchedData && fetchedData?.length!=0){
      const dataToSend = fetchedData.map((data, index) => ({
        question: data.questionText,
        lineManagerOne: role === "LineManager1" ? comments[index].comment1 : comments[index].comment2,
        rating: role === "LineManager1" ? managerRatings[index].rating1 : managerRatings[index].rating2,
        employeeKpiAnswerId: data?.employeeKpiAnswerId
      }));
  
      const filteredData = dataToSend.filter((obj) =>
        Object.values(obj).every((value) => value !== null && value !== '')
      );
  
      const headers = {
        'token': token,
        'Content-Type': 'application/json'
      };
  
      try {
        await Promise.all(
          filteredData.map(async (data) => {
            await axios.post(`${BASE_URL_TWO}`, {
              sp: "insLineManagerKpiRatings",
              userId: id,
              employeekpiAnswerId: data.employeeKpiAnswerId,
              rating: data.rating,
              comments: data.lineManagerOne
            }, { headers });
          })
        );
        showMessage("Successfully saved")
        setTimeout(()=>{
          window.location.reload();
        },1000)
      } catch (error) {
        console.error("Error in POST request:", error);
      }
    }else{
      failedMsg("No data in the table")
    }
  };

  const showMessage = (msg) => {
    message.success({
      content: msg,
      duration: 4 // Duration in seconds
    });
  };

  const failedMsg = (msg) => {
    message.info({
      content: msg,
      duration: 2
    });
  }


  return (
    <>
      <h1 className="pb-10" style={{ color: "#2f574b" }}>
        <i className="bi bi-file-earmark-bar-graph-fill fs-2hx px-1 svg-icon-muted" style={{ color: "#2f574b" }}></i> KPI Report
      </h1>

      {employeNames[0] && <div className='row g-5 g-xl-8 mb-md-5 mb-xl-5 d-flex justify-content-evenly'>
        <div className='col-md-5 col-xl-5 me-13'>
          <div className='card h-md-auto mb-8'>
            <div className='card-body'>
              <h5 className='text-primary'>
                <i className="bi bi-person-fill fs-2 me-3 svg-icon-muted text-primary"></i>Select Employee Name
              </h5>
              <Autocomplete
                fullWidth
                color="primary"
                options={employeNames}
                defaultValue={employeNames?.[0]}
                onInputChange={(event, value) => {
                  setEmployeeName(value);
                  setShouldRefetch((prev) => !prev);
                }}
                onChange={(event, value) => {
                  setEmployeeName(value);
                  setUserId(value?.id)
                  setShouldRefetch((prev) => !prev);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    focused
                    label="Employee Name"
                  />
                )}
              />
              <h5 className='text-primary mb-3'>
                <i className="bi bi-calendar-check fs-2 me-3 svg-icon-muted text-primary"></i>Select Year
              </h5>
              <Autocomplete
                disablePortal
                fullWidth
                options={yearOptions}
                value={year.toString()}
                onInputChange={(event, value) => {
                  setYear(parseInt(value ?? presentYear.toString(), 10));
                  setShouldRefetch((prev) => !prev);
                }}
                onChange={(event, value) => {
                  setYear(parseInt(value ?? presentYear.toString(), 10));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    focused
                    label="Year"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <Grid container sm={11} xs={11} md={6} lg={6} sx={{ bgcolor: '' }}>
          {
            leaveTrackingDetails?.map((data, index) => {

              return (
                <Grid container sx={{ bgcolor: '', height: 'fit-content' }} xs={6} sm={6} md={4} lg={4}>
                  <CardsWidget21
                    className='h-md-20 mb-5'
                    color='#143983'
                    img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                    title={data?.policytitle}
                    description={days.Sick.toString()}
                    onClick={() => setType(data?.policytitle)}
                    availablecount={data?.availablecount}
                    totalcount={data?.totalcount ? data?.totalcount : 0}
                  />
                </Grid>
              )
            }
            )
          }
        </Grid>
      </div>}

      <div className="p-5" style={{ overflowX: 'auto' }}>
        <table className="table custom-table table-rounded border-3 border-gray-100 table-bordered gy-5 gs-7 shadow" style={{ background: "#e8e6e6", width: "2400px" }}>
          <thead>
            <tr className="fw-bold fs-6 border-bottom border-gray-200 text-white bg-primary">
              <th className="KPIyear" colSpan={3} style={{ background: "#2f574b" }}>
                <span className='badge badge-light-success fs-6 fw-bold'> {year}</span>
              </th>
              <th colSpan={2} style={{ background: "#DF9B00", color: "#2f574b" }}>
                Line Manager
                <i className="bi bi-1-square fs-2x px-2 svg-icon-muted" style={{ color: "#2f574b", display: role === 'LineManager1' ? '' : 'none' }}></i>
                <i className="bi bi-2-square fs-2x px-2 svg-icon-muted" style={{ color: "#2f574b", display: role === 'LineManager2' ? '' : 'none' }}></i>
              </th>
            </tr>
            <tr className="fw-bold fs-6 border-bottom border-gray-200 text-white" style={{ background: "#2f574b" }}>
              <th><i className="bi bi-question-square-fill fs-2x px-5 svg-icon-muted text-white"></i>Question</th>
              <th>Employee Rating</th>
              <th>Comments</th>
              <th>Rating</th>
              <th>Comments / Suggestions</th>
            </tr>
          </thead>
          <tbody>
            {(!fetchedData || fetchedData?.length==0) && <tr><td colSpan={7} className='text-center fw-bold'>No data found</td></tr> }
            {fetchedData?.map((data, index) => (
              <tr key={index}>
                <td className="fw-bold" style={{ width: "500px", color: "#2f574b" }}>
                  {data?.questionText}
                </td>
                <td className="fw-bold">
                  <Rating name="read-only" value={data?.employeeRating} readOnly sx={{ fontSize: '2rem' }} />
                </td>
                <td className="fw-bold" style={{ width: "500px", color: "#2f574b" }}>
                  {data?.employeeComments}
                </td>
                {role === "LineManager1" && (
                  <>
                    <td className="text-primary fw-bold">
                      <Rating name="half-rating" value={managerRatings[index]?.rating1 || JSON.parse(data?.LineManager1)?.managerRating || 0} onChange={(e) => handleRating(index, "rating1", e)}
                        sx={{ fontSize: "2rem" }} precision={0.5} />
                    </td>
                    <td className="fw-bold" style={{ width: "500px", color: "#2f574b" }}>
                      <textarea
                        className="form-control"
                        value={comments[index]?.comment1 || JSON.parse(data?.LineManager1)?.managerComments || ""}
                        onChange={(e) => handleTextAreaChange(index, 'comment1', e)}
                      ></textarea>
                    </td>
                  </>
                )}
                {role === "LineManager2" && (
                  <>
                    <td className="text-primary fw-bold">
                      <Rating name="half-rating" value={managerRatings[index]?.rating2 || JSON.parse(data?.LineManager2 )?.managerrating || 0} onChange={(e, value) => handleRating(index, "rating2", e)}
                        sx={{ fontSize: "2rem" }} precision={0.5} />
                    </td>
                    <td className="fw-bold" style={{ width: "500px", color: "#2f574b" }}>
                      <textarea
                        className="form-control"
                        value={comments[index]?.comment2 || JSON.parse(data?.LineManager2)?.managerComments || ""}
                        onChange={(e) => handleTextAreaChange(index, 'comment2', e)}
                      ></textarea>
                    </td>
                  </>
                )}
              </tr>
            ))}
            <tr className='text-success fw-bold gy-1' style={{ background: "#DF9B00" }}>
              <td colSpan={7}></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <button onClick={handleSubmit} className="btn btn-sm" type="button" style={{ background: "#2f574b", color: "#fff" }}>
          <i className="bi bi-check-circle-fill fs-2 svg-icon-muted text-white me-3"></i>Submit
        </button>
      </div>
    </>
  );
};

export { ReportKPI };