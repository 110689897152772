import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import Cookies from 'js-cookie';
import useGetDataWithFullUrl from '../../../../app/hooks/getDataWithFullUrl';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import axios from 'axios';
import BillModal from '../../../../app/components/RenewalCompanyDocument/BillModal';
import EditBillModal from '../../../../app/components/RenewalCompanyDocument/EditBillModal';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PopUp from '../../../../app/components/Modal/Modal';
import { message } from 'antd';

const TablesWidget15 = ({ className }) => {
  const now = new Date();
  const nowMonth = now.getMonth();

  const [clickedItem, setClickedItem] = useState(null);
  const [monthData, setMonthData] = useState(null);
  const [showBillModal, setShowBillModal] = useState(false);
  const [showEditBillModal, setShowEditBillModal] = useState(false);

  const [show, setShow] = useState();
  const [actionStatus, setActionStatus] = useState(false);
  const [deletId, setDeletId] = useState()

  const id = Cookies.get('userId');
  const token = Cookies.get('token');

  const [shouldRefetch, setShouldRefetch] = useState(false);

  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };

  const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getUtilityBillsDetails&userId=${id}`, [shouldRefetch]);

  const utilityDetails = fetchedData ? fetchedData.filter((data, index) => clickedItem === index)[0]?.utilityDetails : '';

  let utilityDetailsArray = [];

  if (utilityDetails) {
    try {
      utilityDetailsArray = JSON.parse(utilityDetails);
    } catch (error) {
      console.error('Error parsing utility details:', error);
    }
  }

  const handleOpenBillModal = () => setShowBillModal(true);
  const handleCloseBillModal = () => setShowBillModal(false);
  
  const handleOpenEditBillModal = (data) => {
    setMonthData(data);
    setShowEditBillModal(true);
  };

  const handleCloseEditBillModal = () => {
    setShowEditBillModal(false);
    setMonthData(null);
  };

  const handleDelete = async (utilityId) => {
    setDeletId(utilityId)
    setShow(true)
  };

  useEffect(()=>{
      if(actionStatus && deletId){
        try {
           axios.post(`${BASE_URL_TWO}`, {
            sp: "delUtilityBills",
            userId: id,
            utilityId: deletId
          }, { headers }).then(()=>{
            message.success("Deleted Successfully")
            setShouldRefetch(prevState => !prevState);
            setActionStatus(false)
            setShow(false)
          })
          
        } catch (error) {
          console.error('Error deleting utility bill:', error);
        }
      }
  },[actionStatus,deletId])

  const handleExportToExcel = () => {
    const dataToExport = fetchedData?.reduce((acc, data) => {
      const details = JSON.parse(data.utilityDetails || '[]');
      const formattedDetails = details.map(detail => {
        const { utilityId, isAnnual, ...rest } = detail; // Destructure and exclude utilityId and isAnnual
        return {
          'Month of Bill': data.monthOfBill,
          ...rest,
        };
      });
  
      return [...acc, ...formattedDetails];
    }, []);
  
    const uniqueColumns = Array.from(new Set(dataToExport.flatMap(Object.keys))); // Ensure unique columns
    const ws = XLSX.utils.json_to_sheet(dataToExport, { header: uniqueColumns });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Utility Bills');
  
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
    const blob = new Blob([buf], { type: 'application/octet-stream' });
  
    saveAs(blob, 'utility_bills.xlsx');
  };
  
  return (
    <>
    <PopUp
    show={show}
    setShow={setShow}
    setActionStatus={setActionStatus}
    title="Delete The Bill"
    message="Are you sure you want to delete"
    btnName="Delete"
  />
    <div className={`card ${className}`}>
      {/* Header */}
      <div className='card-header border-0 pt-5 pl-10'>
        <h3 className='card-title px-5'>
          <span className='card-label fw-bold fs-3 mb-1'>Utility Bills</span>
        </h3>
        <div className='card-toolbar'>
          {/* Menu */}
          <div className='card-toolbar me-5'>
            <button onClick={handleExportToExcel} className='btn btn-sm btn-primary'>
              Export to Excel
            </button>
          </div>
          <div className='card-toolbar me-5'>
            <button onClick={handleOpenBillModal} className='btn btn-sm btn-primary'>
              Add Bill
            </button>
          </div>
        </div>
        <div className='d-flex justify-content-center container-fluid'>
          <ul className='nav'>
            {fetchedData?.map((data, index) => (
              <li onClick={() => setClickedItem(index)} className='nav-item' key={index}>
                <a
                  className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-9 me-1 ${index === nowMonth ? 'active' : ''}`}
                  data-bs-toggle='tab'
                  href={`#kt_table_widget_8_tab_${index + 1}`}
                >
                  {data?.monthOfBill}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
     
      {/* Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {fetchedData?.map((month, index) => (
            <div
              className={`tab-pane fade ${index === nowMonth ? 'show active' : ''}`} id={`kt_table_widget_8_tab_${index + 1}`}
              key={index}
            >
              <div className='align-middle gs-0 gy-3'>
                {utilityDetailsArray.map((monthDataItem, idx) => (
                  <div className='d-flex align-items-center mb-2 bg-hover-light' key={idx}>
                    <span className='bullet bullet-vertical h-40px bg-primary'></span>
                    <div className='flex-grow-1 mx-3'>
                      <span className='text-gray-800 text-hover-primary fw-bold fs-6'>
                        {monthDataItem?.utilityTitle}
                      </span>
                    </div>
                    <div className='d-flex p-2 justify-content-between' style={{ flex: '0 0 35%' }}>
                      <div className='d-flex flex-column justify-content-start mx-2'>
                        <span className={`datafield badge fw-bold d-block fs-7 ${monthDataItem.status ? 'badge-light-success' : 'badge-light-danger'}`} contentEditable="true">{monthDataItem.amount}</span>
                        <span className={`badge fw-bold d-block fs-7 ${monthDataItem.status ? 'badge-light-success' : 'badge-light-danger'}`}>OMR</span>
                      </div>
                      <div>
                        <IconButton className='text-success' onClick={() => handleOpenEditBillModal(monthDataItem)}>
                          <Edit className='fs-1' />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(monthDataItem?.utilityId)} className='text-danger'>
                          <Delete className='fs-1' />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* End Body */}

      {/* Bill Modal */}
      {showBillModal && (
        <BillModal onClose={handleCloseBillModal} />
      )}

      {/* Edit Bill Modal */}
      {showEditBillModal && (
        <EditBillModal
          billData={monthData}
          onClose={handleCloseEditBillModal}
          setShouldRefetch={setShouldRefetch}
        />
      )}
    </div>
    </>
  );
};
 
export { TablesWidget15 };
