import { FC } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import RenewalStatusTable from '../../../components/EmployeeDocumentRenewalTable/DocumentRenewalTable'
import { ListsWidget12 } from '../../../../_metronic/partials/widgets/lists/ListWidget12'
import useFetchData from '../../../hooks/getData'
import { IconButton, Grid } from '@mui/material';
import { Upload, Download, GridView, DocumentScanner, Wallpaper, CheckBoxRounded } from '@mui/icons-material'
import { BASE_URL_TWO } from '../../../../utils'
import { message } from 'antd'



const RenewalStatusPage: FC = () => {

  const { fetchedData } = useFetchData('getEmployeeDocuments')

  return (
    <>

      <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>  {/* icon  */} My Documents / Family Members Documents</h5>

      <div className='row g-5 g-xl-10 mb-md-4 mb-xl-10 d-flex flex-wrap align-content-stretch'>

        {
          fetchedData?.map((data, index) => {

            const details = JSON.parse(data.documentdetails)

            return (
              <div className="col-md-12 col-l-6 col-xl-4">


                <div className={`card h-100}`}>
                  {/* begin::Header */}
                  <div className='card-header border-0'>
                    <h3 className='card-title fw-bold text-primary'>{data?.firstname}</h3>
                  </div>
                  {/* end::Header */}
                  {/* begin::Body */}
                  <div className='card-body pt-2'>
                    {
                      details?.map((item, index) => {
                        const date = item.expirydate
                        const nowDate = new Date().toLocaleDateString('en-US', {
                          day: 'numeric',
                          month: 'numeric',
                          year: 'numeric'
                        });

                        const daysdiff = Math.ceil((new Date(date) - new Date(nowDate)) / (1000 * 60 * 60 * 24))

                        return (
                          <div className='d-flex align-items-center mb-8'>
                            <span className='bullet bullet-vertical h-40px bg-primary'></span>
                            {/* <div className='form-check form-check-custom form-check-solid mx-5'>
              <input className='form-check-input' type='checkbox' value='' defaultChecked={item.completed}/>
            </div> */}
                            <div className='flex-grow-1 mx-3'>
                              <span className='text-gray-800 text-hover-primary fw-bold fs-6'>
                                {item.documenttype}
                                {/* any */}
                              </span>
                              <span className='text-muted fw-semibold d-block'>
                                {/* any */}
                                {item.expirydate}
                              </span>
                            </div>
                            {/* <span className={`badge ${item.completed ?'badge-light-success' :(dateDiff<0 ?'bag')} fs-8 fw-bold`}>New</span> */}
                            <div className='d-flex justify-content-between' style={{ flex: '0 0 35%' }}>
                              {
                                item.completed
                                  ? (<span className='badge badge-light-success fs-8 fw-bold'>Completed</span>)
                                  : (
                                    daysdiff < 0
                                      ? (
                                        <div className='d-flex flex-column justify-content-start mx-2'>
                                          <span className='badge badge-light-danger fs-8 fw-bold'>{daysdiff?'Overdue by':''}</span>
                                          <span className='badge badge-light-danger fs-8 fw-bold'>{daysdiff? daysdiff+'days':'-'}</span>
                                        </div>
                                      )
                                      : (
                                        daysdiff > 30
                                          ? (
                                            <div className='d-flex flex-column justify-content-start mx-2'>
                                              <span className='badge badge-light-primary fs-8 fw-bold'>{daysdiff?'Due in':''}</span>
                                              <span className='badge badge-light-primary fs-8 fw-bold'>{daysdiff? daysdiff+'days':'-'}</span>
                                            </div>
                                          )
                                          : (
                                            <div className='d-flex flex-column justify-content-start mx-2'>
                                              <span className='badge badge-light-warning fs-8 fw-bold'>{daysdiff?'Due in':''}</span>
                                              <span className='badge badge-light-warning fs-8 fw-bold'>{daysdiff? daysdiff+'days':'-'} </span>
                                            </div>
                                          )
                                      )
                                  )
                              }
                              <div>
                                <span onClick={(e) => {
                                  if (!item?.filePath) {
                                    e.preventDefault(); // Prevent the default anchor behavior
                                    message.error("No Document Found");
                                  } else {
                                    e.preventDefault(); // Prevent the default anchor behavior

                                    // Fetch the file and trigger download
                                    fetch(`${BASE_URL_TWO}${item.filePath}`)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        const link = document.createElement('a');
                                        const url = window.URL.createObjectURL(blob);
                                        link.href = url;
                                        link.download = item.fileName || 'downloaded-file';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                        window.URL.revokeObjectURL(url); // Clean up the URL object
                                      })
                                      .catch(() => message.error("Failed to download document"));
                                  }
                                }}>
                                  <IconButton className='text-primary'>
                                    <Download
                                    />
                                  </IconButton>
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>

                </div>

              </div>
            )
          }
          )}

        {
          fetchedData?.length == 0 && <Grid container sx={{ justifyContent: 'center', my: 20 }}>
            No data...
          </Grid>
        }


      </div >

    </>
  )
}


const DocumentsTracker: FC = () => {
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>Employee Document Renewal Notifications</PageTitle> */}
      <RenewalStatusPage />
    </>
  )
}

export { DocumentsTracker }