import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import useFetchCommon from '../../../hooks/getCommonData';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { KTIcon } from '../../../../_metronic/helpers';
import VehiclesForm from '../../../components/VehiclesTable/VehiclesForm';
import EditDepartment from '../Department/EditDepartment';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CreateDepartment from '../Department/CreateDepartment';
import CreateDivision from './CreateDivision';
import EditDivsion from './EditDivsion';
import { message } from "antd";
import { Grid } from '@mui/material';
import PopUp from '../../../components/Modal/Modal';


export default function Division() {

    const id = Cookies.get('userId');
    const token = Cookies.get('token')

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const { commonDataFetch } = useFetchCommon('getDivisionList', [shouldRefetch])
    // console.log("fetchedData", commonDataFetch);

    const [divId, setDivId] = useState()

    const showMessage = (msg) => {
        message.success({
            content: msg,
            duration: 1
        });
    };


    const a = () => {
        message.info({
            content: "This division can't delet",
            duration: 2
        });
    }

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const [dptId, setDptId] = useState();

    const selectedDept = commonDataFetch?.filter((data, index) => dptId == data?.divisionId)

    const departments = useFetchCommon('getDepartmentList')?.commonDataFetch


    const [show, setShow] = useState();
    const [actionStatus, setActionStatus] = useState();
    const [deletId, setDeletId] = useState()

    useEffect(() => {
        if (actionStatus && deletId) {
            axios.post(`${BASE_URL_TWO}`, {
                "sp": "delDivision",
                "userId": id,
                "divisionId": deletId
            }, { headers })
                .then(response => {
                    // Handle success scenario
                    showMessage("Succesfully deleted")
                    setShouldRefetch(prevState => !prevState)
                    setActionStatus(false)
                    // console.log('Request successful:', response.data);
                    // Additional logic for success, if needed
                })
                .catch(error => {
                    a()
                    // Handle error scenario
                    // console.error('Error occurred during POST request:', error);
                    // Additional error handling, such as displaying an error message
                });
        }

    }, [deletId, actionStatus]);



    return (
        <>

            <PopUp
                show={show}
                setShow={setShow}
                setActionStatus={setActionStatus}
                title="Delete Division"
                message="Are you sure you want to division"
                btnName="Delete"
            />


            <Grid container sx={{ justifyContent: 'end', mt: { xs: -1, sm: -1, md: -2, lg: - 5 } }}>
                <div className="card-toolbar me-5">
                    {/* Button to open the modal */}
                    <a href="/"
                        className="btn btn-md btn-light-primary border"
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        title="New Entry"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_2">
                        <KTIcon iconName="plus" className="fs-2" />
                        New Entry
                    </a>
                </div>
            </Grid>


            <CreateDivision departments={departments} onAdd={() => console.log('Add button clicked')} />

            <EditDivsion departments={departments} selectedDept={selectedDept ? selectedDept[0] : ''} />

            <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 bg-white gy-5 gs-7 shadow">
                <thead>

                    <tr className="fw-bold fs-5 bg-primary rounded text-white">

                        <th>#</th>
                        <th>Division</th>
                        <th>Action</th>

                    </tr>

                </thead>
                <tbody>

                    {
                        commonDataFetch?.map((data, index) =>
                            <tr>
                                <td className="text-primary fw-bold"><span className="badge ">{index + 1}</span></td>
                                <td className="text-primary fw-bold">{data?.divisionName}</td>



                                <td className="text-primary fw-bold">

                                    <DeleteIcon

                                        onClick={() => {
                                            setShow(true)
                                            setDeletId(data?.divisionId)
                                        }}

                                        sx={{
                                            cursor: 'pointer'
                                        }}

                                    />


                                    <a href="/"
                                        onClick={() => setDptId(data?.divisionId)}
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                        title="New Entry"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_3">
                                        <ModeEditIcon
                                            sx={{
                                                cursor: 'pointer',
                                                ml: 2
                                            }}
                                        />
                                    </a>

                                </td>




                            </tr>

                        )
                    }

                </tbody>
            </table>

        </>
    )
}
