/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'

const MixedWidget16 = ({ className, chartProgressColor, chartTrackColor, chartHeight, chartTitle, chartCompleted, chartTotal ,labelColor}) => {

  const chartRef = useRef(null)
  const { mode } = useThemeMode();

  // console.log("chartTitle from the widget component", chartTitle);

  const refreshChart = () => {

    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartProgressColor, chartTrackColor, chartHeight, chartTitle, chartCompleted, chartTotal,labelColor))
    if (chart) {
      chart.render()
    }

    return chart

  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`} style={{ boxShadow: 'none'}} >

      <div className='card-body d-flex flex-column justify-content-center align-items-center' style={{ padding: '1rem 2.25rem 1rem 2.25rem'}}>
        <div className='flex-grow-1'  style={{width:"200px"}}>
          <div ref={chartRef} className='mixed-widget-4-chart' style={{width:"200px"}}></div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartProgressColor, chartTrackColor, chartHeight, chartTitle, chartCompleted, chartTotal,labelColor) => {


  return {
    series: [parseInt(chartCompleted) * 100 / parseInt(chartTotal ?? '50')],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: true,
            offsetY: -5,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '22px',
            fontWeight: '500',
            offsetY: 4,
            show: true,
            formatter: function () {
              if (chartTotal == null) {
                return chartCompleted
              } else {
                return chartCompleted + "/" + chartTotal
              }
            },
          },
        },
        track: {
          background: chartTrackColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [chartProgressColor],

    stroke: {
      lineCap: 'round',
    },
    labels: [chartTitle],
  }
}

export { MixedWidget16 }