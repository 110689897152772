import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { faL } from '@fortawesome/free-solid-svg-icons'

export default function PopUp({ show, setShow, title, message, setActionStatus, btnName,update }) {

    // show = { show } setShow = { setShow } /

    return (
        <div style={{ width: '100px' }}>

            <Modal show={show} onHide={() => setShow(false)}>
                <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                    <h5 className="modal-title text-primary">{title} </h5>
                    <button onClick={() => setShow(false)} type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                        <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                    </button>
                </div>
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setShow(false)
                        localStorage.removeItem("personalDetails")
                        localStorage.removeItem("corparateDetails")
                        localStorage.removeItem('familyMembers')
                        localStorage.removeItem('jobDescription')
                        localStorage.removeItem('personalDetails2')
                        localStorage.removeItem('corparateDetails2')
                        localStorage.removeItem('jobDescription2');
                        localStorage.removeItem('familyMembers2')
                        // setCheckDelete(true)
                        setActionStatus(true)
                        if(update){
                            update()
                        }
                    }}>
                        {btnName}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
