import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL_TWO } from '../../../utils';
import { KTSVG } from '../../../_metronic/helpers';
import { message } from 'antd';

const EditBillModal = ({ billData, setShouldRefetch, onClose }) => {
    const id = Cookies.get('userId');
    const token = Cookies.get('token');
    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const validationSchema = Yup.object().shape({
        utilityTitle: Yup.string().required('Utility Name is required'),
        amount: Yup.string().required('Amount is required'),
        dueDate: Yup.date().required('Due date is required'),
        isAnnual: Yup.number().required('isAnnual is required'),
        paymentStatus: Yup.string().required('Payment status is required')
    });

    const initialValues = {
        utilityTitle: billData ? billData.utilityTitle : "",
        amount: billData ? billData.amount : "",
        dueDate: billData ? billData.dueDate : "",
        isAnnual: billData ? billData.isAnnual : 0, // Default to 'No'
        paymentStatus: billData ? billData.paymentStatus : ""
    };

    const showMessage = () => {
        message.success({
            content: 'Document Edited Successfully',
            duration: 3 // Duration in seconds
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            try {
                await axios.post(`${BASE_URL_TWO}`, {
                    userId: parseInt(id),
                    sp: 'updUtilityBills',
                    utilityid: billData.utilityId,
                    utilityTitle: values.utilityTitle,
                    dueDate: values.dueDate,
                    amount: values.amount,
                    paymentStatus: values.paymentStatus,
                    isAnnual: values.isAnnual
                }, { headers });
                setShouldRefetch(prevState => !prevState);
                showMessage();
                onClose(); 
                setTimeout(()=>{
                    window.location.reload()
                },1000)
            } catch (error) {
                setStatus(error.message);
                setSubmitting(false);
            }
        },
        enableReinitialize: true
    });

    return (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex={-1} role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                        <h5 className="modal-title text-primary">Edit Bill Details</h5>
                        <button
                            type="button"
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={onClose}
                            aria-label="Close"
                        >
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                        </button>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasis">Utility Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="utilityTitle"
                                        autoComplete="off"
                                        value={formik.values.utilityTitle}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.utilityTitle}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasis">Amount</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="amount"
                                        autoComplete="off"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.amount}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasis">Due Date</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="dueDate"
                                        autoComplete="off"
                                        value={formik.values.dueDate}
                                        onChange={formik.handleChange}
                                        min={`${new Date().getFullYear()}-01-01`}
                                        max={`${new Date().getFullYear()}-12-31`}
                                    />
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.dueDate}</span>
                                </div>
                                <div className="col-md-6 fv-row">
                                    <label className="form-label fw-bold fs-7 text-light-emphasis">Payment Status</label>
                                    <select
                                        name="paymentStatus"
                                        value={formik.values.paymentStatus}
                                        onChange={formik.handleChange}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Paid">Paid</option>
                                        <option value="Pending">Pending</option>
                                    </select>
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.paymentStatus}</span>
                                </div>
                                <div className="col-md-6 fv-row">
                                    <label className="form-label fw-bold fs-7 text-light-emphasis">Is Annual?</label>
                                    <div className="d-flex gap-4">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="isAnnual"
                                                id="isAnnualYes"
                                                value={1}
                                                checked={formik.values.isAnnual === 1}
                                                onChange={(e) => formik.setFieldValue('isAnnual', parseInt(e.target.value, 10))}
                                            />
                                            <label className="form-check-label" htmlFor="isAnnualYes">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="isAnnual"
                                                id="isAnnualNo"
                                                value={0}
                                                checked={formik.values.isAnnual === 0}
                                                onChange={(e) => formik.setFieldValue('isAnnual', parseInt(e.target.value, 10))}
                                            />
                                            <label className="form-check-label" htmlFor="isAnnualNo">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.isAnnual}</span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" onClick={onClose}>
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditBillModal;
