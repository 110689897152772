import React from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface EmployeeExpenseFormProps {
    onAdd: () => void; // onAdd is a function that takes no parameters and returns void
}

const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required('Customer name is required'),
    salary: Yup.string().required('Slary is required'),
    airTicket: Yup.string().required('Air ticket is required'),
    airTicketForFamily: Yup.string().required('Air ticket for family is required'),
    fuelBased: Yup.string().required('Fuel Base required'),
    CompanyVehcle: Yup.string().required('Compeny vehcle name is required'),
    plateNumber: Yup.string().required('vehcle plate number is required'),
    carInsurance: Yup.string().required('vehcle insurance is required'),
    carRenewalFee: Yup.string().required('vehcle renewal is required'),
    medicalInsurance: Yup.string().required('Medical insurance is required'),
    salesCommission: Yup.string().required('Sales commission is required'),
    visaExpence: Yup.string().required('Visa expence is required'),
    visaExpenceForFamily: Yup.string().required('Visa expence for family is required'),
    gratuity: Yup.string().required('Gratuity is required'),
    pasi: Yup.string().required('PASI is required'),
    tamimahExperience: Yup.string().required('Tamimah experience is required'),
})

const EmployeeExpenseForm = ({ onAdd }) => {
    const handleAddClick = () => {
        if (onAdd) {
            onAdd();
        }
    };

    const initialValues = {
        employeeName: '',
        salary: '',
        airTicket: '',
        airTicketForFamily: '',
        fuelBased: '',
        CompanyVehcle: '',
        plateNumber: '',
        carInsurance: '',
        carRenewalFee: '',
        medicalInsurance: '',
        salesCommission: '',
        visaExpence: '',
        visaExpenceForFamily: '',
        gratuity: '',
        pasi: '',
        tamimahExperience: ''
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            console.log("/////////////////", values);

        }
    });

    return (
        <div className="modal fade" id="kt_modal_2" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
            <div className="modal-dialog modal-lg" role="document">


                <form action="" onSubmit={formik.handleSubmit}>

                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Employee Expense Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>


                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Employee Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='employeeName'
                                        autoComplete='off'
                                        value={formik.values.employeeName}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.employeeName}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Salary</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='salary'
                                        autoComplete='off'
                                        value={formik.values.employeeName}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.employeeName}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Air Ticket</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='airTicket'
                                        autoComplete='off'
                                        value={formik.values.airTicket}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.airTicket}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Air Ticket for family</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='airTicketForFamily'
                                        autoComplete='off'
                                        value={formik.values.airTicketForFamily}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.airTicketForFamily}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Fuel based on reimbursement</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='fuelBased'
                                        autoComplete='off'
                                        value={formik.values.fuelBased}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.fuelBased}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Company vehicle</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='CompanyVehcle'
                                        autoComplete='off'
                                        value={formik.values.CompanyVehcle}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.CompanyVehcle}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Plate number</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='plateNumber'
                                        autoComplete='off'
                                        value={formik.values.plateNumber}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.plateNumber}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Car insurance</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='carInsurance'
                                        autoComplete='off'
                                        value={formik.values.carInsurance}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.carInsurance}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Car renewal fee</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='carRenewalFee'
                                        autoComplete='off'
                                        value={formik.values.carRenewalFee}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.carRenewalFee}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Medical insurance</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='medicalInsurance'
                                        autoComplete='off'
                                        value={formik.values.medicalInsurance}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.medicalInsurance}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Commission for sales</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='salesCommission'
                                        autoComplete='off'
                                        value={formik.values.salesCommission}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.salesCommission}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Visa expense</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='visaExpence'
                                        autoComplete='off'
                                        value={formik.values.visaExpence}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.visaExpence}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Visa expense for family</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='visaExpenceForFamily'
                                        autoComplete='off'
                                        value={formik.values.visaExpenceForFamily}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.visaExpenceForFamily}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Gratuity</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='gratuity'
                                        autoComplete='off'
                                        value={formik.values.gratuity}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.gratuity}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">PASI</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='pasi'
                                        autoComplete='off'
                                        value={formik.values.pasi}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.pasi}</span>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Tamimah Experince</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='tamimahExperience'
                                        autoComplete='off'
                                        value={formik.values.tamimahExperience}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.tamimahExperience}</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary" onClick={handleAddClick}>
                                Add
                            </button>
                        </div>
                    </div>

                </form>









            </div>
        </div>
    );
};

export default EmployeeExpenseForm;
