import { useFormik } from 'formik';
import React, { useState } from 'react';
import { message } from "antd";
import axios from 'axios';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { KTSVG } from '../../../../_metronic/helpers';
import { BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object({
  file: Yup.mixed()
    .required('File is required')
    .test('fileSize', 'File is too large', value => value && value.size <= 5 * 1024 * 1024) // 5 MB limit
    .test('fileType', 'Unsupported File Format', value => {
      if (value) {
        const validTypes = [
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-excel',
          'image/jpeg',
          'image/png',
          'image/gif'
        ];
        return validTypes.includes(value.type);
      }
      return false;
    }),
  fileName: Yup.string()
    .required('File Name is required'),
  fileType: Yup.string()
    .required('File Type is required')
});

export default function EmployeeDocuUpload({ familyMemberId }) {
    const [base64String, setBase64String] = useState('');

    const id = Cookies.get('userId');
    const token = Cookies.get('token')

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
      };

    const showMessage = () => {
        message.success({
            content: 'Successfully created',
            duration: 1
        });
    };

    const failedMessage = () => {
        message.info({
            content: 'Failed to create',
            duration: 1
        });
    }

    const formik = useFormik({
        initialValues: {
            file: null,
            fileName: '',
            fileType: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            axios.post(`${BASE_URL_TWO}Default.aspx`, {
                "sp":'insEmployeeFamilyMemberForFolderView',
                "userId": parseInt(id),
                "familyMemberId": parseInt(familyMemberId),
                "documentType": values.fileType,
                "document": base64String,
                "fileName": values.fileName,
            }, { headers })
                .then(() => {
                    showMessage();
                    setTimeout(() => { window.location.reload() }, 1000);
                    localStorage.clear();
                })
                .catch(error => {
                    console.error('Error occurred during POST request:', error);
                    setStatus({ success: false });
                    failedMessage();
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    const handleFileInputChange = (event) => {
        const file = event.target.files ? event.target.files[0] : '';
        formik.setFieldValue('file', file); // Bind file to formik

        const reader = new FileReader();
        reader.onload = () => {
            setBase64String(reader.result);
        };
        reader.readAsDataURL(file);
    };

    return (
        <div className="modal fade" id="kt_modal_2" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <form onSubmit={formik.handleSubmit}>
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Browse File</label>
                                    <input
                                        type="file"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name='file'
                                        onChange={handleFileInputChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.file}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">File Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name='fileName'
                                        value={formik.values.fileName}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.fileName}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">File Type</label>
                                    <select
                                        className="form-select form-select-lg form-select-solid fs-7 bg-body-secondary"
                                        name='fileType'
                                        onChange={formik.handleChange}
                                        value={formik.values.fileType}
                                    >
                                        <option value="">Select file type...</option>
                                        <option value="Passport">Passport</option>
                                        <option value="Visa">Visa</option>
                                        <option value="Resident Permit">Resident Permit</option>
                                        <option value="ID/RC">ID/RC</option>
                                        <option value="Photo">Photo</option>
                                        <option value="Agreement">Agreement</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.fileType}</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Add
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
