import React, { useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { BankGuarantee } from '../../pages/EmployerPages/Organisation/BankGaurentee';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BASE_URL_TWO } from '../../../utils';
import { message } from "antd";
import axios from 'axios';
import Cookies from 'js-cookie';

import moment from 'moment';



const validationSchema = Yup.object().shape({
    clientName: Yup.string().required('Cstomer name required'),
    startDate: Yup.string().required('Start date is required'),
    endDate: Yup.string()
    .required('End date is required')
    .test('endDate', 'End date must be after the start date', function (value) {
        const { startDate } = this.parent;
        return !startDate || !value || new Date(value) > new Date(startDate);
    }),
    guaranteType: Yup.string().required('Select guarantee Type'),
    amount: Yup.string().required('Guarantee amount is required'),
})

export default function BankGuaranteeFormEdit({ selectedData,setShouldRefetch}) {


    const [guaranteeType, setGuaranteeType] = useState()

    console.log("selectedData", selectedData);

    const start = moment(selectedData?.startDate, "M/D/YYYY").format("YYYY-MM-DD");
    const end = moment(selectedData?.endDate, "M/D/YYYY").format("YYYY-MM-DD");



    const initialValues = {
        clientName: selectedData ? selectedData?.customerName : '',
        startDate: start,
        endDate: end,
        guaranteType: selectedData ? selectedData?.guaranteeType : '',
        amount: selectedData ? selectedData?.guaranteeAmount : '',
    }

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'Updated',
            duration: 1
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            axios.post(`${BASE_URL_TWO}`, {
                sp: 'updBankGuarantee',
                userId: id,
                guaranteeID: selectedData?.guaranteeId,
                clientName: values?.clientName,
                startDate: values?.startDate,
                endDate: values?.endDate,
                guaranteeType: values?.guaranteType,
                guaranteeAmount: values?.amount

            }, { headers }).then(() => {
                setShouldRefetch(prevState => !prevState)
                showMessage()
                // setShouldRefetch(prevState => !prevState)
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            }).catch((err) => {
                console.log("...");
            })

        },
        enableReinitialize: true
    });

    return (
        <div className="modal fade" id="kt_modal_3" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">

            <form action="" onSubmit={formik.handleSubmit}>

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-3">

                                {/* Pair 1: Client Name and Start Date */}
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">Client Name</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='clientName'
                                            autoComplete='off'
                                            value={formik.values.clientName}
                                            onChange={formik.handleChange}
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.clientName}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">Start Date</label>
                                        <input
                                            type="date"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='startDate'
                                            autoComplete='off'
                                            value={formik.values.startDate}
                                            onChange={formik.handleChange}
                                            min={new Date().toLocaleDateString('en-CA')}
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.startDate}</span>
                                    </div>
                                </div>

                                {/* Pair 2: End Date and Guarantee Type */}
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">End Date</label>
                                        <input
                                            type="date"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='endDate'
                                            autoComplete='off'
                                            value={formik.values.endDate}
                                            onChange={formik.handleChange}
                                            min={new Date().toLocaleDateString('en-CA')}
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.endDate}</span>
                                    </div>
                                    <div className='col-md-6'>
                                        <label className='form-label fw-bold fs-7 text-light-emphasi'>Type of Guarantee</label>
                                        <select
                                            name="guaranteType"
                                            value={formik.values.guaranteType}
                                            onChange={(e) => {
                                                setGuaranteeType(e.target.value)
                                                formik.handleChange(e);
                                                formik.setFieldValue('guaranteType', e.target.value)

                                            }}
                                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'>
                                            <option value=''>Select Type</option>
                                            <option value='performanceBond'>Performance Bond</option>
                                            <option value='tenderBond'>Tender Bond</option>
                                            <option value='advanced payment'>Advanced Payment</option>
                                        </select>
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.guaranteType}</span>
                                    </div>
                                </div>

                                {/* Pair 3: Guarantee Amount */}
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">Guarantee Amount</label>
                                        <input
                                            type="number"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='amount'
                                            autoComplete='off'
                                            value={formik.values.amount}
                                            onChange={formik.handleChange}
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.amount}</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="sumbit" className="btn btn-primary" >
                               Update
                            </button>
                        </div>
                    </div>
                </div>

            </form>F
        </div>
    )
}
