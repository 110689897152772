/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTIcon } from '../../../helpers'
import useFetchData from '../../../../app/hooks/getData'
import { Button, message } from "antd";
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import Cookies from 'js-cookie';

// type Props = {
//   className: string
// }

const TablesWidget8 = ({ className, width }) => {

  const [clickedItem, setClickedItem] = useState(0)

  const { fetchedData } = useFetchData('GetEmployeesDOB');
  const id = Cookies.get('userId');
  const token = Cookies.get("token")
  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };


  const filteredData = fetchedData?.filter((data, index) => index === clickedItem);
  const getLeaveDataByType = filteredData?.[0]?.members;
  const employees = getLeaveDataByType ? JSON.parse(getLeaveDataByType) : null;

  const showMessage = () => {
    message.success({
      content: 'Birthday wishes sent',
      duration: 4 // Duration in seconds
    });
  };

  return (
    <>
      <span className='text-muted fw-semibold d-block fs-4 my-2'>Birthday Calendar</span>
      <div className={`card ${className}`} style={{ background: "#6255a3", width: width ? width : '' }}>
        <div className='card-header pt-5 pl-10 pb-5 border-bottom-1 border-white ribbon ribbon-top ribbon-vertical'>
          <div className="ribbon-label bg-danger">
            <i className="bi bi-balloon-fill fs-1 text-white"></i>
          </div>
          <div className='card-toolbar'>
            <ul className='nav'>
              {
                fetchedData?.map((data, index) =>
                  <li
                    key={index}
                    onClick={() => setClickedItem(index)}
                    className='nav-item'>
                    <a style={{
                      background: clickedItem == index ? '#FFF' : '',
                      color: clickedItem == index ? '' : 'white',
                    }}
                      className='nav-link btn btn-sm 
                      fw-bold px-5 me-1'
                      data-bs-toggle='tab'
                    // href='#kt_table_widget_8_tab_1'
                    >
                      {data?.monthOfBirth}
                    </a>
                  </li>
                )
              }
            </ul>
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='kt_table_widget_8_tab_1'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-3'>
                  <thead>
                    <tr>
                      <th className='p-0 w-50px'></th>
                      <th className='p-0 min-w-150px'></th>
                      <th className='p-0 min-w-120px'></th>
                    </tr>
                  </thead>
                  <tbody className='text-primary'>
                    {!employees && <tr><td colSpan="3" className="text-center"><h4 className='text-white'>No birthdays in this month</h4> </td></tr>}
                    {
                      employees?.map((data, index) =>
                        <tr key={index}>
                          <td>
                            <div className='symbol symbol-35px me-2'>
                              <span className='symbol-label bg-light-danger'>
                                <KTIcon iconName='user' className='fs-2x text-info' />
                              </span>
                            </div>
                          </td>
                          <td>
                            <p className='text-light fw-bold text-hover-primary mb-1 fs-6'>
                              {data?.firstName}
                            </p>
                            {/* <span className='text-muted fw-semibold d-block fs-7'>by Rose Liam</span> */}
                          </td>
                          <td className='text-end'>
                            <span className='text-light fw-bold d-block fs-7'> {data?.dateOfBirth}</span>
                            <span className='text-muted fw-semibold d-block fs-8'>Date Of Birth</span>
                          </td>
                          {(data?.userId != id) && <td className='text-end'>
                              <span className='text-muted fw-regular d-block  fs-8'>Send wish</span>
                              <a
                                href='#'
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-info me-3'
                                onClick={() => {
                                  axios.post(`${BASE_URL_TWO}`, {
                                    sp: "sendBirthdayWish",
                                    fromUserId: parseInt(id),
                                    toUserId: data.userId
                                  }, { headers }).then((response) => {
                                    showMessage()
                                  })
                                }}
                              >
                                <i className='bi bi-gift-fill fs-5' title="Send Birthday Wish"></i>
                              </a>
                          </td>}
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export { TablesWidget8 }