/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { Languages } from './Languages'
import { toAbsoluteUrl } from '../../../helpers'
import useFetchDataByCheck from '../../../../app/hooks/getDataByCheck'
import Cookies from 'js-cookie'
import PopUp from '../../../../app/components/Modal/Modal'
import { BASE_URL_TWO } from '../../../../utils';

const HeaderUserMenu = ({ empName }) => {
  const { currentUser, logout } = useAuth()

  const permission = Cookies.get('pemissions');
  let permissionArray = [];
  const navigate = useNavigate()



  try {
    if (!permission) {
      throw new Error("Permission data is undefined");
    }
    permissionArray = JSON.parse(permission);
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  //console.log('permissionArray'+permissionArray);

  const routPath = permissionArray ? permissionArray[0]?.pageUrl : '';

  const [show, setShow] = useState();
  const [actionStatus, setActionStatus] = useState();
  const [deletId, setDeletId] = useState()

  useEffect(() => {
    if (actionStatus && deletId) {
      Cookies.remove('firstName')
      Cookies.remove('pemissions')
      Cookies.remove('token')
      Cookies.remove('userId')
      Cookies.remove('loggedUserName')
      
      localStorage.removeItem('jobDescription')
      localStorage.removeItem('jobDescription')
      localStorage.removeItem('personalDetails')

      logout();

      setTimeout(() => { navigate('/') }, 2000)

    }
  }, [deletId, actionStatus]);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >

      <PopUp
        show={show}
        setShow={setShow}
        setActionStatus={setActionStatus}
        title="Sign out"
        message="Are you sure you want to sign out"
        btnName="Sign out"
      />


      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
          {currentUser?.profileImage ? (
            <img alt='Logo' src={`${BASE_URL_TWO}${currentUser.profileImage}`} />
          ) : (
            <img alt='ProfileImage' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          )}
          </div>

          <div className='d-flex flex-column'>


            <div className='fw-bolder d-flex align-items-center fs-5'>
              {empName}
              <span className='badge badge-light-primary fw-bolder fs-7 px-2 py-1 ms-2'></span>
            </div>
            <a className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>

          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {permissionArray.some(obj => obj.permissionName === 'Update Profile') && (
        <div className='menu-item px-5'>
          <Link to="/superadmin/updateprofile" className='menu-link px-5'>
            My Details 
          </Link>
        </div>
      )}

      {permissionArray.some(obj => obj.permissionName === 'My details') && (
        <div className='menu-item px-5'>
          <Link to="/EmployeePages/MyAccount" className='menu-link px-5'>
            My Details 
          </Link>
        </div>
      )}
      
      {permissionArray.some(obj => obj.permissionName === 'ChangePassword') && (
      <div className='menu-item px-5' >
        <Link to='/changepassword' className='menu-link px-5'>
         Change Password
        </Link>
      </div>
       )}

      {/* <div className='menu-item px-5 my-1'>
        <Link to={routPath} className='menu-link px-5'>
          My Documents
        </Link>
      </div> */}

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div
        onClick={() => {
          setShow(true)
          setDeletId(10)
          // Cookies.remove('firstName')
          // Cookies.remove('pemissions')
          // Cookies.remove('token')
          // Cookies.remove('userId')
          // localStorage.removeItem('jobDescription')
          // localStorage.removeItem('jobDescription')
          // localStorage.removeItem('personalDetails')
          // navigate('/')
        }}

        className='menu-item px-5 my-1'>
        <Link className='menu-link px-5'>
          Sign Out <i className="bi bi-box-arrow-right mx-3 fs-2"></i>
        </Link>
      </div>

      {/* <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
        Log Out <i className="bi bi-box-arrow-right mx-3 fs-2"></i>
        </a>
      </div> */}
    </div>
  )
}

export { HeaderUserMenu }
