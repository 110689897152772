import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { Box, IconButton } from '@mui/material';
import { Archive, Delete, Edit, DocumentScanner } from '@mui/icons-material';
import axios from "axios";
import { Button, message } from "antd";
// import { Button, message } from "antd";
import * as Yup from 'yup';
import { useFormik } from 'formik';



import {
  CardsWidget21,
  CardsWidget30,
  MixedWidget16,
} from '../../../_metronic/partials/widgets'

import {
  TablesWidget1,
  TablesWidget2,
  TablesWidget3,
  TablesWidget4,
  TablesWidget5,
  TablesWidget6,
  TablesWidget7,
  TablesWidget8,
  TablesWidget9,
  TablesWidget10,
  TablesWidget11,
  TablesWidget12,
  TablesWidget13,

} from '../../../_metronic/partials/widgets'
import useFetchData from '../../hooks/getData';
import Cookies from 'js-cookie';
import { Axios } from 'axios';
import { BASE_URL_TWO } from '../../../utils';
import { Modal } from 'react-bootstrap';
import PopUp from '../../components/Modal/Modal';
import RichTextEditor from '../../../_metronic/helpers/components/RichTextEditor';
import DOMPurify from 'dompurify';
import { htmlToText } from 'html-to-text';


const validationSchema = Yup.object().shape({
  announcement: Yup.string()
    // .min(10, 'Minimum 10 characters')
    .required('Announcement is required'),
})

const DashboardPage = () => {

  const [shouldRefetch, setShouldRefetch] = useState(false);

  const employeDashBoardCount = useFetchData('GetAdminDashboardCount').fetchedData?.[0]

  const anauncements = useFetchData('getAnnouncements', [shouldRefetch]).fetchedData;

  const [clickedDate, setClickedDate] = useState(0)

  const filterDataByAnouncemnets = anauncements?.filter((data, index) => index == clickedDate);


  const id = Cookies.get('userId');
  const token = Cookies.get("token")

  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };

  const [editedTexts, setEditedTexts] = useState({});

  const [isAddingAnnouncement, setIsAddingAnnouncement] = useState(false);

  const activeEmployees = useFetchData('GetActiveEmployeeDetails')?.fetchedData

  const LeaveRequists = useFetchData('getPendingLeaveRequests&filter=pending')?.fetchedData

  const expiredDocumentsList = employeDashBoardCount ? JSON?.parse(employeDashBoardCount?.expiredDocumentsList) : ''


  const showMessage = (msg) => {
    message.success({
      content: msg,
      duration: 4 // Duration in seconds
    });
  };

  const a = () => {
    message.info({
      content: 'Announcement updation faild',
      duration: 2
    });
  }

  const initialValues = {
    announcement: '',
    editAnnouncement: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      axios?.post(`${BASE_URL_TWO}`, {
        "sp": "insAnnouncements",
        "userId": parseInt(id),
        "textContent": values?.announcement
      }, { headers }).then(() => {
        setIsAddingAnnouncement(!isAddingAnnouncement)
        setEditedTexts({})
        setShouldRefetch(prevState => !prevState)
        setClickedDate(0)
        showMessage('New announcement created')
        resetForm()
      })
    },
  });

  const [show, setShow] = useState(false);


  const [actionStatus, setActionStatus] = useState()

  const [anouncementId, setAnouncementId] = useState()


  useEffect(() => {
    if (anouncementId && actionStatus) {
      axios.post(`${BASE_URL_TWO}`, {
        "sp": "delAnnouncements",
        "userId": parseInt(id),
        "announcementId": anouncementId
      }, { headers })
        .then(() => {
          setShouldRefetch(prevState => !prevState);
          showMessage('Deleted successfully');
          setActionStatus(false)
        });
    }
  }, [anouncementId, actionStatus]);

  return (
    <>
      <PopUp
        title="Delete Announcement"
        message="Are you sure you want to delete this announcement?"
        show={show}
        setActionStatus={setActionStatus}
        setShow={setShow}
        btnName="Delete"
      />


      <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> Employer Dashboard </h5>

      <div className='d-flex justify-content-start'>
        <div className='col-xl-40 me-5 flex-fill'>
          <TablesWidget8 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>

        <div className='col-md-8 col-lg-2 mt-5'>

          <a className="col-md-4 col-lg-6 col-xl-4 col-xxl-3" data-bs-toggle="collapse" href="#collapseExample5" role="button" aria-expanded="false" aria-controls="collapseExample5">
            <CardsWidget30
              className='h-md-47 mb-5'
              color='#143983'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              title='Active Employees'
              description={`${employeDashBoardCount?.ActiveEmployees ?employeDashBoardCount?.ActiveEmployees:0}/${employeDashBoardCount?.totalEmployees ? employeDashBoardCount?.totalEmployees :0}`}
            />
          </a>

          <a className="col-md-4 col-lg-6 col-xl-4 col-xxl-3" data-bs-toggle="collapse" href="#1_months_EX_table" role="button" aria-expanded="false" aria-controls="1_months_EX_table">
            <CardsWidget30
              className='h-md-47 mb-5'
              color='#34a8cb'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              title='Expired Documents'
              description={employeDashBoardCount?.expiredDocuments ? employeDashBoardCount?.expiredDocuments:0}
            />
          </a>

          <a className="col-md-4 col-lg-6 col-xl-4 col-xxl-3" data-bs-toggle="collapse" href="#pending_leave_request" role="button" aria-expanded="false" aria-controls="pending_leave_request">
            <CardsWidget30
              className='h-md-47'
              color='#831414'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              title='Pending Leaves'
              description={employeDashBoardCount?.pendingLeaves ? employeDashBoardCount?.pendingLeaves : 0}
            />
          </a>
        </div>

      </div>

      {
        expiredDocumentsList ?

          <div className="collapse" id="1_months_EX_table">
            <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300  gy-5 gs-7 bg-white shadow">
              <thead>
                <tr className="fw-bold fs-5 border-bottom border-gray-200 bg-primary text-white">
                  <th><i className="bi bi-person-fill fs-3 me-2 svg-icon-muted text-white"></i>Name</th>
                  <th><i className="bi bi-file-earmark-fill fs-3 me-2 svg-icon-muted text-white"></i>Document Name</th>
                  <th><i className="bi bi-calendar2-day-fill fs-3 me-2 svg-icon-muted text-white"></i>Expiration Date</th>
                  <th style={{ width: "15px" }}> View</th>
                </tr>
              </thead>


              <tbody>

                {
                  expiredDocumentsList?.map((data, index) =>
                    <tr>
                      <td className="text-primary fw-bold">{data?.memberName}</td>
                      <td className="text-primary fw-bold">{data?.documentType}</td>
                      <td className="text-primary fw-bold"><span className="badge badge-primary p-2">{data?.expiryDate}</span></td>

                      <td className="">
                        <Box sx={{ justifyContent: 'center' }}>
                          <IconButton
                          >
                            <a href={data?.filePath}><DocumentScanner className='text-primary' /></a>
                          </IconButton>
                        </Box>
                      </td>
                    </tr>
                  )
                }

              </tbody>
            </table>
          </div>

          :

          ""
      }

      {
        activeEmployees ?

          <div className="collapse" id="collapseExample5">
            <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300  gy-5 gs-7 bg-white shadow">
              <thead>
                <tr className="fw-bold fs-5 border-bottom border-gray-200 bg-primary text-white">
                  <th><i className="bi bi-person-fill fs-3 me-2 svg-icon-muted text-white"></i>Name</th>
                  <th><i className="bi bi-building fs-3 me-2 svg-icon-muted text-white"></i>TT/TD</th>
                  <th><i className="bi bi-flag fs-3 me-2 svg-icon-muted text-white"></i>Country</th>
                </tr>
              </thead>

              <tbody>

                {
                  activeEmployees?.map((data, index) =>
                    <tr>
                      <td className="text-primary fw-bold">{data?.Name}</td>
                      <td className="text-primary fw-bold">{data?.CompanyName}</td>
                      <td className="text-primary fw-bold"><span className="badge badge-primary p-2">{data?.nationality}</span></td>
                    </tr>
                  )
                }

              </tbody>
            </table>
          </div>

          :

          ""
      }

      <div className="collapse" id="pending_leave_request">

        {
          LeaveRequists?.length == 0 ? '' :

            <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300  gy-5 gs-7 bg-white shadow">
              <thead>

                <tr className="fw-bold fs-5 border-bottom border-gray-200 bg-primary text-white">

                  <th><i className="bi bi-person-fill fs-3 me-2 svg-icon-muted text-white"></i>Name</th>
                  <th><i className="bi bi-file-earmark-fill fs-3 me-2 svg-icon-muted text-white"></i>Leave Type</th>
                  <th><i className="bi bi-hourglass-top fs-3 me-2 svg-icon-muted text-white"></i>Duration</th>

                </tr>

              </thead>
              <tbody>
                {
                  LeaveRequists?.map((data, index) =>

                    <tr>
                      <td className="text-primary fw-bold">{data?.employeeName}</td>
                      <td className="text-primary fw-bold">{data?.leaveType}</td>
                      <td className="text-primary fw-bold"><span className="badge badge-light-danger p-2">{data?.duration} Days</span></td>
                    </tr>

                  )
                }
              </tbody>
            </table>

        }
      </div>

      <div className="card borcard-borderedder mb-10" >
        <div className="card-header ribbon ribbon-top ribbon-vertical bg-primary-subtle">
          <div className="ribbon-label" style={{ background: "#143983" }}>
            <i className="bi bi-megaphone-fill fs-1 text-white"></i>
          </div>
          <div className="card-title">
            <h3 className='card-title align-items-start px-5'>
              <span className='card-label fw-bold fs-3 mb-1 text-primary'> Add Announcements</span>
            </h3>

            <pre></pre><br></br>


            <div className="card-toolbar">
              <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold">

                {
                  anauncements?.map((data, index) =>
                    <li
                      key={index}
                      onClick={() => {
                        setClickedDate(index)
                        setIsAddingAnnouncement(false)
                      }}
                      className="nav-item cursor-pointer">
                      <a
                        onClick={() => {
                          // setClickedDate(index)
                          setIsAddingAnnouncement(false)
                        }}
                        className="nav-link "
                        data-bs-toggle="tab"
                        // href="#kt_tab_pane_7"
                        style={{ borderBottom: clickedDate == index ? `2px solid #143983` : '' }}
                      >
                        {data?.createdAt}
                      </a>
                    </li>
                  )
                }

                <li className="nav-item cursor-pointer">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_11"
                    style={{ borderBottom: isAddingAnnouncement == true ? `2px solid #143983` : '' }}
                  >

                    <div className='add-Announcements'>
                      <div className="d-flex justify-content-end pb-1">
                        <a style={{ borderBottom: setIsAddingAnnouncement == true ? `2px solid #143983` : '' }}
                          onClick={() => { setClickedDate("A") }}
                          className="text-white" data-bs-toggle="collapse" href="#collapseExample1"
                          role="button" aria-expanded="false" aria-controls="collapseExampleAnnouncemant">
                          <button onClick={() => setIsAddingAnnouncement(!isAddingAnnouncement)} type="button" className="btn btn-primary btn-sm">+ Add</button>
                        </a>
                      </div>
                    </div>

                  </a>
                </li>

              </ul>
            </div>
          </div>
        </div>

        <div className="card-body text-primary fw-bold bg-primary-subtle" >
          <div className="tab-content" id="myTabContent">

            {
              filterDataByAnouncemnets?.map((data, index) => {
                return (
                  <div
                    className="tab-pane fade show active"
                    id="kt_tab_pane_7"
                    role="tabpanel"
                  >
                    <RichTextEditor
                      name="editAnnouncement"
                      className="datafield p-5 d-flex flex-column gap-3"
                      onChange={(value) => {
                        setEditedTexts((prevEditedTexts) => ({
                          ...prevEditedTexts,
                          [data.announcementId]: value,
                        }));
                      }}
                      suppressContentEditableWarning={true}
                      value={editedTexts[data.announcementId] || data.textContent}
                    />
                    <div className='edit-Announcements'>
                      <div className="d-flex justify-content-end pb-1">

                        <button onClick={() => {

                          axios.post(`${BASE_URL_TWO}`, {
                            sp: "updAnnouncements",
                            userId: parseInt(id),
                            announcementId: data?.announcementId,
                            textContent: editedTexts[data.announcementId],
                          }, { headers })
                            .then((response) => {
                              // Log successful response for debugging
                              setShouldRefetch(prevState => !prevState);
                              showMessage('Updated successfully');
                            })
                            .catch((error) => {
                              console.error('Error updating announcement:', error);
                              // Handle the error here:
                              a()
                              if (error.response) {

                              } else if (error.request) {
                                console.error('No response received from server');
                              } else {
                                console.error('Error setting up request:', error.message);
                              }

                            });

                        }} type="button" className="btn btn-primary btn-sm m-2">Update</button>

                        <button
                          onClick={() => {
                            setShow(true);
                            setAnouncementId(data?.announcementId)
                          }}
                          type="button"
                          className="btn btn-danger btn-sm m-2"
                        >
                          Delete
                        </button>

                      </div>
                    </div>
                  </div>
                )
              }
              )
            }

            <div
              className="card-body text-primary fw-bold bg-primary-subtle"
              id="kt_tab_pane_10"
              role="tabpanel"
            >

              {
                isAddingAnnouncement && (
                  <div className='justify-content-md-center'>
                    <div className='row g-2 g-xl-4 justify-content-md-center'>
                      <form action="" onSubmit={formik.handleSubmit}>
                        <div className='col-xl-10 position-relative'>
                          <RichTextEditor
                            name='announcement'
                            className="datafield  d-flex flex-column gap-3"
                            onChange={value => {
                              formik.setFieldValue('announcement', value)
                            }}
                            suppressContentEditableWarning={true}
                            value={formik.values.announcement}
                          />
                          {formik.touched.announcement && formik.errors.announcement && (
                            <div className='fv-plugins-message-container'>
                              <span style={{ color: 'red' }} role='alert'>
                                {formik.errors.announcement}
                              </span>
                            </div>
                          )}
                          <button type="submit" className="btn btn-outline-primary border border-primary  w-100 mt-2" style={{ backgroundColor: '#143983', color: 'white' }}>Send Announcement</button>
                        </div>
                      </form>
                    </div>
                  </div>
                )
              }

            </div>
          </div>
        </div>
      </div>

    </>
  )
}

const EmployerDashboard = () => {
  const intl = useIntl()
  return (
    <>
      <DashboardPage />
    </>
  )
}

export { EmployerDashboard }