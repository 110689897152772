import React, { FC, useEffect, useRef, useState } from 'react'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import { Step5 } from './steps/Step5'
import { KTIcon, KTSVG } from '../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { Form, Formik, FormikValues, useFormik } from 'formik'
import { createAccountSchemas, ICreateAccount, inits } from './CreateAccountWizardHelper'
import useGetDataWithFullUrl from '../../../../hooks/getDataWithFullUrl'
import { BASE_URL, BASE_URL_TWO } from '../../../../../utils'
import Cookies from 'js-cookie'
import { Autocomplete, Grid, TextField } from '@mui/material'
import useGetDataWithFullUrlTwo from '../../../../hooks/getDataWithFullUrlTwo'
import { faPersonWalkingDashedLineArrowRight } from '@fortawesome/free-solid-svg-icons';
import { message } from "antd";
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validationSchema =
  Yup.object().shape({
    coursename: Yup.string().required("Course name is required"),
    academy: Yup.string().required("Academy name is required"),
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    Duration: Yup.string().required("Cource duration is required"),
    status: Yup.string().required("Status is required")
  });

const EmployeeInfo = () => {

  const stepperRef = useRef(null)
  const stepper = useRef(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const [dptId, setDptId] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = (values, actions) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const [shouldRefetch, setShouldRefetch] = useState(false);

  const [searchValue, setSearchValue] = useState()

  const id = Cookies.get('userId');
  const token = Cookies.get('token');
  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };

  const employeList = useGetDataWithFullUrl(`${BASE_URL}getEmployeeIDList&userId=${id}`)?.fetchedData
  
  let arr = []

  employeList?.map((data, index) => {
      arr.push({
          label:data?.employeeName,
          id:data?.userId
      })
  })

  const courseDetails = useGetDataWithFullUrl(`${BASE_URL}getEmployeeCourseDetails&userId=${id}${searchValue ? `&Search=${searchValue}` : ''}`, [shouldRefetch]).fetchedData;

  const [steps, setSteps] = useState("step1")
  const [personalDetails, setPersonalDetails] = useState()
  const [corporateDetails, setCorporateDetails] = useState()
  const [jobDescriptionBenifits, setJobDescriptionBenifits] = useState();
  const [familyDetails, setFamilyDetails] = useState();

  const [employerSearchValue, setEmployerSearchValue] = useState();
  const [employeeId, setEmployeeId] = useState()
  const[data,setData] = useState()

  useEffect(()=>{
    localStorage.removeItem('personalDetails2')
    localStorage.removeItem('corparateDetails2')
    localStorage.removeItem('jobDescription2');
    localStorage.removeItem('familyMembers2');
    setSteps('step1')
  },[])

  const fetchEmployeeDetails=async (employeId)=>{
    if(!employeId){
      localStorage.removeItem('personalDetails2')
      localStorage.removeItem('corparateDetails2')
      localStorage.removeItem('jobDescription2');
      localStorage.removeItem('familyMembers2');
      setSteps('step1')
      return;
    }
    localStorage.removeItem('personalDetails2')
    localStorage.removeItem('corparateDetails2')
    localStorage.removeItem('jobDescription2');
    localStorage.removeItem('familyMembers2');
    setSteps('step1')
    const response=await axios.get(`${BASE_URL}getEmployeeDetails&userId=${id}&staffId=${employeId}&multiple=true`,{headers});
    setData(response?.data?.Data);
    setData(response?.data?.Data);
  }


  const employeDetailSearch = useGetDataWithFullUrlTwo(`${BASE_URL}getSearchByName&userId=${id}${employerSearchValue ? `&Search=${employerSearchValue}` : ''}`, [shouldRefetch])?.fetchedData;

  const searchItems = employeDetailSearch?.filter((data, index) => data?.employeeId != id)

  const [showSearchItems, setShowSearchItems] = useState(true)

  const showMessage = () => {
    message.success({
      content: 'Created successfully',
      duration: 4 // Duration in seconds
    });
  };

  const showError = (msg) => {
    message.error({
      content: msg,
      duration: 4 // Duration in seconds
    });
  };

  const a = () => {
    message.info({
      content: 'Failed to create',
      duration: 2
    });
  }

  const initialValues = {
    coursename: '',
    academy: '',
    startDate: '',
    endDate: '',
    Duration: '',
    Status: ''
  };

  const [base64Data, setBase64Data] = useState('');

  const handleFileSelect = (event) => {
    const file = event.target.files[0]; // Get the selected file
    const reader = new FileReader(); // Create a new file reader
    reader.onload = function (event) {
      const base64 = event.target.result; // Get the base64 data
      setBase64Data(base64); // Update state with base64 data
    };

    reader.readAsDataURL(file);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setStatus, resetForm, setSubmitting }) => {

      // console.log("Hiiiiiiiiiii", values);
      axios.post(`${BASE_URL_TWO}`, {
        "sp": "insEmployeeCourses",
        "userId": id,
        "employeeId": searchValue,
        "courseName": values?.coursename,
        "academyName": values?.academy,
        "startDate": values?.startDate,
        "endDate": values?.endDate,
        "duration": values?.Duration,
        "status": values?.status,
        "certificateFilePath": base64Data
      }, { headers }).then((res) => {
        showMessage()
        resetForm()
        setBase64Data('');
        setShouldRefetch(prevState => !prevState)
      }).catch((err) => {
        a()
        resetForm()
      })
    },
  });


  const handleAddCourseClick = () => {
    if (!dptId) {
      showError('Please select an employee');
    } else {
      
    }
  };

  return (
    <>
      <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> View / Edit Employee Information</h5>

      <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 mb-10 d-md-flex justify-content-md-center">

        <li className="nav-item">
          <a className="nav-link active" data-bs-toggle="tab" href="#Employee_info">
            <div className="custom-width">
              <a href="#" className="btn btn-flex btn-primary px-6">
                <span className="svg-icon svg-icon-2x">
                  <i className="bi bi-search fs-2 px-5 svg-icon-muted text-white"></i>
                </span>
                <div className="d-flex align-items-center py-2">
                  <span className="bullet bullet-vertical me-5 h-20px w-3px text-white"></span>
                </div>
                <span className="d-flex flex-column align-items-start ms-2">
                  <span className="fs-5 fw-bolder">Employee Details</span>
                </span>
              </a>
            </div>
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            data-bs-toggle="tab"
            href="#courses"
          >
            <a href="#" className="btn btn-flex btn-primary px-6">
              <span className="svg-icon svg-icon-2x"><i className="bi bi-search fs-2 px-5 svg-icon-muted text-white"></i></span>
              <li className="d-flex align-items-center py-2">
                <span className="bullet bullet-vertical me-5 h-20px w-3px text-white"></span>
              </li>
              <span className="d-flex flex-column align-items-start ms-2">
                <span className="fs-5 fw-bolder">Courses Details</span>
              </span>
            </a>
          </a>
        </li>
      </ul>

      <div className="tab-content mb-10" id="myTabContent">
        <div className="tab-pane fade show active" id="Employee_info" role="tabpanel">

          <div className='card'>
            <div className='card-body'>

              <div className="flex-wrap flex-stack mb-6">

                <div className="d-flex my-2">
                  <div className="d-flex gap-2 flex-column position-relative me-4">
                      <h3>Enter the name</h3>
                      <div className='w-250px'>
                            <Autocomplete
                                id='name'
                                options={arr}
                                onChange={(event, value) => {
                                  setEmployerSearchValue(value)
                                  fetchEmployeeDetails(value?.id)
                                  setEmployeeId(value?.id)
                                  setData(null)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Name"
                                        onChange={(e) =>{
                                          setEmployerSearchValue(e.target.value)
                                          setData(null)
                                        } }
                                    />
                                )}
                            />

                        </div>
                  </div>
                </div>

              </div>

              <div
                ref={stepperRef}
                className='stepper stepper-links d-flex flex-column pt-15'
                id='kt_create_account_stepper'
              >
                <div className='stepper-nav mb-5'>

                  <div style={{ background: '', paddingBottom: '2px', borderBottom: steps == 'step1' ? '3px solid #143983' : '' }} className='' >
                    <h6 className='stepper-title fs-6'>Personal Details</h6>
                  </div>

                  <div style={{ paddingBottom: '2px', borderBottom: steps == 'step2' ? '3px solid #143983' : '' }} className='stepper-item' >
                    <h6 className='stepper-title fs-6'>Corporate Details</h6>
                  </div>

                  <div style={{ paddingBottom: '2px', borderBottom: steps == 'step3' ? '3px solid #143983' : '' }} className='stepper-item' >
                    <h6 className='stepper-title fs-6'>Job Description & Company Benefits</h6>
                  </div>

                  <div style={{ paddingBottom: '2px', borderBottom: steps == 'step4' ? '3px solid #143983' : '' }} className='stepper-item' data-kt-stepper-element='nav'>
                    <h6 className='stepper-title fs-6'>Family Members Details</h6>
                  </div>

                  <div style={{ paddingBottom: '2px', borderBottom: steps == 'step5' ? '3px solid #143983' : '' }} className='stepper-item' data-kt-stepper-element='nav'>
                    <h6 className='stepper-title fs-6'>Account Details</h6>
                  </div>
                </div>

                <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>


                  {() => (

                    <Form className='mx-auto mw-700px w-100 pt-15 pb-10' id='kt_create_account_form'>

                      <>

                        <Grid container justifyContent="center" sx={{}}>

                          <Grid container xs={11} sm={11} md={11} lg={11} sx={{}}>
                            {
                              steps == "step1" && (

                                <Step1 result={data?.result1 ? data?.result1[0] : ''} setSteps={setSteps} A={10} personalDetails={personalDetails} setPersonalDetails={setPersonalDetails} />

                              )
                            }

                            {
                              steps === 'step2' && (
                                <Step2 result={data?.result2 ? data?.result2[0] : ''} empId={data?.result1 ? data?.result1[0].userId:''} setSteps={setSteps} setCorporateDetails={setCorporateDetails} />
                              )
                            }

                            {
                              steps === 'step3' && (
                                <Step3 result={data?.result3 ? data?.result3[0] : ''} setSteps={setSteps} setJobDescriptionBenifits={setJobDescriptionBenifits} />
                              )
                            }

                            {
                              steps === 'step4' && (
                                <Step4 result={data?.result4 ? data?.result4 : ''} setFamilyDetails={setFamilyDetails} setSteps={setSteps} />
                              )
                            }

                            {
                              steps === 'step5' && (
                                <Step5
                                  employeDet={data?.result1 ? data?.result1[0] : ''}
                                  setSteps={setSteps}
                                  personalDetails={personalDetails}
                                  corporateDetails={corporateDetails}
                                  jobDescriptionBenifits={jobDescriptionBenifits}
                                  familyDetails={familyDetails}
                                  status={data?.result6 ? data?.result6[0].status:false}
                                />
                              )
                            }

                          </Grid>



                        </Grid>
                      </>

                    </Form>

                  )}

                </Formik>

              </div>
            </div>
          </div>
        </div>
      </div >


      {/* courses */}
      < div className="tab-content mb-10" id="myTabContent" >
        <div className="tab-pane fade" id="courses" role="tabpane2">

          {/* table start from here  */}
          <div className={`card`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Recent Courses</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>Over {courseDetails?.length} Courses</span>
              </h3>

              <div className="d-flex flex-wrap flex-stack mb-6">
                <div className="d-flex my-2">
                  <div className="d-flex align-items-center position-relative me-4">



                    <select
                      onChange={(e) => {
                        setShouldRefetch(prevState => !prevState)
                        setSearchValue(e.target.value)
                        setDptId(e.target.value)
                        // formik.handleChange(e)
                        // setShouldRefetch(prevState => !prevState)
                      }}
                      className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                      name="dptId"
                      autoComplete='off'
                      value={formik.values.dptId}
                    >
                      <option value="">Select Employee</option>
                      {
                        employeList?.map((data, index) =>
                          <option
                            // onClick={() => setDid(data?.deptId)}
                            value={data?.userId}>{data?.employeeName}</option>
                        )
                      }
                    </select>

                  </div>

                </div>
              </div>

              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                title="Add Course"
                //data-bs-toggle="modal"
                //data-bs-target="#kt_modal_1"
                onClick={handleAddCourseClick}
                {...(dptId ? { 'data-bs-target': '#kt_modal_1' } : {})}
                {...(dptId ? { 'data-bs-toggle': 'modal' } : {})}

              >

              <KTIcon iconName='plus-square' className='fs-1' />
              </button>


              <form action="" style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
                <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div style={{ justifyContent: 'space-between' }} className="modal-header bg-primary-subtle">
                        <h5 className="modal-title text-primary">    Add New Course </h5>
                        <div
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                          />
                        </div>

                      </div>
                      <div className="modal-body">


                        <div className='row mb-1'>
                          <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Courses Name</label>
                          <div className='col-lg-6 fv-row'>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                              placeholder=''
                              name='coursename'
                              value={formik.values.coursename}
                              onChange={formik.handleChange}
                            />
                            <span style={{ color: 'red' }} role='alert'>{formik.errors.coursename}</span>

                          </div>

                        </div>

                        <div className='row mb-1'>
                          <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Academy Name</label>
                          <div className='col-lg-6 fv-row'>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                              placeholder=''
                              name='academy'
                              value={formik.values.academy}
                              onChange={formik.handleChange}
                            />
                            <span style={{ color: 'red' }} role='alert'>{formik.errors.academy}</span>

                          </div>
                        </div>

                        <div className='row mb-1'>
                          <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Start Date</label>
                          <div className='col-lg-6 fv-row'>
                            <input
                              type='date'
                              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                              placeholder=''
                              name='startDate'
                              value={formik.values.startDate}
                              onChange={formik.handleChange}
                            />
                            <span style={{ color: 'red' }} role='alert'>{formik.errors.startDate}</span>
                          </div>
                        </div>

                        <div className='row mb-1'>
                          <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>End Date</label>
                          <div className='col-lg-6 fv-row'>
                            <input
                              type='date'
                              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                              placeholder=''
                              name='endDate'
                              value={formik.values.endDate}
                              onChange={formik.handleChange}
                            />
                            <span style={{ color: 'red' }} role='alert'>{formik.errors.endDate}</span>
                          </div>
                        </div>

                        <div className='row mb-1'>
                          <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Duration</label>
                          <div className='col-lg-6 fv-row'>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                              placeholder=''
                              name='Duration'
                              value={formik.values.Duration}
                              onChange={formik.handleChange}
                            />
                            <span style={{ color: 'red' }} role='alert'>{formik.errors.Duration}</span>
                          </div>
                        </div>

                        <div className='row mb-1'>
                          <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Status</label>
                          <div className='col-lg-6 fv-row'>
                            <select
                              className="form-select form-select-solid border fs-7 bg-body-secondary"
                              aria-label="Select example"
                              name="status"
                              value={formik.values.status}
                              onChange={formik.handleChange}
                            >
                              <option value="">Select Status</option>
                              <option value="In-Process">In-Process</option>
                              <option value="Passed">Passed</option>
                              <option value="Failed">Failed</option>
                            </select>
                            {formik.errors.status && (
                              <span style={{ color: 'red' }} role='alert'>{formik.errors.status}</span>
                            )}
                          </div>
                        </div>



                        <div className='row mb-1'>
                          <label className='col-lg-4  col-form-label fw-bold fs-8 text-light-emphasis'>Upload Certificate</label>
                          <div className='col-lg-6 fv-row'>
                            <input
                              type="file"
                              id="imageUpload"
                              className='form-control-file form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                              placeholder=''
                              onChange={handleFileSelect}
                            />
                          </div>
                        </div>

                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>


                </div>
              </form>

            </div>
            {/* end::Header */}
            {/* begin::Body */}

            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className="table table-hover table-striped gs-7" style={{ background: "rgba(20, 57, 131, 0.2)" }}>
                  {/* begin::Table head */}
                  <thead className="bg-primary">
                    <tr className='fw-bold text-white'>
                      <th className='w-25px'>
                        <i className="bi bi-patch-check-fill fs-3 me-2 svg-icon-muted text-white"></i>
                      </th>
                      <th className='min-w-150px'>Courses Name</th>
                      <th className='min-w-140px'>Academy Name</th>
                      <th className='min-w-120px'>Start Date</th>
                      <th className='min-w-120px'>End Date</th>
                      <th className='min-w-120px'>Duration</th>
                      <th className='min-w-120px'>Status</th>
                      <th className='min-w-100px text-end'>View Certificate</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>


                    {
                      courseDetails?.map((data, index) =>

                        <tr>
                          <td>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>

                              {data?.courseName}
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                              {data?.academyName}
                            </a>
                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Code: PH</span> */}
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                              {data?.startDate}
                            </a>
                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Code: Paid</span> */}
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                              {data?.endDate}
                            </a>

                          </td>
                          <td>
                            <span className='badge badge-light-info fs-7'>{data?.duration} Days</span>
                          </td>
                          <td>
                            <span style={{
                              background: data?.status === "Passed" ? '#50cd89' : (data?.status === "In-Process" ? '#ffc700' : (data?.status === "Faild" ? '' : 'green')),
                              color: '#FFF'
                            }} className='badge fs-7'>{data?.status}</span>
                          </td>
                          <td className='text-end'>
                            {data?.certificateFilePath && ( <a href={`${BASE_URL_TWO}${data?.certificateFilePath}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' target="_blank">
                              <KTIcon iconName='document' className='fs-2x' />
                            </a>)}
                          </td>
                        </tr>

                      )
                    }
                  </tbody>

                  {/* end::Table body */}
                </table>

                <Grid container sx={{ bgcolor: '', justifyContent: 'center', my: 4 }}>
                  {courseDetails?.length == 0 && "No Data..."}
                </Grid>

                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>

        </div>
      </div >


    </>
  )
}

export { EmployeeInfo }