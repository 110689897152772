import { useEffect, useMemo, useState } from 'react';

//MRT Imports
//import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table'; //default import deprecated
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
//Material UI Imports
import { Box, IconButton, ListItemIcon, MenuItem } from '@mui/material';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';


//Icons Imports
import { AccountCircle, Delete, Edit, Send } from '@mui/icons-material';

//Mock Data
import { data } from './makeData'
import { message } from "antd";
import { KTIcon } from '../../../_metronic/helpers'
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import Cookies from 'js-cookie';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import axios from 'axios';
import PopUp from '../Modal/Modal';
import EditDepartment from '../../pages/SuperAdminPages/Department/EditDepartment';
import EditEmployeeInfo from './EditEmployeeInfo';


export const Employee = {
    firstName: '',
    lastName: '',
    employeeID: '',
    phoneNo: '',
    emergencyNo: '',
    dateOfBirth: '',
    gender: '',
    maritalStatus: '',
    address: '',
    joiningDate: '',
    mpDate: '',
    totalSalary: '',
    basicPay: '',
    otherAllowances: '',
    jobTitle: '',
    jobNo: '',
    passportNo: '',
    emailID: '',
    visaType: '',
    nationality: '',
    comapany: '',
    dept: '',
    exp: '',
    license: '',
    status: '',
    avatar: '',
};






const EmployeeTable = () => {

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const [shouldRefetch, setShouldRefetch] = useState(false);


    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getEmployeesProfileList&userId=${id}&DisplayLength=50&DisplayStart=0&SortCol=0&SortDir=asc&Search=`, [shouldRefetch]);

    const tableData = fetchedData || [];

    const [show, setShow] = useState()
    const [actionStatus, setActionStatus] = useState(false)
    const [employeeId, setEmployeeId] = useState()

    const showMessage = (msg) => {
        message.success({
            content: msg,
            duration: 4 // Duration in seconds
        });
    };

    const a = () => {
        message.info({
            content: 'Announcement updation faild',
            duration: 2
        });
    }


    useEffect(() => {
        if (actionStatus && employeeId) {
            axios.post(`${BASE_URL_TWO}`, {
                "sp": "delEmployeeProfile",
                "userId": parseInt(id),
                "employeeId": parseInt(employeeId)
            }, { headers })
                .then(() => {
                    showMessage("Deleted successfully");
                    setTimeout(()=>{
                        window.location.reload()
                    },1000)
                    setShouldRefetch(prevState => !prevState);
                })
                .catch(error => {
                    if (error.response) {
                        console.error("Server Error:", error.response.data);
                        a("Server Error: Please try again later.");
                    } else if (error.request) {
                        console.error("Network Error:", error.request);
                        a("Network Error: Please check your internet connection.");
                    } else {
                        console.error("Error:", error.message);
                        a("An unexpected error occurred. Please try again later.");
                    }
                });
        }
    }, [employeeId, actionStatus]);

    const [Employeedata, setData] = useState(data)

    const [eId, setEid] = useState()


    const filpterData = fetchedData?.filter((data, index) => data?.employeeId == eId)

    const selectedData = filpterData ? filpterData[0] : ''

    // console.log("selectedData.............selectedData", selectedData);


    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.firstName} ${row.lastName}`, //accessorFn used to join multiple data into a single cell
                accessorKey: 'employeeName',
                id: 'name', //id is still required when using accessorFn instead of accessorKey
                header: 'Name',
                size: 300,
                enableGrouping: false,
                enablePinning: true,
                muiTableHeadCellProps: {
                    style: {
                        paddingLeft: '1rem'
                    }
                },
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            paddingLeft: '1rem',
                        }}
                    >
                        <img
                            alt="avatar"
                            height={30}
                            src={row.original.Avatar
                                ?`${BASE_URL_TWO}${row.original.Avatar}`: 'https://sipl.ind.in/wp-content/uploads/2022/07/dummy-user.png'}
                            loading="lazy"
                            style={{ borderRadius: '50%',width:"40px",height:"40px" }}
                        />
                        <span>{row.original.employeeName}</span>
                    </Box>
                ),
            },
            {
                accessorKey: 'email',
                enableClickToCopy: true,
                header: 'Email ID',
                size: 300,
                enableGrouping: false,
                enablePinning: false,
            },
            {
                accessorKey: 'gender',
                header: 'Gender',
                size: 150,
                enableGrouping: true,
                enablePinning: false,
            },
            {
                accessorKey: 'address',
                header: 'Address',
                size: 300,
                enableGrouping: false,
                enablePinning: false,
            },
            {
                accessorKey: 'nationality',
                header: 'Nationality',
                size: 200,
                enableGrouping: true,
                enablePinning: false,
            },
            {
                accessorKey: 'maritalStatus',
                header: 'Marital Status',
                size: 200,
                enableGrouping: false,
                enablePinning: false,
            },
            {
                accessorKey: 'phoneNumber',
                header: 'Phone Number',
                size: 250,
                enableGrouping: false,
                enablePinning: false,
            },
            {
                accessorKey: 'emergencyNumber',
                header: 'Emergency Phone Number',
                size: 250,
                enableGrouping: false,
                enablePinning: false,
            },
            {
                accessorKey: 'dateOfBirth',
                id: 'dob',
                header: 'Date of Birth',
                enableGrouping: false,
                enablePinning: false,
                sortingFn: 'datetime',
                Cell: ({ cell }) => {
                    const dateOfBirth = new Date(cell.getValue());
                    const formattedDateOfBirth = dateOfBirth.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    return formattedDateOfBirth;
                },
           },
            {
                accessorKey: 'passportNumber',
                header: 'Passport Number',
                size: 200,
                enableGrouping: false,
                enablePinning: false,
            },
            {
                accessorKey: 'passportExpDate',
                header: 'Passport Exp Date',
                enableGrouping: false,
                enablePinning: false,
                sortingFn: 'datetime',
                Cell: ({ cell }) => {
                    const dateOfBirth = new Date(cell.getValue());
                    const formattedDateOfBirth = dateOfBirth.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    return formattedDateOfBirth;
                },
            },
            {
                accessorKey: 'visaType',
                header: 'Visa Type',
                size: 200,
                enableGrouping: false,
                enablePinning: false,
            },
            {
                accessorKey: 'visaExpDate',
                header: 'Visa Exp Date',
                enableGrouping: false,
                enablePinning: false,
                sortingFn: 'datetime',
                Cell: ({ cell }) => {
                    const dateOfBirth = new Date(cell.getValue());
                    const formattedDateOfBirth = dateOfBirth.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    return formattedDateOfBirth;
                },
            },
            {
                accessorKey: 'IDRCExpDate',
                header: 'RC Expiry Date',
                enableGrouping: false,
                enablePinning: false,
                sortingFn: 'datetime',
                Cell: ({ cell }) => {
                    const dateOfBirth = new Date(cell.getValue());
                    const formattedDateOfBirth = dateOfBirth.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    return formattedDateOfBirth;
                },
            },
            {
                accessorKey: 'bankName',
                header: 'Bank Name',
                size: 200,
                enableGrouping: false,
                enablePinning: false,
            },
            {
                accessorKey: 'bankAcNumber',
                header: 'Bank Account No',
                size: 200,
                enableGrouping: false,
                enablePinning: false,
            },
            {
                accessorKey: 'deptName',
                header: 'Department',
                size: 250,
                enableGrouping: true,
                enablePinning: false,
            },
            {
                accessorKey: 'internalJobTitle',
                id: 'internalJobTitle',
                header: 'Internal Job Title',
                size: 250,
                enableGrouping: true,
                enablePinning: false,
                Cell: ({ cell }) => cell.getValue(),
            },
            {
                accessorKey: 'jobManPowerNumber',
                header: 'Job Manpower Number',
                size: 250,
                enableGrouping: false,
                enablePinning: false,
            },
            {
                accessorKey: 'jobManPowerTitle',
                id: 'manpowerJobTItle',
                header: 'Job Manpower Title',
                size: 250,
                enableGrouping: true,
                enablePinning: false,
                Cell: ({ cell }) => cell.getValue(),
            },
            {
                accessorKey: 'contractFrom',
                id: 'contractFromDate',
                header: 'Contract From',
                enableGrouping: false,
                enablePinning: false,
                sortingFn: 'datetime',
                Cell: ({ cell }) => {
                    const dateOfBirth = new Date(cell.getValue());
                    const formattedDateOfBirth = dateOfBirth.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    return formattedDateOfBirth;
                },
            },
            {
                accessorKey: 'contractTo',
                id: 'contractToDate',
                header: 'Contract To',
                enableGrouping: false,
                enablePinning: false,
                sortingFn: 'datetime',
                Cell: ({ cell }) => {
                    const dateOfBirth = new Date(cell.getValue());
                    const formattedDateOfBirth = dateOfBirth.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    return formattedDateOfBirth;
                },
            },
            {
                accessorKey: 'linemanager1',
                header: 'LineManager1',
                size: 250,
                enableGrouping: true,
                enablePinning: false,
            },
            {
                accessorKey: 'lineManager2',
                header: 'LineManager2',
                size: 250,
                enableGrouping: true,
                enablePinning: false,
            },
            {
                accessorKey: 'tamimahJoiningDate',
                id: 'startDate',
                size: 200,
                header: 'Tamimah Joining Date',
                enableGrouping: false,
                enablePinning: false,
                sortingFn: 'datetime',
                Cell: ({ cell }) => {
                    const dateOfBirth = new Date(cell.getValue());
                    const formattedDateOfBirth = dateOfBirth.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    return formattedDateOfBirth;
                },
            },
            {
                accessorKey: 'manPowerJoiningDate',
                id: 'mpDate',
                size: 200,
                header: 'Manpower Joining Date',
                enableGrouping: false,
                enablePinning: false,
                sortingFn: 'datetime',
                Cell: ({ cell }) => {
                    const dateOfBirth = new Date(cell.getValue());
                    const formattedDateOfBirth = dateOfBirth.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    return formattedDateOfBirth;
                },
            },
            {
                accessorKey: 'totalSalary',
                filterFn: 'between',
                header: 'Total Salary',
                enableGrouping: false,
                enablePinning: false,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() < 50000
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 50000 && cell.getValue() < 75000
                                        ? theme.palette.warning.dark
                                        : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()?.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'OMR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                    </Box>
                )
            },
            {
                accessorKey: 'basicSalary',
                filterFn: 'between',
                header: 'Basic Salary',
                size: 200,
                enableGrouping: false,
                enablePinning: false,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() < 50_000
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 50_000 &&
                                        cell.getValue() < 75_000
                                        ? theme.palette.warning.dark
                                        : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()?.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'OMR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                    </Box>
                ),
            },
            {
                accessorKey: 'employeeWorkStatus',
                header: 'Employee Status',
                size: 200,
                enableGrouping: false,
                enablePinning: false,
            },
        ],
        []
    );
    
    const exportToExcel = () => {
        const orderedData = fetchedData.map(item => ({
            RowNum: item.RowNum,
            EmployeeName: item.employeeName,
            Email: item.email,
            Gender: item.gender,
            Address: item.address,
            Nationality: item.nationality,
            MaritalStatus: item.maritalStatus,
            PhoneNumber: item.phoneNumber,
            EmergencyNumber: item.emergencyNumber,
            DateOfBirth: item.dateOfBirth,
            PassportNumber: item.passportNumber,
            PassportExpDate: item.passportExpDate,
            VisaType: item.visaType,
            IDRCExpDate: item.IDRCExpDate,
            BankName: item.bankName,
            BankAcNumber: item.bankAcNumber,
            InternalJobTitle: item.internalJobTitle,
            JobManPowerNumber: item.jobManPowerNumber,
            JobManPowerTitle: item.jobManPowerTitle,
            ContractFrom: item.contractFrom,
            ContractTo: item.contractTo,
            Linemanager1: item.linemanager1,
            LineManager2: item.lineManager2,
            TamimahJoiningDate: item.tamimahJoiningDate,
            ManPowerJoiningDate: item.manPowerJoiningDate,
            TotalSalary: item.totalSalary,
            BasicSalary: item.basicSalary,
            EmployeeWorkStatus: item.employeeWorkStatus,
        }));
    
        const worksheet = XLSX.utils.json_to_sheet(orderedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `veemployees.xlsx`);
    };
    

    return (
        <>
            <EditEmployeeInfo selectedData={selectedData} />

            <PopUp
                title="Delete employee"
                message="Are you sure you want to Delete this employee"
                btnName="Delete"
                setActionStatus={setActionStatus}
                show={show}
                setShow={setShow}
            />

            <h5 className="text-black-50 mb-10">
                <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> {/* icon */}  Employee Details
            </h5>

            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5 pb-2'>
                    <h3 className='card-title align-items-start flex-column'>

                    </h3>
                    {/* <div className='card-toolbar'>
                        <a className='btn btn-sm btn-light-primary'>
                            <KTIcon iconName='plus' className='fs-2' />
                            New Entry
                        </a>
                    </div> */}
                    <div onClick={exportToExcel} className='card-toolbar'>
                        <a  className='btn btn-sm btn-primary'>
                            <KTIcon iconName='file-down' className='fs-2' />
                            Export to Excel
                        </a>
                    </div>
                </div>
                {/* end::Header */}
                <div className='card-body'>
                    <MaterialReactTable
                        columns={columns}
                        data={tableData}
                        enableDensityToggle={false}
                        enableColumnFilters
                        enableColumnOrdering
                        enablePinning
                        enableRowActions
                        initialState={{
                            showColumnFilters: false,
                            sorting: [
                                { id: "name", desc: false },
                            ],
                            expanded: true,
                            columnPinning: { left: ["name"] }
                        }}
                        positionActionsColumn='last'
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ justifyContent: 'center' }}>
                                <IconButton
                                    onClick={() => {
                                        setShow(true)
                                        setEmployeeId(fetchedData[row.index]?.userId)
                                    }}
                                >
                                    <Delete className='text-danger' />
                                </IconButton>
                            </Box>
                        )}

                        sortingFns={{
                            status: (rowA, rowB, columnID) => {
                                const valA = rowA.getValue < String > (columnID)
                                const valB = rowB.getValue < String > (columnID)
                                if (valA === 'Pending' && valB !== 'Pending') {
                                    return 1
                                }
                                else if (valB === 'Pending' && valA !== 'Pending') {
                                    return -1
                                }
                                else {
                                    return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1
                                }
                            }
                        }}
                        
                        filterFns={{
                            dateFilter: (row, columnId, filterValue) => {
                                const filterArray = filterValue.replaceAll(',', ';').split(';')
                                const cellVal = row.getValue < Date > (columnId)
                                const monthName = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec']
                                for (let item of filterArray) {
                                    const itemArray = item.replaceAll(/\s+/g, '#').split('#')
                                    console.log(filterArray, item, itemArray)
                                    if (cellVal.getFullYear().toString() === itemArray[itemArray?.length - 1])
                                        for (let i = 0; i < itemArray?.length - 1; i++) {
                                            if (itemArray[i].includes(monthName[cellVal.getMonth()]))
                                                return true
                                        }
                                }
                                return false
                            },
                            multipleFilter: (row, columnId, filterValue) => {
                                const filterArray = filterValue.replaceAll(',', ';').split(';')
                                const cellVal = row.getValue < String > (columnId)
                                for (let item of filterArray) {
                                    if (cellVal.toLocaleLowerCase().includes(item.toLocaleLowerCase()))
                                        return true
                                }
                                return false
                            }
                        }}

                        muiTablePaperProps={{
                            sx: {
                                backgroundColor: 'var(--bs-card-bg)',
                                border: 'none',
                                boxShadow: 'none'
                            }
                        }}
                        muiTableProps={{
                            className: 'table',
                            sx: {
                                borderCollapse: 'separate',
                                borderSpacing: '0px 4px',
                            }
                        }}
                        muiTableContainerProps={{
                            className: 'mb-2',
                            sx: {
                                backgroundColor: 'var(--bs-light)',
                                borderRadius: 'var(--bs-card-border-radius)',
                            }
                        }}
                        muiTableHeadRowProps={{
                            className: 'fw-bold bg-light',
                            sx: {
                                boxShadow: 'none'
                            }
                        }}
                        muiTableHeadCellProps={{
                            className: 'px-7 py-3 text-black',
                            sx: {
                                verticalAlign: 'middle',
                                backgroundColor: '#EBEBD3 !important',
                                outline: '10px',
                                outlineStyle: 'solid',
                                outlineColor: 'var(--bs-light)',
                                outlineOffset: '-5px',
                                borderRadius: '20px',
                            }
                        }}
                        muiTableBodyRowProps={{
                            hover: false,
                            sx: {
                                backgroundColor: 'transparent'
                            }
                        }}
                        muiTableBodyCellProps={{
                            className: 'bg-light bg-hover-secondary'
                        }}
                        muiTopToolbarProps={{
                            className: 'card text-light mb-2',
                            sx: {
                                boxShadow: 'none',
                                backgroundColor: '#143983'
                            },
                        }}
                        muiBottomToolbarProps={{
                            className: 'text-light mb-5 d-flex',
                            sx: {
                                boxShadow: 'none',
                                backgroundColor: '#143983',
                                borderRadius: 'var(--bs-card-border-radius)',
                                minHeight: '50px'
                            },
                        }}
                        muiSearchTextFieldProps={{
                            className: 'bg-light text-light text-muted'
                        }}
                        muiTableHeadCellFilterTextFieldProps={{
                            helperText: false,
                            focused: true,
                            inputProps: {
                                className: "text-black",
                            },
                            sx: {
                                color: 'black'
                            }
                        }}
                        muiTableHeadCellColumnActionsButtonProps={{
                            sx: {
                                color: 'black'
                            }
                        }}
                        muiTableHeadCellDragHandleProps={{
                            sx: {
                                color: 'black'
                            }
                        }}
                        icons={{
                            FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
                            FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
                            ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
                            FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                            FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                            SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
                            CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
                        }}
                        muiTablePaginationProps={{
                            className: 'text-white',
                        }}
                        enableGlobalFilter={false}
                        muiToolbarAlertBannerProps={{
                            className: 'text-white',
                            sx: {
                                backgroundColor: '#143983',
                            }
                        }}
                        muiToolbarAlertBannerChipProps={{
                            className: 'text-white'
                        }}
                    />
                </div>
            </div >

        </>
    );
};

export default EmployeeTable;