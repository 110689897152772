// import { StaffDetails } from './InsuredStaffTable';
// import Data from './Data/Data.json'

// export const data: StaffDetails[] = Data;


import StaffDetails from './InsuredStaffTable';
import Data from './Data/Data.json';

const data = Data;

export default data;
