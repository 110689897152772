import { Grid } from '@mui/material'
import React from 'react'

function NoFiles() {
  return (
    <div className=' d-flex justify-content-center align-items-center flex-grow-1'>
        <div className='d-flex justify-content-center align-items-center flex-column flex-grow-1'style={{ minHeight: '600px' }}>
            <img
            src='https://cdn-icons-png.flaticon.com/512/7466/7466140.png'
            alt="icon"
            style={{ width:"100%" ,maxWidth: '80px', height: 'auto' }}
            />
            <h6 style={{ background: '' }} className='container mx-auto text-center my-3 text-2xl'>
                No Files
            </h6>
        </div>
    </div>
  )
}

export default NoFiles;