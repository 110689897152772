import React, { useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useFetchData from '../../hooks/getData';
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import Cookies from 'js-cookie';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import { message } from "antd";

const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required('Customer name is required'),
    otherAllowance: Yup.string().required('Allowance amount is required'),
    overTimeSalary: Yup.string().required('Over time amount is required'),
    appraisal: Yup.string().required('Appraisal amount is required'),
    deductionAmount: Yup.string().required('Deduction amount amount is required'),
    incentiveAmount: Yup.string().required('Incentive amount is required'),
    bankName: Yup.string().required('Bank name is required'),
    bankAccount: Yup.string().required('Select your bank account'),
})

const PayrollTableForm: React.FC = () => {

    const [bankAccount, setBankAccount] = useState()

    const initialValues = {
        employeeName: '',
        mpStartDate: '',
        totalSalary: '',
        basicSalary: '',
        otherAllowance: '',
        overTimeSalary: '',
        appraisal: '',
        deductionAmount: '',
        incentiveAmount: '',
        bankName: '',
        bankAccount: ''
    }

    const showMessage = () => {
        message.success({
            content: 'Successfully added new payroll',
            duration: 1
        });
    };

    const employeList = useFetchData('getEmployeesList').fetchedData;

    const [employeId, setEmployeName] = useState()

    const [shouldRefetch, setShouldRefetch] = useState(false);

    console.log("employeId", employeId);

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    // const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getEmployeeDetailsForInsurance&employeeId=${employeId}&userId=${id}`, [shouldRefetch])

    // const details = fetchedData ? fetchedData[0] : '';

    // console.log("details", details);


    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            axios.post(`${BASE_URL_TWO}`, {
                sp: 'insEmployeePayRoll',
                employeeId: employeId,
                userId: id,
                otherAllowance: values?.otherAllowance,
                overTimeSalary: values?.overTimeSalary,
                appraisal: values?.appraisal,
                deductionAmount: values?.deductionAmount,
                incentiveAmount: values?.incentiveAmount,
                bankName: values?.bankName,
                bankAccount: values?.bankAccount,
            }, { headers }).then(() => {
                showMessage()
                setTimeout(function () {
                    window.location.reload()
                }, 1500);
            })

        }
    });

    return (
        <div className="modal fade" id="kt_modal_1" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">

            <form action="" onSubmit={formik.handleSubmit}>

                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Employee Payroll Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Employee Name</label>
                                    <select
                                        onChange={(e) => {
                                            setEmployeName(e.target.value)
                                            formik.handleChange(e)
                                            setShouldRefetch(prevState => !prevState)
                                        }}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="employeeName"
                                        autoComplete='off'
                                        value={formik.values.employeeName}
                                    >
                                        <option>Select employee</option>
                                        {
                                            employeList?.map((data, index) =>
                                                <option value={data?.userId}>{data?.employeeName}</option>
                                            )
                                        }
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.employeeName}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">MP Start Date</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="mpStartDate"
                                        autoComplete='off'
                                        value={formik.values?.MpStartDate}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.mpStartDate}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Total Salary</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="totalSalary"
                                        autoComplete='off'
                                        value={formik.values?.totalSalary}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.totalSalary}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Basic Salary</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="basicSalary"
                                        autoComplete='off'
                                        value={formik.values?.basicSalary}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.basicSalary}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Other Allowance</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="otherAllowance"
                                        autoComplete='off'
                                        value={formik.values?.otherAllowance}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.otherAllowance}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Overtime Salary</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="overTimeSalary"
                                        autoComplete='off'
                                        value={formik.values?.overTimeSalary}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.overTimeSalary}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Appraisal</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="appraisal"
                                        autoComplete='off'
                                        value={formik.values?.appraisal}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.appraisal}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Deduction Amount</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="deductionAmount"
                                        autoComplete='off'
                                        value={formik.values?.deductionAmount}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.deductionAmount}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Incentive Amount</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="incentiveAmount"
                                        autoComplete='off'
                                        value={formik.values?.incentiveAmount}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.incentiveAmount}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Bank Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="bankName"
                                        autoComplete='off'
                                        value={formik.values?.bankName}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.bankName}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Bank Account</label>
                                    <select
                                        onChange={(e) => {
                                            setBankAccount(e.target.value)
                                            formik.handleChange(e)
                                        }}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="bankAccount"
                                        autoComplete='off'
                                        value={formik.values.bankAccount}
                                    >
                                        <option>Select Bank Account</option>
                                        <option value="single">Bank Muscat</option>
                                        <option value="married">Meethaq (Islamic)</option>
                                        <option value="divorced">Ahli Bank</option>
                                        <option value="divorced">Al-Hilal Bank (Islamic)</option>
                                        <option value="widowed">Al-Izz Bank (Islamic)</option>
                                        <option value="widowed">Al-Yusr Bank (Islamic)</option>
                                        <option value="widowed">Bank Dhofar</option>
                                        <option value="widowed">Bank Meli Iran</option>
                                        <option value="widowed">Bank Of Baroda</option>
                                        <option value="widowed">Bank Of Beirut</option>
                                        <option value="widowed">Bank Saderat Iran</option>
                                        <option value="widowed">Bank Sohar</option>
                                        <option value="widowed">Bank Sohar (Islamic)</option>
                                        <option value="widowed">Habib Bank Limited</option>
                                        <option value="widowed">HSBC Oman</option>
                                        <option value="widowed">Maisara (Islamic)</option>
                                        <option value="widowed">Muzn Bank (Islamic)</option>
                                        <option value="widowed">National Bank of Abu Dhabi</option>
                                        <option value="widowed">National Bank Of Oman</option>
                                        <option value="widowed">Bank Nizwa (Islamic)</option>
                                        <option value="widowed">Oman Arab Bank</option>
                                        <option value="widowed">Oman Development Bank</option>
                                        <option value="widowed">Oman Housing Bank</option>
                                        <option value="widowed">Qatat National Bank</option>
                                        <option value="widowed">Standard Chartard Bank</option>
                                        <option value="widowed">State Bank Of India</option>
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.bankAccount}</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Add
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    );
};

export default PayrollTableForm;
























