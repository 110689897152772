import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTIcon, KTSVG, WithChildren } from '../../../../helpers'
import { useLayout } from '../../../core'


const SidebarMenuItem = (props) => {

  const { children, to, title, icon, fontIcon, hasBullet = false, iconpath, permission,onClick } = props

  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { app } = config

  // console.log("permissionGroup?????????????????????????", permission);


  return (
    <div style={{
      display: permission ? 'flex' : 'none'
    }} className='menu-item'onClick={onClick}>
      <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2x' />
          </span>
        )}
        {iconpath && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}

            <KTSVG path={iconpath} className='svg-icon-muted svg-icon-2x' />

          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export { SidebarMenuItem }
