import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// Setting the worker src to avoid warnings
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFViewer = ({ pdfUrl }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div style={{overflowX:"auto"}}>
            <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                className='overflow-x-auto'
            >
                <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}/>
            </Document>
        
            <div className='mt-4'>
                <button
                    onClick={() => setPageNumber(prevPage => Math.max(prevPage - 1, 1))}
                    disabled={pageNumber <= 1}
                    className='bg-primary text-white rounded py-1 px-2 me-2'
                >
                    Previous
                </button>
                <span>Page {pageNumber} of {numPages}</span>
                <button
                    onClick={() => setPageNumber(prevPage => Math.min(prevPage + 1, numPages))}
                    disabled={pageNumber >= numPages}
                    className=' bg-primary text-white  rounded py-1 px-2 ms-2'
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default PDFViewer;
