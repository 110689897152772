import { useMemo, FC, useState, useEffect } from 'react';

//MRT Imports
//import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table'; //default import deprecated
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
//Material UI Imports
import { Autocomplete, Box, IconButton, TextField } from '@mui/material';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';


//Mock Data
import { data } from './makeData';
import { KTIcon, KTSVG } from '../../../_metronic/helpers';
import { Delete, Edit } from '@mui/icons-material';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import Cookies from 'js-cookie';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import axios from 'axios';
import { Button, message } from "antd";
import PayrollTableForm from './PayrollTableForm';
import PopUp from '../Modal/Modal';
import EditDepartment from '../../pages/SuperAdminPages/Department/EditDepartment';
import PayRollEditForm from './PayRollEditForm';


export const PayrollDetails = {
    employeeName: '',
    totalSalary: '',
    basicSalary: '',
    mpStartDate: '',
    mpEndDate: '',
    overtimeAllowance: '',
    appraisal: '',
    deductionType: '',
    deductionAmt: '',
    housing: '',
    transport: '',
    electricity: '',
    water: '',
    costOfLeaving: '',
    motivational: '',
    other: '',
    gratuity: '',
    incentives: ''

};

const PayrollTable = () => {

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const [recievedData, setData] = useState(data)
    const yearOptions = ['2020', '2021', '2022', '2023', '2024']
    const MonthOptions = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "All"]
    const nowDate = new Date()
    const [month, setMonth] = useState((nowDate.getMonth()) + 1)
    const [year, setYear] = useState(nowDate.getFullYear())
    const [Name, setName] = useState();
    const [employeeId,setEmployeeId] =useState()
    const [editData,setEditData] = useState()

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const employeList = useGetDataWithFullUrl(`${BASE_URL}getEmployeeIDList&userId=${id}`)?.fetchedData

    const url = `${BASE_URL}getEmployeePayRollList&userId=${id}`+ ((month === undefined || month == 12)?`&month=`:`&month=${month+1}`)+ `&year=${year}` + (employeeId ? `&employeeId=${employeeId}` : `&employeeId=`);
    const { fetchedData } = useGetDataWithFullUrl(url, [shouldRefetch]);

    let arr = []

    employeList?.map((data, index) => {
        arr.push({
            label:data?.employeeName,
            id:data?.userId
        })
    })

    const showMessage = () => {
        message.success({
            content: 'Deleted successfully',
            duration: 1 // Duration in seconds
        });
    };


    const a = (msg) => {
        message.info({
            content: msg,
            duration: 2
        });
    }

    const [show, setShow] = useState();
    const [actionStatus, setActionStatus] = useState();
    const [deletId, setDeletId] = useState()

    useEffect(() => {
        if (actionStatus && deletId) {
            axios.post(`${BASE_URL_TWO}`, {
                "sp": "delEmployeePayRoll",
                "userId": id,
                "payRollId": deletId
            }, { headers })
                .then(() => {
                    showMessage("Deleted successfully");
                    setShouldRefetch(prevState => !prevState);
                    setActionStatus(false)
                })
                .catch(error => {
                    if (error.response) {
                        console.error("Server Error:", error.response.data);
                        a("Failed to delete");
                    } else if (error.request) {
                        console.error("Network Error:", error.request);
                        a("Failed to delete");
                    } else {
                        console.error("Error:", error.message);
                        a("Failed to delete");
                    }
                });
        }
    }, [deletId, actionStatus]);


    const tableData = fetchedData || [];

    const columns = useMemo(
        () => [

            {
                accessorFn: (row) => `${row.firstName}`, //accessorFn used to join multiple data into a single cell
                id: 'name',
                header: 'First Name',
                enablePinning: true,
                size: 150,
                muiTableHeadCellProps: {
                    style: {
                        paddingLeft: '1rem'
                    }
                },
                muiTableBodyCellProps: {
                    style: {
                        paddingLeft: '1rem'
                    }
                }
            },
            {
                accessorKey: 'lastName',
                header: 'Last Name',
                size: 150,
            },

            {
                accessorKey: 'dateOfJoining',
                header: 'Internal Date of Joining',
                sortingFn: 'datetime',
                size: 200,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return date.toLocaleDateString('en-GB',{
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                }
            },
            {
                accessorKey: 'MpJoiningDate',
                header: 'MP Date of Joining',
                sortingFn: 'datetime',
                size: 200,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return date.toLocaleDateString('en-GB',{
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                }
            },

            {
                accessorKey: 'totalSalary',
                header: 'Total Salary',
                size: 150,
                filterFn: 'between',
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() < 300
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 300 && cell.getValue() < 600
                                        ? theme.palette.warning.dark
                                        : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()?.toLocaleString?.('en-US', {
                            style: 'currency',
                            currency: 'OMR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                    </Box>
                ),
            },
            {
                accessorKey: 'basicSalary',
                header: 'Basic Salary',
                size: 150,
                filterFn: 'between',
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() < 300
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 300 &&
                                        cell.getValue() < 600
                                        ? theme.palette.warning.dark
                                        : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()?.toLocaleString?.('en-US', {
                            style: 'currency',
                            currency: 'OMR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                    </Box>
                ),
            },

            {
                accessorKey: 'bankName',
                header: 'Bank Name',
                size: 200,
            },
            {
                accessorKey: 'bankAcNumber',
                header: 'Bank Account Number',
                size: 200,
            },
            {
                accessorKey: 'otherAllowance',
                header: 'Other Allowance',
                size: 150,
                filterFn: 'between',
            },
            {
                accessorKey: 'overtimeSalary',
                header: 'Overtime Salary',
                size: 150,
                filterFn: 'between',
            },
            {
                accessorKey: 'appraisal',
                header: 'Appraisal',
                size: 150,
                filterFn: 'between',
            },
            {
                accessorKey: 'deductionAmount',
                header: 'Deduction Amount',
                size: 150,
                filterFn: 'between',
            },
            {
                accessorKey: 'incentiveAmount',
                header: 'Incentive Amount',
                size: 150,
                filterFn: 'between',
            },
            {
                accessorKey: 'GratuityAmount',
                header: 'Gratuity Amount',
                size: 150,
                // Cell: ({ cell, row }) => {
                //     const basicSalary = row.original.basicSalary;
                //     const monthsWorked = (new Date().getFullYear() - new Date(row.original.internalJoiningDate).getFullYear()) * 12;
                //     const gratuity = (basicSalary * monthsWorked) / 12;
                //     return gratuity.toLocaleString('en-US', {
                //         style: 'currency',
                //         currency: 'OMR',
                //         minimumFractionDigits: 0,
                //         maximumFractionDigits: 0,
                //     });
                // }
            },
            {
                accessorKey: 'comments',
                header: 'Comments',
                size: 200,
            },
            {
                accessorKey: 'status',
                header: 'Employee Status',
                size: 150,
            },
        ],
        []
    );
    
    const exportToExcel = () => {
        const filteredData = fetchedData.map((data) => {
            return {
                FirstName: data.firstName,
                LastName: data.lastName,
                InternalDateOfJoining: data.dateOfJoining, // Use the correct key from your data
                MpDateOfJoining: data.MpJoiningDate,             // Use the correct key from your data
                TotalSalary: data.totalSalary,
                BasicSalary: data.basicSalary,
                BankName: data.bankName,
                BankAccountNumber: data.bankAcNumber,
                OtherAllowance: data.otherAllowance,
                OvertimeSalary: data.overtimeSalary,
                Appraisal: data.appraisal,
                DeductionAmount: data.deductionAmount,
                IncentiveAmount: data.incentiveAmount,
                GratuityAmount: data.GratuityAmount,
                Comments: data.comments,
                EmployeeStatus: data.status,
            };
        });
    
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'payroll.xlsx');
    };
    
    return (
        <>

            <PayRollEditForm selectedData={editData} month={month+1}/>

            <PopUp
                show={show}
                setShow={setShow}
                setActionStatus={setActionStatus}
                title="Delete Employee pay roll"
                message="Are you sure you want to delete"
                btnName="Delete"
            />

            <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> Employee Payroll</h5>
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5 pb-2'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Employee Payroll Split Details</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>[{tableData?.length} entries]</span>
                    </h3>

                    <div className='card-toolbar'>
                        <a onClick={exportToExcel} className='btn btn-sm btn-primary'>
                            <KTIcon iconName='file-down' className='fs-2' />
                            Export to Excel
                        </a>
                    </div>
                </div>
                {/* end::Header */}
                <div className='card-body'>
                    <h2>Enter the Month</h2>
                    <div className='row mt-md-2 mt-xl-4 mb-md-4 mb-md-8 mb-5'>
                        <div className='col-md-3'>
                            <Autocomplete
                                disablePortal
                                id='yearField'
                                color="primary"
                                options={MonthOptions}
                                onInputChange={(event, value) => {
                                    setMonth(MonthOptions.indexOf(value) !== -1 ? MonthOptions.indexOf(value) : nowDate.getMonth())
                                    setShouldRefetch(prevState => !prevState)
                                }}
                                onChange={(event, value) => {
                                    setMonth(MonthOptions.indexOf(value ?? MonthOptions[nowDate.getMonth()]) !== -1 ? MonthOptions.indexOf(value ?? MonthOptions[nowDate.getMonth()]) : nowDate.getMonth())
                                    setShouldRefetch(prevState => !prevState)
                                }}
                                classes={{
                                    input: 'text-dark'
                                }}
                               defaultValue={MonthOptions[nowDate.getMonth()]}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Month"
                                    />
                                }
                            />
                        </div>

                        <div className='col-md-3'>
                            <Autocomplete
                                id='name'
                                options={arr}
                                onChange={(event, value) => {
                                    setShouldRefetch(prevState => !prevState);
                                    setEmployeeId(value?.id)
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Name/ID"
                                        onChange={() => setShouldRefetch(prevState => !prevState)}
                                    />
                                )}
                            />

                        </div>
                    </div>


                    <MaterialReactTable
                        columns={columns}
                        data={tableData}
                        enableDensityToggle={false}
                        enableColumnFilters
                        enableColumnOrdering
                        enablePinning
                        enableRowActions
                        // enableColumnResizing
                        // columnResizeMode= 'onChange'
                        initialState={{
                            showColumnFilters: false,
                            // grouping: ["approval"], 
                            sorting: [
                                { id: "name", desc: false },
                            ],
                            expanded: true,
                            columnPinning: { left: ["name"] }
                        }}
                        positionToolbarAlertBanner="bottom"
                        positionActionsColumn='last'
                        renderRowActions={({ row, table }) => [
                            <Box sx={{ justifyContent: 'center' }}>
                                <a href="/"
                                    onClick={() => setEditData(fetchedData?.filter((data) => data?.employeeId== fetchedData[row.index].employeeId))}
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    title="New Entry"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_3">
                                    <IconButton
                                    // onClick={() => table.setEditingRow(row)}
                                    >
                                        <Edit className='text-success' />
                                    </IconButton>
                                </a>

                                <IconButton

                                    onClick={() => {
                                        if(fetchedData[row.index].payrollId !== null){
                                            setShow(true)
                                            setDeletId(fetchedData[row.index].payrollId)
                                        }else{
                                            alert('No data to delete')
                                        }
                                    }}

                                >
                                    <Delete className='text-danger' />
                                </IconButton>
                            </Box>
                        ]}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: 'Action',
                                size: 80,
                                muiTableHeadCellProps: {
                                    className: 'px-7 py-4 text-black',
                                    sx: {
                                        alignItems: 'center',
                                        verticalAlign: 'middle',
                                        backgroundColor: '#EBEBD3 !important',
                                        outline: '6px',
                                        outlineStyle: 'solid',
                                        outlineColor: 'var(--bs-light)',
                                        outlineOffset: '-5px',
                                        borderRadius: '20px',
                                    }
                                }
                            },
                        }}
                        sortingFns={{
                            status: (rowA, rowB, columnID) => {
                                const valA = rowA.getValue < String > (columnID)
                                const valB = rowB.getValue < String > (columnID)
                                if (valA === 'Pending' && valB !== 'Pending') {
                                    return 1
                                }
                                else if (valB === 'Pending' && valA !== 'Pending') {
                                    return -1
                                }
                                else {
                                    return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1
                                }
                            }
                        }}
                        filterFns={{
                            dateFilter: (row, columnId, filterValue) => {
                                const filterArray = filterValue.replaceAll(',', ';').split(';')
                                const cellVal = row.getValue < Date > (columnId)
                                const monthName = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec']
                                for (let item of filterArray) {
                                    const itemArray = item.replaceAll(/\s+/g, '#').split('#')
                                    console.log(filterArray, item, itemArray)
                                    if (cellVal.getFullYear().toString() === itemArray[itemArray?.length - 1])
                                        for (let i = 0; i < itemArray?.length - 1; i++) {
                                            if (itemArray[i].includes(monthName[cellVal.getMonth()]))
                                                return true
                                        }
                                }
                                return false
                            },
                            multipleFilter: (row, columnId, filterValue) => {
                                const filterArray = filterValue.replaceAll(',', ';').split(';')
                                const cellVal = row.getValue < String > (columnId)
                                for (let item of filterArray) {
                                    if (cellVal.toLocaleLowerCase().includes(item.toLocaleLowerCase()))
                                        return true
                                }
                                return false
                            }
                        }}

                        // enableEditing
                        // editingMode='modal'
                        muiTablePaperProps={{
                            sx: {
                                backgroundColor: 'var(--bs-card-bg)',
                                border: 'none',
                                boxShadow: 'none'
                            }
                        }}
                        muiTableProps={{
                            className: 'table',
                            sx: {
                                borderCollapse: 'separate',
                                borderSpacing: '0px 4px',
                            }
                        }}
                        muiTableContainerProps={{
                            className: 'mb-2',
                            sx: {
                                backgroundColor: 'var(--bs-light)',
                                borderRadius: 'var(--bs-card-border-radius)',
                            }
                        }}
                        muiTableHeadRowProps={{
                            className: 'fw-bold bg-light',
                            sx: {
                                boxShadow: 'none'
                            }
                        }}
                        muiTableHeadCellProps={{
                            className: 'px-7 py-3 text-black',
                            sx: {
                                verticalAlign: 'middle',
                                backgroundColor: '#EBEBD3 !important',
                                outline: '10px',
                                outlineStyle: 'solid',
                                outlineColor: 'var(--bs-light)',
                                outlineOffset: '-5px',
                                borderRadius: '20px',
                            }
                        }}
                        muiTableBodyRowProps={{
                            hover: false,
                            sx: {
                                backgroundColor: 'transparent'
                            }
                        }}
                        muiTableBodyCellProps={{
                            className: 'bg-light bg-hover-secondary ',
                        }}
                        muiTopToolbarProps={{
                            className: 'card text-light mb-2',
                            sx: {
                                boxShadow: 'none',
                                backgroundColor: '#143983'
                            },
                        }}
                        muiBottomToolbarProps={{
                            className: 'text-light mb-5 d-flex',
                            sx: {
                                boxShadow: 'none',
                                backgroundColor: '#143983',
                                borderRadius: 'var(--bs-card-border-radius)',
                                minHeight: '50px'
                            },
                        }}
                        muiSearchTextFieldProps={{
                            className: 'bg-light text-light text-muted'
                        }}
                        muiTableHeadCellFilterTextFieldProps={{
                            helperText: false,
                            focused: true,
                            inputProps: {
                                className: "text-black",
                            },
                            sx: {
                                color: 'black'
                            }
                        }}
                        muiTableHeadCellColumnActionsButtonProps={{
                            sx: {
                                color: 'black'
                            }
                        }}
                        muiTableHeadCellDragHandleProps={{
                            sx: {
                                color: 'black'
                            }
                        }}
                        icons={{
                            FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
                            FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
                            ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
                            FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                            FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                            SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
                            CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
                        }}
                        muiTablePaginationProps={{
                            className: 'text-white',
                        }}
                        enableGlobalFilter={false}
                        muiToolbarAlertBannerProps={{
                            className: 'text-white',
                            sx: {
                                backgroundColor: '#143983',
                            }
                        }}
                        muiToolbarAlertBannerChipProps={{
                            className: 'text-white'
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default PayrollTable;