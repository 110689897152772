import { useMemo, FC, useState } from 'react';

//MRT Imports
//import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table'; //default import deprecated
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';

//Material UI Imports
import { Autocomplete, Box, IconButton, TextField } from '@mui/material';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';


//Mock Data
import { data } from './makeData';
import { KTIcon, KTSVG } from '../../../_metronic/helpers';
import { Delete, Edit } from '@mui/icons-material';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import Cookies from 'js-cookie';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import axios from 'axios';

import EmployeeExpenseForm from './EmployeeExpenseForm';

export const PayrollDetails = {
    employeeName: '',
    totalSalary: '',
    basicSalary: '',
    mpStartDate: '',
    mpEndDate: '',
    overtimeAllowance: '',
    appraisal: '',
    deductionType: '',
    deductionAmt: '',
    housing: '',
    transport: '',
    electricity: '',
    water: '',
    costOfLeaving: '',
    motivational: '',
    other: '',
    gratuity: '',
    incentives: ''

};

const EmployeeExpense = () => {

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const [recievedData, setData] = useState(data)
    const yearOptions = ['2020', '2021', '2022', '2023', '2024']
    const MonthOptions = ["january", "february", "march", "april", "may", "June", "July", "August", "September", "October", "November", "December", "All"]
    const NameOptions = ["Rawan/156", "Rawan/156", "Rawan/156", "Rawan/156", "Rawan/156", "Rawan/156"]
    const nowDate = new Date()
    const [month, setMonth] = useState(nowDate.getMonth())
    const [year, setYear] = useState(nowDate.getFullYear())
    const [Name, setName] = useState(NameOptions[0]);

    console.log("month", MonthOptions[month]);
    console.log("month", month);

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    // const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getEmployeePayRollList&userId=${id}&month=${MonthOptions[month]}&year=${year}`, [shouldRefetch]);

    // console.log("fetchedData............:", fetchedData);

    const fetchedData = []

    const tableData = fetchedData || [];

    console.log("....................Employee Expensssssse ......... ", tableData ? tableData[0] : '');
    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.employeeName}`, //accessorFn used to join multiple data into a single cell
                id: 'name',
                header: 'Employee Name',
                size: 200,
                filterFn: 'multipleFilter',
                enablePinning: true,
                muiTableHeadCellProps: {
                    style: {
                        paddingLeft: '1rem'
                    }
                },
                muiTableBodyCellProps: {
                    style: {
                        paddingLeft: '1rem'
                    }
                }
            },
            {
                accessorKey: 'totalSalary',
                filterFn: 'between',
                header: 'Total Salary',
                enablePinning: false,
                //custom conditional format and styling
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() < 300
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 300 && cell.getValue() < 600
                                        ? theme.palette.warning.dark
                                        : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()?.toLocaleString?.('en-US', {
                            style: 'currency',
                            currency: 'OMR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                    </Box>

                ),
            },
            {
                accessorKey: 'airTicket',
                header: 'Air Ticket',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Air Ticket</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'basicSalary',
                header: 'Basic Salary',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Basic Salary</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'fuelBasedOnReimbursement',
                header: 'Fuel based on reimbursement',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Fuel Based on</div>
                            <div className='th'>Reimbursement</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'companyVehicle',
                header: 'company vehicle',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Company</div>
                            <div className='th'>Vehicle</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'plateNumber',
                header: 'plate number',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Plate</div>
                            <div className='th'>Number</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'bankName',
                header: 'Bank Name',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Bank</div>
                            <div className='th'>Name</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'bankAccount',
                header: 'Bank Account',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Bank</div>
                            <div className='th'>Account</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'carInsurance',
                header: 'car insurance',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Car Insurance</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'carRenewalFee',
                header: 'car renewal fee',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Car Renewal Fee</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'medicalInsurance',
                header: 'Medical Insurance',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Medical Insurance</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'commissionForSales',
                header: 'commission for sales',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Commission For Sales</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'visaExpense',
                header: 'Visa Expense',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Visa Expense</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'visaExpenseFamily',
                header: 'Visa Expense family',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Visa Expense For Family</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'gratuity',
                header: 'gratuity',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Gratuity</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'pasi',
                header: 'pasi',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Pasi</div>
                        </div>
                    )
                }
            },
            {
                accessorKey: 'YearsOfExperinceTamimah',
                header: 'YearsOfExperinceTamimah',
                filterFn: 'between',
                enablePinning: false,
                size: 100,
                Header: () => {
                    return (
                        <div style={{ verticalAlign: 'middle' }}>
                            <div className='th'>Taimah Experince</div>
                        </div>
                    )
                }
            },

        ], []);

    // console.log("fetchedData", fetchedData ? fetchedData[0] : '');

    return (
        <>

            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5 pb-2'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Employee Expense</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>[{tableData.length} entries]</span>
                    </h3>

                    <div>
                        <div className="card-toolbar">
                            {/* Button to open the modal */}
                            <a
                                className="btn btn-sm btn-light-primary"
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                                title="New Entry"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_2">
                                <KTIcon iconName="plus" className="fs-2" />
                                New Entry
                            </a>
                        </div>

                        <EmployeeExpenseForm onAdd={() => console.log('Add button clicked')} />

                    </div>
                    <div className='card-toolbar'>
                        <a className='btn btn-sm btn-primary'>
                            <KTIcon iconName='file-down' className='fs-2' />
                            Export to Excel
                        </a>
                    </div>
                </div>
                {/* end::Header */}
                <div className='card-body'>
                    <h2>Enter the Month and Year</h2>
                    <div className='row mt-md-2 mt-xl-4 mb-md-4 mb-md-8 mb-5'>
                        <div className='col-md-3'>
                            <Autocomplete
                                disablePortal
                                id='yearField'
                                color="primary"
                                options={MonthOptions}
                                onInputChange={(event, value) => {
                                    setMonth(MonthOptions.indexOf(value) !== -1 ? MonthOptions.indexOf(value) : nowDate.getMonth())
                                    setShouldRefetch(prevState => !prevState)
                                }}
                                onChange={(event, value) => {
                                    setMonth(MonthOptions.indexOf(value ?? MonthOptions[nowDate.getMonth()]) !== -1 ? MonthOptions.indexOf(value ?? MonthOptions[nowDate.getMonth()]) : nowDate.getMonth())
                                    setShouldRefetch(prevState => !prevState)
                                }}
                                classes={{
                                    input: 'text-dark'
                                }}
                                defaultValue={MonthOptions[nowDate.getMonth()]}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Month"
                                    />
                                }
                            />
                        </div>
                        <div className='col-md-3'>
                            <Autocomplete
                                color="primary"
                                id='year'
                                options={yearOptions}
                                onInputChange={(event, value) => {
                                    setShouldRefetch(prevState => !prevState)
                                    setYear(parseInt(value ?? nowDate.getFullYear().toString()))

                                }}
                                onChange={(event, value) => {
                                    setShouldRefetch(prevState => !prevState)
                                    setYear(parseInt(value ?? nowDate.getFullYear().toString()))

                                }}
                                classes={{
                                    input: 'text-dark'
                                }}
                                defaultValue={nowDate.getFullYear().toString()}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Year"
                                        onChange={() => setShouldRefetch(prevState => !prevState)}
                                    />
                                }
                            />
                        </div>
                        <div className='col-md-3'>
                            <Autocomplete
                                id='name'
                                options={NameOptions}
                                onChange={(event, value) => {
                                    setShouldRefetch(prevState => !prevState);
                                    setName(value);
                                }}
                                defaultValue={NameOptions[0]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Name/ID"
                                        onChange={() => setShouldRefetch(prevState => !prevState)}
                                    />
                                )}
                            />

                        </div>
                    </div>


                    <MaterialReactTable
                        columns={columns}
                        data={tableData}
                        enableDensityToggle={false}
                        enableColumnFilters
                        enableColumnOrdering
                        enablePinning
                        enableRowActions
                        // enableColumnResizing
                        // columnResizeMode= 'onChange'
                        initialState={{
                            showColumnFilters: false,
                            // grouping: ["approval"], 
                            sorting: [
                                { id: "name", desc: false },
                            ],
                            expanded: true,
                            columnPinning: { left: ["name"] }
                        }}
                        positionToolbarAlertBanner="bottom"
                        positionActionsColumn='last'
                        renderRowActions={({ row, table }) => [
                            <Box sx={{ justifyContent: 'center' }}>
                                <IconButton
                                    onClick={() => table.setEditingRow(row)}
                                >
                                    <Edit className='text-success' />
                                </IconButton>
                                <IconButton
                                    onClick={() => {

                                        console.log("fetchedData[row.index]?.employeeId", fetchedData[row.index]?.employeeId);

                                        // axios.post(`${BASE_URL_TWO}`, {
                                        //     "sp": "delEmployeeProfile",
                                        //     "userId": id,
                                        //     "employeeId": fetchedData[row.index]?.employeeId
                                        // }, { headers }).then(() => { setShouldRefetch(prevState => !prevState) })
                                    }}
                                >
                                    <Delete className='text-danger' />
                                </IconButton>
                            </Box>
                        ]}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: 'Action',
                                size: 80,
                                muiTableHeadCellProps: {
                                    className: 'px-7 py-4 text-black',
                                    sx: {
                                        alignItems: 'center',
                                        verticalAlign: 'middle',
                                        backgroundColor: '#EBEBD3 !important',
                                        outline: '6px',
                                        outlineStyle: 'solid',
                                        outlineColor: 'var(--bs-light)',
                                        outlineOffset: '-5px',
                                        borderRadius: '20px',
                                    }
                                }
                            },
                        }}
                        sortingFns={{
                            status: (rowA, rowB, columnID) => {
                                const valA = rowA.getValue < String > (columnID)
                                const valB = rowB.getValue < String > (columnID)
                                if (valA === 'Pending' && valB !== 'Pending') {
                                    return 1
                                }
                                else if (valB === 'Pending' && valA !== 'Pending') {
                                    return -1
                                }
                                else {
                                    return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1
                                }
                            }
                        }}
                        filterFns={{
                            dateFilter: (row, columnId, filterValue) => {
                                const filterArray = filterValue.replaceAll(',', ';').split(';')
                                const cellVal = row.getValue < Date > (columnId)
                                const monthName = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec']
                                for (let item of filterArray) {
                                    const itemArray = item.replaceAll(/\s+/g, '#').split('#')
                                    console.log(filterArray, item, itemArray)
                                    if (cellVal.getFullYear().toString() === itemArray[itemArray.length - 1])
                                        for (let i = 0; i < itemArray.length - 1; i++) {
                                            if (itemArray[i].includes(monthName[cellVal.getMonth()]))
                                                return true
                                        }
                                }
                                return false
                            },
                            multipleFilter: (row, columnId, filterValue) => {
                                const filterArray = filterValue.replaceAll(',', ';').split(';')
                                const cellVal = row.getValue < String > (columnId)
                                for (let item of filterArray) {
                                    if (cellVal.toLocaleLowerCase().includes(item.toLocaleLowerCase()))
                                        return true
                                }
                                return false
                            }
                        }}

                        // enableEditing
                        // editingMode='modal'
                        muiTablePaperProps={{
                            sx: {
                                backgroundColor: 'var(--bs-card-bg)',
                                border: 'none',
                                boxShadow: 'none'
                            }
                        }}
                        muiTableProps={{
                            className: 'table',
                            sx: {
                                borderCollapse: 'separate',
                                borderSpacing: '0px 4px',
                            }
                        }}
                        muiTableContainerProps={{
                            className: 'mb-2',
                            sx: {
                                backgroundColor: 'var(--bs-light)',
                                borderRadius: 'var(--bs-card-border-radius)',
                            }
                        }}
                        muiTableHeadRowProps={{
                            className: 'fw-bold bg-light',
                            sx: {
                                boxShadow: 'none'
                            }
                        }}
                        muiTableHeadCellProps={{
                            className: 'px-7 py-3 text-black',
                            sx: {
                                verticalAlign: 'middle',
                                backgroundColor: '#EBEBD3 !important',
                                outline: '10px',
                                outlineStyle: 'solid',
                                outlineColor: 'var(--bs-light)',
                                outlineOffset: '-5px',
                                borderRadius: '20px',
                            }
                        }}
                        muiTableBodyRowProps={{
                            hover: false,
                            sx: {
                                backgroundColor: 'transparent'
                            }
                        }}
                        muiTableBodyCellProps={{
                            className: 'bg-light bg-hover-secondary'
                        }}
                        muiTopToolbarProps={{
                            className: 'card text-light mb-2',
                            sx: {
                                boxShadow: 'none',
                                backgroundColor: '#143983'
                            },
                        }}
                        muiBottomToolbarProps={{
                            className: 'text-light mb-5 d-flex',
                            sx: {
                                boxShadow: 'none',
                                backgroundColor: '#143983',
                                borderRadius: 'var(--bs-card-border-radius)',
                                minHeight: '50px'
                            },
                        }}
                        muiSearchTextFieldProps={{
                            className: 'bg-light text-light text-muted'
                        }}
                        muiTableHeadCellFilterTextFieldProps={{
                            helperText: false,
                            focused: true,
                            inputProps: {
                                className: "text-black",
                            },
                            sx: {
                                color: 'black'
                            }
                        }}
                        muiTableHeadCellColumnActionsButtonProps={{
                            sx: {
                                color: 'black'
                            }
                        }}
                        muiTableHeadCellDragHandleProps={{
                            sx: {
                                color: 'black'
                            }
                        }}
                        icons={{
                            FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
                            FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
                            ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
                            FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                            FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                            SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
                            CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
                        }}
                        muiTablePaginationProps={{
                            className: 'text-white',

                        }}
                        enableGlobalFilter={false}
                        muiToolbarAlertBannerProps={{
                            className: 'text-white',
                            sx: {
                                backgroundColor: '#143983',
                            }
                        }}
                        muiToolbarAlertBannerChipProps={{
                            className: 'text-white'
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default EmployeeExpense;