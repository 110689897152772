
import React, { useState } from 'react';
import { message } from "antd";
import axios from 'axios';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import { KTSVG } from '../../../_metronic/helpers';
import { BASE_URL_TWO } from '../../../utils';
import moment from 'moment';



export default function EditEmployeeInfo({ selectedData }) {

    const [employeId, setEmployeName] = useState()

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'Updated department',
            duration: 1
        });
    };

    const Dob = moment(selectedData?.dateOfBirth, "M/D/YYYY").format("YYYY-MM-DD");
    const passportExp = moment(selectedData?.passportExpDate, "M/D/YYYY").format("YYYY-MM-DD");
    const visa = moment(selectedData?.visaExpDate, "M/D/YYYY").format("YYYY-MM-DD");
    const contractExp = moment(selectedData?.contractExpDate, "M/D/YYYY").format("YYYY-MM-DD");
    const tamimah = moment(selectedData?.tamimahJoiningDate, "M/D/YYYY").format("YYYY-MM-DD");
    const mp = moment(selectedData?.manPowerJoiningDate, "M/D/YYYY").format("YYYY-MM-DD");
    const rc = moment(selectedData?.rcExpDate, "M/D/YYYY").format("YYYY-MM-DD");

    const initialValues = {
        userId: id,
        employeeId: "",
        employeeName: selectedData ? selectedData.employeeName : '',
        email: selectedData ? selectedData.email : '',
        gender: selectedData ? selectedData.gender : '',
        address: selectedData ? selectedData.address : '',
        nationality: selectedData ? selectedData.nationality : '',
        maritalStatus: selectedData ? selectedData.maritalStatus : '',
        idCardNo: selectedData ? selectedData.IdCardNo : '',
        accessDoorNo: selectedData ? selectedData.accessDoorNumber : '',
        phoneNo: selectedData ? selectedData.phoneNumber : '',
        emergencyNo: selectedData ? selectedData.emergencyNumber : '',
        DOB: Dob,
        passportNo: selectedData ? selectedData.passportNumber : '',
        passportExpDate: passportExp,
        visaType: selectedData ? selectedData.visaType : '',
        visaExpDate: visa,
        workPlace: selectedData ? selectedData.currentExp : '',
        dept: selectedData ? selectedData.deptName : '',
        internalJobTitle: selectedData ? selectedData.internalJobTitle : '',
        jobMpNo: selectedData ? selectedData.jobManPowerNumber : '',
        jobMpTitle: selectedData ? selectedData.jobManPowerTitle : '',
        contractExpDate: contractExp,
        tamimahJoiningDate: tamimah,
        mpJoiningDate: mp,
        rcExpDate: rc,
        totalSalary: selectedData ? selectedData.totalSalary : '',
        basicSalary: selectedData ? selectedData.basicSalary : '',
        workExp: selectedData ? selectedData.currentExp : '',
        status: selectedData ? selectedData.employeeWorkStatus : '',
    }

    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            console.log("values", values);

            axios.post(`${BASE_URL_TWO}`, {
                sp: "updEmployeeProfile",
                userId: id,
                employeeId: selectedData?.employeeId,
                employeeName: values ? values.employeeName : '',
                email: values ? values.email : '',
                gender: values ? values.gender : '',
                address: values ? values.address : '',
                nationality: values ? values.address : '',
                maritalStatus: values ? values.idCardNo : '',
                idCardNo: values ? values.idCardNo : '',
                accessDoorNo: values ? values.accessDoorNo : '',
                phoneNo: values ? values.phoneNo : '',
                emergencyNo: values ? values.emergencyNo : '',
                DOB: values ? values.DOB : '',
                passportNo: values ? values.passportNo : '',
                passportExpDate: values ? values.passportExpDate : '',
                visaType: values ? values.visaType : '',
                visaExpDate: values ? values.visaExpDate : '',
                workPlace: values ? values.workPlace : '',
                dept: values ? values.dept : '',
                internalJobTitle: values ? values.internalJobTitle : '',
                jobMpNo: values ? values.jobMpNo : '',
                jobMpTitle: values ? values.jobMpTitle : '',
                contractExpDate: values ? values.contractExpDate : '',
                tamimahJoiningDate: values ? values.tamimahJoiningDate : '',
                mpJoiningDate: values ? values.mpJoiningDate : '',
                rcExpDate: values ? values.rcExpDate : '',
                totalSalary: values ? values.totalSalary : '',
                basicSalary: values ? values.basicSalary : '',
                workExp: values ? values.workExp : '',
                status: values ? values.status : '',
            }, { headers }).then(() => {
                showMessage()
                setShouldRefetch(prevState => !prevState)
                setTimeout(function () {
                    window.location.reload()
                }, 2000);
            })
        },
        enableReinitialize: true
    });

    return (
        <div className="modal fade" id="kt_modal_3" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">

            <form action="" onSubmit={formik.handleSubmit}>

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Employee Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='employeeName'
                                        autoComplete='off'
                                        value={formik.values.employeeName}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.employeeName}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Email id</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='email'
                                        autoComplete='off'
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.email}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Gender</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='gender'
                                        autoComplete='off'
                                        value={formik.values.gender}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.gender}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Address</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='address'
                                        autoComplete='off'
                                        value={formik.values.address}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.address}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Nationality</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='nationality'
                                        autoComplete='off'
                                        value={formik.values.nationality}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.nationality}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">MaritalStatus</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='maritalStatus'
                                        autoComplete='off'
                                        value={formik.values.maritalStatus}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.maritalStatus}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">IdRc Number</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='idCardNo'
                                        autoComplete='off'
                                        value={formik.values.idCardNo}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.idCardNo}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Access Door No</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='accessDoorNo'
                                        autoComplete='off'
                                        value={formik.values.accessDoorNo}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.accessDoorNo}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Phone No</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='phoneNo'
                                        autoComplete='off'
                                        value={formik.values.phoneNo}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.phoneNo}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Emergeny No</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='emergencyNo'
                                        autoComplete='off'
                                        value={formik.values.emergencyNo}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.emergencyNo}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">DOB</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='DOB'
                                        autoComplete='off'
                                        value={formik.values.DOB}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.DOB}</span>
                                </div>


                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Passport No</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='passportNo'
                                        autoComplete='off'
                                        value={formik.values.passportNo}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.passportNo}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Passport ExpDate</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='passportExpDate'
                                        autoComplete='off'
                                        value={formik.values.passportExpDate}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.passportExpDate}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Visa Type</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='visaType'
                                        autoComplete='off'
                                        value={formik.values.visaType}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.visaType}</span>
                                </div>


                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Visa Exp Date</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='visaExpDate'
                                        autoComplete='off'
                                        value={formik.values.visaExpDate}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.visaExpDate}</span>
                                </div>


                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Work Place</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='workPlace'
                                        autoComplete='off'
                                        value={formik.values.workPlace}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.workPlace}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Dept</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='dept'
                                        autoComplete='off'
                                        value={formik.values.dept}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.workPlace}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">internal Job Title</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='internalJobTitle'
                                        autoComplete='off'
                                        value={formik.values.internalJobTitle}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.internalJobTitle}</span>
                                </div>


                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Job Mp No</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='jobMpNo'
                                        autoComplete='off'
                                        value={formik.values.jobMpNo}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.jobMpNo}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Job Mp Title</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='jobMpTitle'
                                        autoComplete='off'
                                        value={formik.values.jobMpTitle}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.jobMpTitle}</span>
                                </div>


                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Contract Exp Date</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='contractExpDate'
                                        autoComplete='off'
                                        value={formik.values.contractExpDate}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.contractExpDate}</span>
                                </div>


                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Tamimah Joining Date</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='tamimahJoiningDate'
                                        autoComplete='off'
                                        value={formik.values.tamimahJoiningDate}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.tamimahJoiningDate}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Mp Joining Date</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='mpJoiningDate'
                                        autoComplete='off'
                                        value={formik.values.mpJoiningDate}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.mpJoiningDate}</span>
                                </div>


                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Rc Exp Date</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='rcExpDate'
                                        autoComplete='off'
                                        value={formik.values.rcExpDate}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.rcExpDate}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Total Salary</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='totalSalary'
                                        autoComplete='off'
                                        value={formik.values.totalSalary}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.totalSalary}</span>
                                </div>


                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Basic Salary</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='basicSalary'
                                        autoComplete='off'
                                        value={formik.values.basicSalary}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.basicSalary}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Work Exp</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='workExp'
                                        autoComplete='off'
                                        value={formik.values.workExp}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.workExp}</span>
                                </div>

                                <div className='col-md-6 fv-row'>
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Status</label>
                                    <select
                                        name="status"
                                        value={formik.values.status}
                                        onChange={(e) => {
                                            // setStatus(e.target.value)
                                            formik.handleChange(e);
                                            formik.setFieldValue('status', e.target.value)

                                        }}
                                        className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'>
                                        <option>Select Status</option>
                                        <option value='under-Process'>Under Process</option>
                                        <option value='Active'>Active</option>
                                        <option value='Expired'>Expired</option>
                                        <option value='Completed'>Completed</option>
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.status}</span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Add
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )
}
