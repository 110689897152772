import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import PayrollTable from '../../../components/PayrollTable/PayrollTable'
import EmployeeExpense from '../../../components/EmployeeExpenseTable/EmployeeExpense'


const RenewalStatusPage: FC = () => (
    <>
        <div className='row g-5 g-xl-10'>
            <PayrollTable/>
        </div>
        <div className='row g-5 g-xl-10 mt-20'>
            <EmployeeExpense/>
        </div>
    </>
)


const Payroll: FC = () => {
    return (
      <>
        <RenewalStatusPage/>
      </>
    )
  }
                    
export {Payroll}