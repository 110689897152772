import React, { useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import useFetchData from '../../hooks/getData';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import { message } from "antd";

interface VehiclesFormProps {
    onAdd: () => void;
}

const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required('Name is  required'),
    plateNumber: Yup.string().required('Plate number is required'),
    carModel: Yup.string().required('Model is required'),
    carYear: Yup.string().required('Manufactured year is required'),
    fromDate: Yup.string().required('Insurance start year is required'),
    toDate:Yup.string()
    .required('End date is required')
    .test('endDate', 'End date must be after the start date', function (value) {
        const { fromDate } = this.parent;
        return !fromDate || !value || new Date(value) > new Date(fromDate);
    }),
    insuranceAmunt: Yup.string(),
    fee: Yup.string(),
    mulkiyaExpDate:Yup.string().required('Mulkiya expiry date is required'),
    insuranceName:Yup.string()
})

const VehiclesForm: React.FC<VehiclesFormProps> = ({ onAdd }) => {

    const [employeId, setEmployeName] = useState()

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const employeList = useFetchData('getEmployeesList').fetchedData;

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'Added insurance staff',
            duration: 1
        });
    };

    const initialValues = {
        employeeName: '',
        plateNumber: '',
        carModel: '',
        carYear: '',
        fromDate: '',
        toDate: '',
        insuranceAmunt: '',
        fee: '',
        mulkiyaExpDate:"",
        insuranceName:""
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            axios.post(`${BASE_URL_TWO}`, {
                sp: 'insVehicleOwnerships',
                userId: id,
                employeeId: employeId,
                plateNo: values?.plateNumber,
                carModel: values?.carModel,
                modelYear: values?.carYear,
                insuranceAmount: values?.insuranceAmunt,
                carRenewalFee: values?.fee,
                mulkiyaExpDate:values?.mulkiyaExpDate,
                insuranceName:values?.insuranceName,
                startDate: values?.fromDate,
                endDate: values?.toDate
            }, { headers }).then(() => {
                showMessage()
                setShouldRefetch(prevState => !prevState)
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            })
        },
    });

    return (
        <div className="modal fade" id="kt_modal_2" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
            <form action="" onSubmit={formik.handleSubmit}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Car Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-3">
                                {/* Employee Name and Plate Number */}
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Employee Name <span className='text-danger'>*</span></label>
                                    <select
                                        onChange={(e) => {
                                            setEmployeName(e.target.value)
                                            formik.handleChange(e)
                                            setShouldRefetch(prevState => !prevState)
                                        }}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="employeeName"
                                        autoComplete='off'
                                        value={formik.values.employeeName}
                                    >
                                        <option value="">Select employee</option>
                                        {
                                            employeList?.map((data, index) =>
                                                <option value={data?.userId}>{data?.employeeName}</option>
                                            )
                                        }
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.employeeName}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Plate Number<span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='plateNumber'
                                        value={formik.values.plateNumber}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.plateNumber}</span>
                                </div>

                                {/* Car Model and Year */}
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Car Model<span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='carModel'
                                        value={formik.values.carModel}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.carModel}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Year<span className='text-danger'>*</span></label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='carYear'
                                        value={formik.values.carYear}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.carYear}</span>
                                </div>

                                {/* From and To Dates */}
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Insurance (From)<span className='text-danger'>*</span></label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='fromDate'
                                        value={formik.values.fromDate}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.fromDate}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Insurance (To)<span className='text-danger'>*</span></label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='toDate'
                                        value={formik.values.toDate}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.toDate}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Insurance Name</label>
                                    <input type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='insuranceName'
                                        value={formik.values.insuranceName}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.insuranceName}</span>
                                </div>

                                {/* Insurance Amount and Car Renewal Fee */}
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Insurance Amount</label>
                                    <input type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='insuranceAmunt'
                                        value={formik.values.insuranceAmunt}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.insuranceAmunt}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Car Renewal Fee</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='fee'
                                        value={formik.values.fee}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.fee}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Mulkiya Expiry Date <span className='text-danger'>*</span></label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='mulkiyaExpDate'
                                        value={formik.values.mulkiyaExpDate}
                                        onChange={formik.handleChange}
                                        min={new Date().toLocaleDateString('en-CA')}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.mulkiyaExpDate}</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default VehiclesForm;
