import { FC, useState, useEffect, useMemo, useRef } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import { TextField, Autocomplete, Grid, Rating } from '@mui/material'
import { addDays } from 'date-fns'
import { useReactToPrint } from 'react-to-print';
import {
    CardsWidget21,
} from '../../../../_metronic/partials/widgets'

import Calendar from '../../../components/Calendar/MonthView'

import Data from './data/data.json'
import useFetchData from '../../../hooks/getData'
import useFetchDataByYear from '../../../hooks/getDataByYear'
import useFetchEmployerData from '../../../hooks/useFetchEmployerData'
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl'
import { BASE_URL, BASE_URL_TWO } from '../../../../utils'
import Cookies from 'js-cookie'
import axios from 'axios'
import RichTextEditor from '../../../../_metronic/helpers/components/RichTextEditor'
import { message } from 'antd'

const data = Data

const SummaryKPIPage = () => {
    const presentYear = new Date().getFullYear()
    const [employeeName, setEmployeeName] = useState(null);
    const [year, setYear] = useState(presentYear);
    const [employeeLeaveDetails, setEmployeeLeaveDetails] = useState([])
    const [type, setType] = useState("All")
    const col1 = useRef(null)
    const col2 = useRef(null)
    const col3 = useRef(null)
    const col4 = useRef(null)


    const printRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });
    // const [noTickets, setNoTIckets] = useState(0)
    // const [ticketAmt, setAmt] = useState(0)

    const [days, setdays] = useState({
        Sick: 0,
        Marriage: 0,
        Maternity: 0,
        Annual: 0,
        Compassionate: 0,
        Unpaid: 0,
        Hajj: 0,
        Paternity: 0,
        Flex:0
    })


    const currentYear = new Date().getFullYear();
    const last5Years = Array.from({ length: 7 }, (_, i) => currentYear - i);

    const timestamp = Date.now();
    const today = new Date(timestamp);
    const newyear = today.getFullYear();
    let month = today.getMonth() + 1; // getMonth() returns month from 0 to 11
    month = month < 10 ? `0${month}` : month; // ensure two-digit month
    let day = today.getDate();
    day = day < 10 ? `0${day}` : day; // ensure two-digit day

    const formattedDate = `${newyear}-${month}-${day}`;

    const yearOptions = useMemo(() => {
        return last5Years
    }, []);

    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    useEffect(() => {
        const listofLeaveDetails = [];
        let noOfDays = {
            Sick: 0,
            Marriage: 0,
            Maternity: 0,
            Annual: 0,
            Compassionate: 0,
            Unpaid: 0,
            Hajj: 0,
            Paternity: 0
        }
        for (let item of data) {
            if (item.name === employeeName) {
                const startDate = new Date(item.startDate)
                const endDate = new Date(item.endDate)
                let date = startDate
                if (startDate.getFullYear() === year || endDate.getFullYear() === year) {
                    while (date < endDate) {
                        if (date.getFullYear() === year) {
                            const ld = { date: date, type: item.type }
                            listofLeaveDetails.push(ld)
                            noOfDays[item.type] = noOfDays[item.type] + 1
                        }
                        date = addDays(date, 1)
                    }
                }
            }
        }
        setdays(noOfDays)
        setEmployeeLeaveDetails(listofLeaveDetails)
    }, [employeeName, year])

    useEffect(() => {
        const handleClickOutside = (event) => {
            console.log("click recorded")
            console.log(col1.current)
            console.log(col2.current)
            console.log(col3.current)
            console.log(col4.current)
            if (col1.current && !col1.current.contains(event.target) &&
                col2.current && !col2.current.contains(event.target) &&
                col3.current && !col3.current.contains(event.target) &&
                col4.current && !col4.current.contains(event.target)
            ) {
                setType("All")
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        }
    }, [])

    const [shouldRefetch, setShouldRefetch] = useState(false);


    const employeList = useFetchData('getEmployeesList').fetchedData;

    const employeNames = []
    employeList?.map((data, index) => employeNames.push({
        label: data?.employeeName,
        id: data?.userId
    }))
    const [userId, setUserId] = useState(employeNames[0]?.userId);


    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };
    const rolesAndSuggestion = useGetDataWithFullUrl(`${BASE_URL}getRolesAndSuggestions&userId=${id}&employeeId=${userId ? userId : id}&year=${year}`, [shouldRefetch]).fetchedData;
    const leaveTrackingDetails = useGetDataWithFullUrl(`${BASE_URL}getEmployeeLeaveTrackerDetails&userId=${id}&employeeId=${userId ? userId : employeList?.[0]?.userId}&year=${year}`, [shouldRefetch]).fetchedData;
    const kpiSummeryData = useGetDataWithFullUrl(`${BASE_URL}getKpiSummaryForAdmin&userId=${id}&employeeId=${userId ? userId : employeList?.[0]?.userId}&year=${year}`, [shouldRefetch]).fetchedData;
    const [suggession, setSuggession] = useState();
    const [newResponsibility, setNewResponsibility] = useState()

    useEffect(()=>{
        setNewResponsibility(null)
        setSuggession(null)
    },[userId,year])

    const handleRolesSuggession = () => {
       if(suggession.replace(/<[^>]*>?/gm, '').trim() !== '' && newResponsibility.replace(/<[^>]*>?/gm, '').trim() !== ''){
        axios.post(`${BASE_URL}`, {
            "sp": "updRolesAndSuggestions",
            "userId": id,
            "employeeId": userId ? userId : id,
            "year": year,
            "suggestions": suggession || rolesAndSuggestion?.[0]?.suggestions,
            "comments": newResponsibility || rolesAndSuggestion?.[0]?.comments
        }, { headers }).then((response) => {
            showMessage("Successfully saved")
            setTimeout(()=>{
                window.location.reload()
            },1000)
        })
       }else{
        if(suggession.replace(/<[^>]*>?/gm, '').trim() === '' && newResponsibility.replace(/<[^>]*>?/gm, '').trim() === ''){
            alert("Please enter the data")
        }else if(suggession.replace(/<[^>]*>?/gm, '').trim() === ''){
            alert("Please enter the suggession")
        }else{
            alert("please enter the responsibilitiy")
        }
       }
    }

    useEffect(() => {
        if (rolesAndSuggestion?.[0]?.comments) {
            setNewResponsibility(rolesAndSuggestion?.[0]?.comments);
            setSuggession(rolesAndSuggestion?.[0]?.suggestions)
        }
    }, [rolesAndSuggestion]);


    const showMessage = (msg) => {
        message.success({
            content: msg,
            duration: 4 // Duration in seconds
        });
    };

    

    return (

        <>
            <h3 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-1 me-2 svg-icon-muted text-black-50"></i>  {/* icon  */} Employee Summary KPI Tracker</h3>

            {employeNames[0] && <div className='row g-5 g-xl-8 mb-md-5 mb-xl-5 d-flex justify-content-evenly'>
                <div className='col-md-5 col-xl-5 me-13'>
                    <div className='card h-md-auto mb-8'>
                        <div className='card-body'>
                            <h5 className='text-primary'><i className="bi bi-person-fill fs-2 me-3 svg-icon-muted text-primary"></i>Select Employee Name </h5>
                            <Autocomplete
                                fullWidth
                                color="primary"
                                id='employeeNameField'
                                options={employeNames}
                                onInputChange={(event, value) => {
                                    setEmployeeName(value);
                                    setShouldRefetch(prevState => !prevState)
                                }}
                                onChange={(event, value) => {
                                    setEmployeeName(value);
                                    setUserId(value?.id)
                                    setShouldRefetch(prevState => !prevState)
                                }}
                                defaultValue={employeNames?.[0]}
                                classes={{
                                    input: 'text-primary'
                                }}
                                className='mt-md-5 mt-xl-3 mb-3'
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Employee Name"
                                    />
                                }
                            />
                            <h5 className='text-primary mb-3 mt-2'><i className="bi bi-calendar-check fs-2 me-3 svg-icon-muted text-primary"></i>Select Year </h5>
                            <Autocomplete
                                disablePortal
                                fullWidth
                                id='yearField'
                                color="primary"
                                options={yearOptions}
                                onInputChange={(event, value) => {
                                    setYear(parseInt(value ?? presentYear.toString()));
                                    setShouldRefetch(prevState => !prevState)
                                }}
                                onChange={(event, value) => {
                                    setYear(parseInt(value ?? presentYear.toString()));
                                    setShouldRefetch(prevState => !prevState)
                                }}
                                classes={{
                                    input: 'text-primary'
                                }}
                                defaultValue={presentYear.toString()}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Year"
                                    />
                                }
                            />

                            {/* employee information */}

                            {
                                leaveTrackingDetails?.slice(0, 1)?.map((data, index) =>
                                    <div className="mt-2">

                                        {/* Employee Total Salary */}
                                        <li className="d-flex align-items-center me-3">
                                            <span className="bullet bg-primary me-5"></span><h6 className='mt-2 text-primary fs-7 me-5'>Total Salary</h6> <span className="badge badge-primary">{data?.totalSalary} OMR</span>
                                        </li>

                                        {/* Employee Internal Job Title */}
                                        <li className="d-flex align-items-center me-3">
                                            <span className="bullet bg-primary me-5"></span><h6 className='mt-2 text-primary fs-7 me-5'>Internal Job Title</h6><span className="badge badge-primary">{data?.jobtitle}</span>
                                        </li>

                                        {/* Employee Responsibilities */}
                                        <li className="d-flex align-items-center me-3">
                                            <span className="bullet bg-primary me-5"></span><h6 className='mt-2 text-primary fs-7 me-5'>Responsibilities</h6>

                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <a className="btn btn-icon btn-sm btn-light-primary ms-2 bg-white" onClick={()=> {
                                                    if(!data?.responsibilities){
                                                        alert('No file found')
                                                        return;
                                                    }
                                                }} href={data?.responsibilities?`${BASE_URL_TWO}${data?.responsibilities}`:'#'} target={`${data?.responsibilities?'_blank':'_self'}`}>
                                                    <KTSVG
                                                        path="/media/icons/duotune/communication/com005.svg"
                                                        className="svg-icon svg-icon-2x"
                                                    />
                                                </a>
                                            </div>
                                        </li>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>

                <Grid container sm={11} xs={11} md={6} lg={6} sx={{ bgcolor: '' }}>
                    {
                        leaveTrackingDetails?.map((data, index) => {

                            return (
                                <Grid container sx={{ bgcolor: '', height: 'fit-content' }} xs={6} sm={6} md={4} lg={4}>
                                    <CardsWidget21
                                        className='h-md-20 mb-5'
                                        color='#143983'
                                        img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                                        title={data?.policytitle}
                                        description={days.Sick.toString()}
                                        onClick={() => setType(data?.policytitle)}
                                        availablecount={data?.availablecount}
                                        totalcount={data?.totalcount ? data?.totalcount : 0}
                                    />
                                </Grid>
                            )
                        }
                        )
                    }
                </Grid>

            </div>}

            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button className="btn btn-sm" type="button" style={{ background: "#6255a3", color: "#fff" }} onClick={handlePrint} ><i className="bi bi-file-pdf-fill fs-2x svg-icon-muted text-white"></i>Print</button>
            </div>

            <div className="p-5" style={{ overflowX: 'auto' }}>

                <table className="table custom-table table-rounded border-3 border-gray-100 table-bordered gy-5 gs-7 shadow" style={{ background: "#e8e6e6", width: "2400px" }}>
                    <thead>
                        <tr className="fw-bold fs-6 border-bottom border-gray-200 text-white bg-primary">
                            <th className="KPIyear" colSpan={3} style={{ background: "#6255a3" }}><span className='badge badge-light-info fs-6 fw-bold'> {year} </span></th>

                            <th colSpan={2} style={{ background: "#143983", color: "#fff" }}>Line Manager <i className="bi bi-1-square fs-2x px-2 svg-icon-muted" style={{ color: "#fff" }}></i></th>
                            <th colSpan={2} style={{ background: "#143983", color: "#fff" }}>Line Manager <i className="bi bi-2-square fs-2x px-2 svg-icon-muted" style={{ color: "#fff" }}></i> </th>

                        </tr>
                        <tr className="fw-bold fs-6 border-bottom border-gray-200 text-white" style={{ background: "#6255a3" }}>

                            <th><i className="bi bi-question-square-fill fs-2x px-5 svg-icon-muted text-white"></i>Question</th>
                            <th>My Rating</th>
                            <th> Comments / Suggestions</th>
                            <th> Rating </th>
                            <th > Comments / Suggestions </th>
                            <th> Rating </th>
                            <th >Comments / Suggestions </th>

                        </tr>

                    </thead>
                    {kpiSummeryData?.length <= 0 && <tbody><tr><td colSpan={7} className='text-center fw-bold fs-4'>No Data Found</td></tr></tbody>}
                    {kpiSummeryData?.length > 0 && <tbody>

                        {
                            kpiSummeryData?.map((data, index) => {

                                return (
                                    <>
                                        <tr>
                                            <td className=" fw-bold" style={{ width: "500px", color: "#6255a3" }} >{data?.questionText}</td>

                                            <td className="fw-bold">
                                                <div className="rating">
                                                    <div>
                                                        <Rating name="full-rating-read" value={Math.round(data?.rating)}  readOnly sx={{ fontSize: "2rem" }} />
                                                    </div>
                                                </div>

                                            </td>

                                            <td className="fw-bold" style={{ width: "500px", color: "#6255a3" }}>{data ? data?.comments : 'No Comment'}</td>

                                            <td className="fw-bold">
                                                <div className="rating">
                                                    <div>
                                                        <Rating name="full-rating-read" value={Math.round(JSON?.parse(data?.LineManager1)?.managerrating)}  readOnly sx={{ fontSize: "2rem" }} />
                                                    </div>
                                                </div>

                                            </td>

                                            <td className="fw-bold" style={{ width: "500px", color: "#6255a3" }}>{data ? JSON.parse(data?.LineManager1)?.managercomments : 'No Comment'}</td>
                                            <td className="fw-bold">
                                                <div className="rating">
                                                    <div>
                                                        <Rating name="full-rating-read" value={Math.round(JSON.parse(data?.LineManager2)?.managerrating)}  readOnly sx={{ fontSize: "2rem" }} />
                                                    </div>
                                                </div>

                                            </td>

                                            <td className="fw-bold" style={{ width: "500px", color: "#6255a3" }}>{data ? JSON.parse(data?.LineManager2)?.managercomments : 'No Comment'}</td>

                                        </tr>

                                    </>
                                )
                            })
                        }
                    </tbody>}
                </table>
            </div>

            <br></br><br></br><br></br>

            {kpiSummeryData?.length > 0 && <div>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button className="btn btn-sm" type="button" style={{ background: "#1e6378", color: "#fff" }} onClick={handleRolesSuggession}><i className="bi bi-save fs-1hx px-3 svg-icon-muted text-white" ></i>Save</button>
                </div>

                {/* <h1 className="pb-10 mt-10" style={{color: "#2f574b"}}><i className="bi bi-file-earmark-bar-graph-fill fs-2hx px-1 svg-icon-muted" style={{color: "#2f574b"}}></i>  General Question</h1> */}


                <table className="table table-rounded border border-gray-100 table-row-bordered table-row-gray-100  gy-5 gs-7 shadow" style={{ background: "#e8e6e6" }}>

                    <>
                        <thead>
                            <tr className="fw-bold fs-5 border-bottom border-gray-200 text-white" style={{ background: "#1e6378" }}>
                                <th><i className="bi bi-card-text fs-2x px-5 svg-icon-muted text-white"></i>Suggestions for ( Appraisal | Bonus | Incentive ):</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="mb-3 w-100">
                                        <RichTextEditor
                                            name="editSuggession"
                                            className="datafield p-5 w-100 d-flex flex-column gap-3"
                                            onChange={(value) => {
                                                setSuggession(value);
                                            }}
                                            suppressContentEditableWarning={true}
                                            value={suggession }
                                        />
                                    </div>

                                </td>
                            </tr>

                        </tbody>
                        <thead>
                            <tr className="fw-bold fs-5 border-bottom border-gray-200 text-white" style={{ background: "#1e6378" }}>
                                <th><i className="bi bi-card-text fs-2x px-5 svg-icon-muted text-white"></i>New Job Responsibilities:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="mb-3 w-100">
                                        <RichTextEditor
                                            name="editResponsibility"
                                            className="datafield p-5 w-100 d-flex flex-column gap-3"
                                            onChange={(value) => {
                                                setNewResponsibility(value);
                                            }}
                                            suppressContentEditableWarning={true}
                                            value={newResponsibility}
                                        />
                                    </div>

                                </td>
                            </tr>

                        </tbody>
                    </>
                </table>
            </div>}
            <div ref={printRef} className='centered-div'>
                <div class="dashboard-cust">
                    {leaveTrackingDetails?.map((data, index) => (<div class="leave-block"><h6>{`${data?.totalcount ? data.totalcount : '0'} / ${data?.availablecount}`}</h6><h5>{data?.policytitle}</h5></div>))}
                </div>

                <div class="table-container-cust">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="3"> {year} </th>
                                <th colspan="2">Line Manager 1</th>
                                <th colspan="2">Line Manager 2</th>
                            </tr>
                            <tr>
                                <th>Question</th>
                                <th>My Rating</th>
                                <th>Comments</th>
                                <th>Rating</th>
                                <th>Comments</th>
                                <th>Rating</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {kpiSummeryData?.map((data, index) => (
                                <tr>
                                    <td>{data?.questionText}</td>
                                    <td>{data?.rating}</td>
                                    <td>{data ? data?.comments : 'No Comment'}</td>
                                    <td>{JSON.parse(data?.LineManager1)?.managerrating}</td>
                                    <td>{data ? JSON.parse(data?.LineManager1)?.managercomments : 'No Comment'}</td>
                                    <td>{JSON.parse(data?.LineManager2)?.managerrating}</td>
                                    <td>{data ? JSON.parse(data?.LineManager2)?.managercomments : 'No Comment'}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

const SummaryKPI = () => {
    return (
        <>
            <SummaryKPIPage />
        </>
    )
}

export { SummaryKPI }