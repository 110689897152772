import { FC, useEffect, useState } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { Card4 } from '../../../../_metronic/partials/content/cards/Card4';
import PDFViewer from '../../../components/pdfViewer';
import useFetchData from '../../../hooks/getData';
import NoFiles from '../../../../_metronic/helpers/components/NoFiles';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import { Link } from 'react-router-dom';

const LeaveForms = () => {
  const [year, setYear] = useState(null);
  const [yearWiseData, setYearWiseData] = useState([]);
  const [viewFolder, setViewFolder] = useState(0);
  const [getUserId, setUserId] = useState(null);
  const [navigationStack, setNavigationStack] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for the search query
  const [documents,setDocuments] = useState({})

  const { fetchedData } = useFetchData('getLeaveDocumentsYearWise');

  useEffect(() => {
    const savedStack = localStorage.getItem('navigationStack');
    const savedState = localStorage.getItem('currentState');
    const savedYearWiseData = localStorage.getItem('yearWiseData');

    if (savedStack) {
      const parsedStack = JSON.parse(savedStack);
      setNavigationStack(parsedStack);
    }
    if (savedState) {
      const { year, viewFolder, userId } = JSON.parse(savedState);
      setYear(year);
      setViewFolder(viewFolder);
      setUserId(userId);
    }
    if (savedYearWiseData) {
      const parsedYearWiseData = JSON.parse(savedYearWiseData);
      setYearWiseData(parsedYearWiseData);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('navigationStack', JSON.stringify(navigationStack));
    localStorage.setItem('currentState', JSON.stringify({ year, viewFolder, userId: getUserId }));
    localStorage.setItem('yearWiseData', JSON.stringify(yearWiseData));
  }, [navigationStack, year, viewFolder, getUserId, yearWiseData]);

  const yearWiseDataFilter = fetchedData?.filter((data) => data?.year == year);
  const filteredYearData = yearWiseDataFilter ? yearWiseDataFilter[0]?.leaveDocuments : '';

  // const handleCardClick = () => {
  //   // Open the PDF in a new tab
  //   window.open(pdfUrl, '_blank');
  // };

  useEffect(() => {
    if (filteredYearData) {
      try {
        const parsedData = JSON.parse(filteredYearData);
        setYearWiseData(parsedData);
      } catch (error) {
        console.error("Error parsing JSON: ", error);
      }
    } else {
      console.warn("No data available to parse.");
    }
  }, [filteredYearData]);

  const filteredYearWiseData = yearWiseData?.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.userId === value.userId
    ))
  );

  const employees = yearWiseData?.filter((data) => data?.userId == getUserId);

  const handleBackButtonClick = () => {
    if (navigationStack.length > 0) {
      const previousState = navigationStack.pop();
      setDocuments(fetchedData?.filter(item=>item.year === previousState.year)[0])
      setYear(previousState.year);
      setViewFolder(previousState.viewFolder);
      setUserId(previousState.userId);
      setNavigationStack([...navigationStack]);
      setSearchQuery('')
    }
  };

  const handleFolderClick = (data, view) => {
    setNavigationStack([...navigationStack, { year, viewFolder, userId: getUserId }]);
    setDocuments(data)
    setYear(data?.year);
    setViewFolder(view);
    setUserId(data?.userId);
    setSearchQuery('')
  };

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Filter function to search files and folders based on the search query
  const searchFilter = (data) => {
    if (searchQuery === '') {
      return true; // If search query is empty, return all data
    }
    if (viewFolder === 0 && data?.year) {
      return data.year.toString().toLowerCase().includes(searchQuery);
    } else if (viewFolder === 1 && data?.firstName) {
      return `${data.firstName.toLowerCase()} ${data.lastName.toLowerCase()}`.includes(searchQuery);
    } else if (viewFolder === 2 && data?.supportingDocumentPath.split('/')[data?.supportingDocumentPath.split('/').length-1].split('-')[0]) {
      return data?.supportingDocumentPath.split('/')[data?.supportingDocumentPath.split('/').length-1].split('-')[0].toLowerCase().includes(searchQuery);
    }
    return false;
  };

  return (
    <>
      <h5 className="text-black-50 mb-10">
        <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> Leave Forms Submitted
      </h5>

      <div className='mb-6'>
        <div className='d-flex justify-content-between '>
          <div className='d-flex align-items-center position-relative me-4'>
            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-150px ps-9'
              placeholder='Search'
              value={searchQuery}
              onChange={handleSearchInputChange} // Handle search input change
            />
          </div>
          {navigationStack.length > 0 && <button className='btn btn-primary btn-sm' onClick={handleBackButtonClick}>Back</button>}
        </div>
      </div>

      {viewFolder === 0 && (fetchedData?.filter(searchFilter)?.length <= 0) && <NoFiles />}
      {viewFolder === 1 && (filteredYearWiseData?.filter(searchFilter)?.length <= 0) && <NoFiles />}
      {viewFolder === 2 && (employees?.filter(searchFilter)?.length <= 0) && <NoFiles />}

      {viewFolder === 0 && fetchedData?.length > 0 && <div className='row g-6 g-xl-9 mb-6 mb-xl-9 '>
        {
          fetchedData?.filter(searchFilter).map((data, index) => // Apply search filter
            <div
              key={index}
              onClick={() => handleFolderClick(data, 1)}
              className='col-12 col-sm-12 col-xl-3'>
              <Card4
                icon='/media/svg/files/download.svg'
                title={data?.year}
                description={`${ new Set(JSON.parse(data?.leaveDocuments)?.map(item=> item?.firstName+item?.lastName)).size || 0} Files`}
              />
            </div>
          )
        }
      </div>}

      {viewFolder === 1 && filteredYearWiseData?.length > 0 && <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
        {
          filteredYearWiseData?.filter(searchFilter).map((data, index) => // Apply search filter
            <div
              key={index}
              onClick={() => handleFolderClick(data, 2)}
              className='col-12 col-sm-12 col-xl-3'>
              <Card4
                icon='/media/svg/files/download.svg'
                title={`${data?.firstName} ${data?.lastName}`}
                description={JSON.parse(documents?.leaveDocuments)?.filter(item=>item?.firstName+item?.lastName ===data?.firstName+data?.lastName)?.length}
              />
            </div>
          )
        }
      </div>}
      
      {viewFolder === 2 && employees?.length > 0 && <div className='row g-6 g-xl-9 mb-6 mb-xl-3'>
        {
          
          employees?.filter(searchFilter).map((data, index) => // Apply search filter
              <div key={index} className='col-12 col-sm-12 col-xl col-xl-3'>
                  <Card4 icon='/media/svg/files/doc.svg' title={data?.supportingDocumentPath.split('/')[data?.supportingDocumentPath.split('/').length-1].split('-')[0]} link={data.supportingDocumentPath?`${BASE_URL_TWO}${data.supportingDocumentPath}`:''}  description={Math.floor((new Date()- new Date(data?.CreatedAt)) / (1000 * 60 * 60 * 24))+" days ago"} />
              </div>
           
          )
        }
      </div>}
    </>
  );
};

export { LeaveForms };
