/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react'
import { useIntl, IntlProvider } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { display } from '@mui/system'
import { number } from 'yup'
// import {IntlProvider} from "react-intl";

const SidebarMenuMain = () => {

  const intl = useIntl()

  const permission = Cookies.get('pemissions');
  let permissionArray = [];

  try {
    if (!permission) {
      throw new Error("Permission data is undefined");
    }
    permissionArray = JSON.parse(permission);
   
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  let path = useLocation();
  const test = path?.pathname.split('/')[path?.pathname.split('/').length-1]
  if(!isNaN(test)){
    path = path?.pathname.split('/').slice(0,-1).join('/')
  }else{
    path = path?.pathname
  }
  const navigate = useNavigate();

  useEffect(() => {

    if (!permissionArray.some(obj => obj.pageUrl === path)) {
      navigate('/error/login');
    }
  }, [path.pathname, permissionArray])

  // console.log("permissionArray.................", permissionArray);

  return (
    <>

      <div style={{ display: permissionArray[0]?.permissionGroup == "Employer" ? 'flex' : 'none' }}>
      </div>

      <>
        <div style={{ display: permissionArray[0]?.permissionGroup == "Employee" ? 'flex' : 'none' }} className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section  text-uppercase fs-8 ls-1 text-white'>Employee Pages</span>
          </div>
        </div>

        <SidebarMenuItem
          permission={permissionArray.some(obj => obj.permissionName === 'dashboard')}
          to='/EmployeePages/Dashboard'
          iconpath='/media/icons/duotune/general/gen008.svg' title='Dashboard'
          fontIcon='bi-layers'
        />

        <div style={{
          display: permissionArray.some(obj => obj.permissionName === "Request leave" || obj.permissionName === 'leave tracker' || obj.permissionName === 'Leave Policy') ? 'flex' : 'none',
          width: '100%'
        }}>
          <SidebarMenuItemWithSub
            to='/EmployeePages/myLeave/Dashboard'
            title='My Leave'
            iconpath='/media/icons/duotune/general/gen005.svg'
            fontIcon='bi-person'
          >
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Request leave')}
              to='/EmployeePages/myLeave/Requestleave' iconpath='' title='Request Leave' fontIcon='bi-layers' hasBullet={true} />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'leave tracker')}
              to='/EmployeePages/myLeave/leaveTracker' iconpath='' title='Leave Tracker' fontIcon='bi-layers' hasBullet={true} />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Leave Policy')}
              to='/EmployeePages/myLeave/leavePolicy' iconpath="" title='Leave Policy' fontIcon='bi-layers' hasBullet={true} />

          </SidebarMenuItemWithSub>
        </div>

        <SidebarMenuItem
          to='/EmployeePages/DocumentsTracker'
          permission={permissionArray.some(obj => obj.permissionName === 'Documnet Tracker')}
          iconpath='/media/icons/duotune/communication/com005.svg'
          title='Documents Tracker' fontIcon='bi-layers'
        />
        <SidebarMenuItem
          permission={permissionArray.some(obj => obj.permissionName === 'My details')}
          to='/EmployeePages/MyAccount' icon='user' title='My Details' fontIcon='bi-layers' />
        <SidebarMenuItem
          permission={permissionArray.some(obj => obj.permissionName === 'My Folder')}
          to='/EmployeePages/Folder' iconpath='/media/icons/duotune/files/fil012.svg' title='My Folder' fontIcon='bi-layers' />
        <SidebarMenuItem
          permission={permissionArray.some(obj => obj.permissionName === 'Compeny Documents')}
          to='/EmployeePages/OverallPolicies' iconpath='/media/icons/duotune/general/gen051.svg' title='Company Documents' fontIcon='bi-layers' />


        <div style={{
          display: permissionArray.some(obj => obj.permissionName === "Kpi Summery" || obj.permissionName === 'Kpi Quistions') ? 'flex' : 'none',
          width: '100%'
        }}>
          <SidebarMenuItemWithSub
            to=''
            title='My KPI'
            icon='ranking'
            fontIcon='bi-app-indicator'
          >
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Kpi Summery')}
              to='/EmployeePages/KPI/KPISummary' icon='ranking' title='KPI Summary' fontIcon='bi-layers' />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Kpi Quistions')}
              to='/EmployeePages/KPI/EmployeeKPIQuestion' icon='ranking' title='KPI Questions' fontIcon='bi-layers' />
          </SidebarMenuItemWithSub>
        </div>
      </>

      <>

        <div style={{ display: permissionArray[0]?.permissionGroup == "Employer" ? 'flex' : 'none' }} className='menu-item'>
          <div className='menu-content pt-8'>
            <span className='menu-section text-uppercase fs-8 ls-1 text-white'>Employer Pages</span>
          </div>
        </div>

        <SidebarMenuItem
          permission={permissionArray.some(obj => obj.permissionName === 'employer dashboard')}
          to='/employer/dashboard'
          icon='element-11'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />

        <div style={{
          display: permissionArray.some(obj => obj.permissionName === "Leave Requists" || obj.permissionName === 'Employe tracker' || obj.permissionName === 'Leave Policie') ? 'flex' : 'none',
          width: '100%'
        }}>

          <SidebarMenuItemWithSub
            to=''
            title='Leave Tracker'
            iconpath='/media/icons/duotune/files/fil011.svg'
            fontIcon='bi-app-indicator'
          >

            <SidebarMenuItem

              permission={permissionArray.some(obj => obj.permissionName === 'Leave Requists')}
              to='employer/leave/notification'
              iconpath=''
              title='Leave Requests'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />

            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Employe tracker')}
              to='employer/leave/leaveTracker'
              iconpath=''
              title='Employee Tracker'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Leave Policie')}
              to='employer/hr/policies'
              iconpath=""
              title='Leave Policy'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Submited Leave Policie')}

              to='employer/leave/leaveForms'
              iconpath=""
              title='Submitted Leave Forms'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </div>

        <div style={{
          display: permissionArray.some(obj => obj.permissionName === "Employee profile" || obj.permissionName === 'Insured Staff' || obj.permissionName === 'payroll' || obj.permissionName === 'Renewal status' || obj.permissionName === 'Employee Folder' || obj.permissionName === 'Hr Lettrt format' || obj.permissionName === 'Company Documents') ? 'flex' : 'none',
          width: '100%'
        }}>

          <SidebarMenuItemWithSub
            to=''
            title='HR Management'
            iconpath='/media/icons/duotune/electronics/elc004.svg'
            fontIcon='bi-app-indicator'
          >
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Employee profile')}
              to='employer/hr/employee'
              hasBullet={true}
              title='Employee Profile'
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Insured Staff')}
              to='employer/hr/insuredStaff'
              hasBullet={true}
              title='Insured Staff'
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'payroll')}
              to='employer/hr/payroll'
              hasBullet={true}
              title='Payroll'
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Renewal status')}
              to='employer/hr/renewal'
              hasBullet={true}
              title='Renewal Status'
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Employee Folder')}
              to='employer/hr/folder'
              hasBullet={true}
              title='Employees Folder'
              fontIcon='bi-app-indicator'
            />

            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Hr Lettrt format')}
              to='employer/hr/letters'
              hasBullet={true}
              title='HR Letters Format'
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Company Documents')}
              to='employer/hr/OverallPolicies'
              hasBullet={true}
              title='Company Documents'
              fontIcon='bi-app-indicator'
            />
          </SidebarMenuItemWithSub>
        </div>

        <div style={{
          display: permissionArray.some(obj => obj.permissionName === "register new employe" || obj.permissionName === 'Employe Details') ? 'flex' : 'none',
          width: '100%'
        }}>
          <SidebarMenuItemWithSub
            to=''
            title='User Details'
            icon='user-edit'
            fontIcon='bi-app-indicator'
          >
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'register new employe')}
              to='employer/hr/RegisterEmployees/Register'
              icon=''
              title='Register New Employee'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Employe Details')}
              to='employer/hr/EmployeeInfo/EmployeeInfo'
              icon=''
              title='Employee Details'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </div>

        <div style={{
          display: permissionArray.some(obj => obj.permissionName === "employer Kpi Quistions" || obj.permissionName === 'Kpi Report' || obj.permissionName === 'Kpi Summary') ? 'flex' : 'none',
          width: '100%'
        }}>

          <SidebarMenuItemWithSub
            to=''
            title='KPI'
            icon='ranking'
            fontIcon='bi-app-indicator'
          >

            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'employer Kpi Quistions')}
              to='/employer/EmployerKPI/KPIQuestions' icon='' title='KPI Questions' fontIcon='bi-layers' hasBullet={true} />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Kpi Report')}
              to='/employer/EmployerKPI/ReportKPI' icon='' title='KPI Report' fontIcon='bi-layers' hasBullet={true} />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Kpi Summary')}
              to='/employer/EmployerKPI/SummaryKPI' icon='' title='KPI Summary' fontIcon='bi-layers' hasBullet={true} />

          </SidebarMenuItemWithSub>

        </div>


        <div style={{
          display: permissionArray.some(obj => obj.permissionName === "Vehcle" || obj.permissionName === 'Vehcle Documents') ? 'flex' : 'none',
          width: '100%'
        }}>
          <SidebarMenuItemWithSub
            to=''
            title='Car'
            icon='car'
            fontIcon='bi-app-indicator'
          >
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Vehcle')}
              to='employer/car/vehicles'
              icon=''
              title='Vehicles'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Vehcle Documents')}
              to='employer/car/documents'
              iconpath=""
              title='Vehicle Documents'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </div>




        <div style={{
          display: permissionArray.some(obj => obj.permissionName === "Renewal Company documents" || obj.permissionName === 'TM/TD Documents' || obj.permissionName === 'Bank Gurantee') ? 'flex' : 'none',
          width: '100%'
        }}>
          <SidebarMenuItemWithSub
            to=''
            title='Organisation'
            icon='home'
            fontIcon='bi-app-indicator'
          >
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Renewal Company documents')}
              to='employer/organisation/documents'
              iconpath=""
              title='Renewal Company Documents'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'TM/TD Documents')}
              to='employer/organisation/companyDoc'
              iconpath=""
              title='TM/TD Documents'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'Bank Gurantee')}
              to='employer/organisation/bankGuarantee'
              icon=''
              title='Bank Guarantee'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
            <SidebarMenuItem
              permission={permissionArray.some(obj => obj.permissionName === 'ProductsServices')}
              to='employer/organisation/products'
              icon=''
              title='Contracts/Agreements'
              fontIcon='bi-app-indicator'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>
        </div>



        <div style={{
          display: permissionArray.some(obj => obj.permissionName === "Department" || obj.permissionName === 'Division' || obj.permissionName === 'Department' || obj.permissionName === "Admin register new employe") ? '' : 'none',
          width: '100%'
        }}>

         {/*<div className='menu-item'>
            <div className='menu-content pt-8'>
              <span className='menu-section text-uppercase fs-8 ls-1 text-white'>Super Admin Pages</span>
            </div>
      </div> */}

          <SidebarMenuItem
            to='/superadmin/department'
            permission={permissionArray.some(obj => obj.permissionName === 'Department')}
            // iconpath='/media/icons/duotune/general/gen005.svg'
            title='Department' fontIcon='bi-layers'
            icon='home'
          />

          <SidebarMenuItem
            to='/superadmin/division'
            permission={permissionArray.some(obj => obj.permissionName === 'Division')}
            iconpath='/media/icons/duotune/communication/com005.svg'
            title='Division' fontIcon='bi-layers'
          />

          <SidebarMenuItem
            to='/superadmin/Position'
            permission={permissionArray.some(obj => obj.permissionName === 'Position')}
            // iconpath='/media/icons/duotune/communication/com005.svg'
            title='Position' fontIcon='bi-layers'
            iconpath='/media/icons/duotune/files/fil011.svg'
          />

          <SidebarMenuItem
            to='/superadmin/RegisterEmployees/Register'
            permission={permissionArray.some(obj => obj.permissionName === 'Admin register new employe')}
            // iconpath='/media/icons/duotune/electronics/elc004.svg'
            title='Add Staff' fontIcon='bi-layers'
            icon='user-edit'
          />

          <SidebarMenuItem
            to='/superadmin/deductiontypes'
            permission={permissionArray.some(obj => obj.permissionName === 'Deduction Types')}
            // iconpath='/media/icons/duotune/communication/com005.svg'
            title='Deduction' fontIcon='bi-layers'
            icon='ranking'
          />


        </div>
      </>

      <br></br ><br></br><br></br><br></br><br></br>

    </>
  )
}

export { SidebarMenuMain }

