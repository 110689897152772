/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  icon: string
  title: string
  description: string
  link ?: string
  check?:boolean
}

const Card4: FC<Props> = ({icon, title, description,link,check=false}) => {

  const restrictedTextStyle: React.CSSProperties = {
    display: '-webkit-box',
    WebkitLineClamp: 2, // Limit to 2 lines
    WebkitBoxOrient: 'vertical' as any, // Cast to 'any' to bypass type-checking
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.2', // Adjust line height if needed
    maxHeight: '2.4em' // Should be line-height * number of lines
  };
  return (
    <div className='card h-100'>
      <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
        { link ?
        (<a href={link ? link : '#'} onClick={(e)=>{
          if(!link){
            e.preventDefault(); 
            alert('Document is not available')
          }
        }} className='text-gray-800 text-hover-primary d-flex flex-column' target={(link && check) ? "_self" : "_blank"}>
          <div className='symbol symbol-75px mb-6'>
            <img src={toAbsoluteUrl(icon)} alt='' />
          </div>
          <div className='fs-5 fw-bolder mb-2' style={restrictedTextStyle} >{title}</div>
        </a>) :
        (<a href='#' className='text-gray-800 text-hover-primary d-flex flex-column'>
        <div className='symbol symbol-75px mb-6'>
          <img src={toAbsoluteUrl(icon)} alt='' />
        </div>
        <div className='fs-5 fw-bolder mb-2' style={restrictedTextStyle} >{title}</div>
      </a>)
}
        <div className='fs-7 fw-bold text-gray-400 mt-auto'>{description}</div>
      </div>
    </div>
  )
}

export {Card4}
