
import React, { useEffect, useRef, useState } from 'react';
import { MixedWidget16 } from '../../../../_metronic/partials/widgets';
import useFetchData from '../../../hooks/getData';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1280 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1280, min: 700 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1
  }
};

const CustomLeftArrow = ({ onClick }) => {
  const arrowStyle = {
    backgroundColor: 'white',
    border: 'none',
    position: 'absolute',
    zIndex: 0, // Reduced z-index
    width: '40px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.49)",
    borderRadius: "5px"
  };

  return (
    <button
      onClick={onClick}
      style={arrowStyle}
    >
      &lt;
    </button>
  );
};

const CustomRightArrow = ({ onClick }) => {
  const arrowStyle = {
    backgroundColor: 'white',
    border: 'none',
    position: 'absolute',
    zIndex: 0, // Reduced z-index
    width: '40px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: "5px",
    boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.29)",

  };

  return (
    <button
      onClick={onClick}
      style={arrowStyle}
    >
      &gt;
    </button>
  );
};


export function LeaveStatus({ isVisible }) {

  const { fetchedData } = useFetchData('getLeaveStatus');

  const [index, setIndex] = useState()
  const [maxWidth, setMaxWidth] = useState('80%'); // Default to 80%

  useEffect(() => {
    // Update maxWidth based on screen width
    const handleResize = () => {
      setMaxWidth(window.innerWidth < 450 ? '100%' : '80%');
    };

    // Set initial maxWidth
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const statusDetails = fetchedData ? fetchedData[index]?.leaveDetails : '';

  let Data;


  if (statusDetails) {
    try {
      const parsedDetails = JSON.parse(statusDetails)
      Data = parsedDetails
    } catch (error) {
      console.log("Found an error");
    }
  } else {
    console.log("statusDetails empty or something else")
  }


  return (
    <div className='accordion-item bg-transparent pb-2' style={{ backgroundColor: 'transparent', paddingBottom: '2rem' }}>
      <div
        id='collapseExample3'
        className='accordion-collapse collapse'
        aria-labelledby='kt_accordion_header_3'
        data-bs-parent='#kt_accordion'
      >
        <div className="card  bg-primary-subtle" >
          <div className="py-10 " style={{ backgroundColor: 'transparent', borderRadius: "15px" }}>
            <div className="tab-content" id="myTabContent">
              <div className='mx-auto ' style={{ maxWidth }}>
                {isVisible && <Carousel
                  responsive={responsive}
                  infinite={true}
                  itemClass=''
                  customTransition="all 0.5s ease"
                  customLeftArrow={<CustomLeftArrow />}
                  customRightArrow={<CustomRightArrow />}
                >
                  {/* begin::Row */}
                  {/* begin::Col */}
                  <div className="mx-3" key={index} style={{ boxShadow: 'none', backgroundColor: "transparent" }} >
                    {
                      fetchedData && (
                        <a onClick={() => setIndex(0)} data-bs-toggle="collapse" href="#Sick_Leave_Dates_Table" role="button" aria-expanded="false" aria-controls="Sick_Leave_Dates_Table">
                          <MixedWidget16
                            className='h-md-20 d-flex justify-content-center align-items-center '
                            chartProgressColor={`#143983`}
                            chartTrackColor={`#BFCAE2`}
                            chartHeight='250px'
                            labelColor={`${(fetchedData[0]?.availablecount < fetchedData[0]?.totalcount) ? "red" : 'gray'}`}
                            chartTitle={fetchedData[0]?.policytitle}
                            chartCompleted={fetchedData[0]?.totalcount || 0}
                            chartTotal={fetchedData[0]?.availablecount || 0}
                          />
                        </a>
                      )
                    }
                  </div>
                  {/* end::Col */}
                  {/* begin::Col */}

                  <div className="mx-3" key={index} style={{ boxShadow: 'none' }} >
                    {
                      fetchedData && (
                        <a onClick={() => setIndex(1)} className="" data-bs-toggle="collapse" style={{ boxShadow: 'none' }} href="#Sick_Leave_Dates_Table" role="button" aria-expanded="false" aria-controls="Sick_Leave_Dates_Table">
                          <MixedWidget16
                            className='h-md-20 d-flex justify-content-center align-items-center  '
                            chartProgressColor={`#34a8cb`}
                            chartTrackColor={`#ff0000`}
                            chartHeight='250px'
                            labelColor={`${(fetchedData[1]?.availablecount < fetchedData[1]?.totalcount) ? "red" : 'gray'}`}
                            chartTitle={fetchedData[1]?.policytitle}
                            chartCompleted={fetchedData[1]?.totalcount || 0}
                            chartTotal={fetchedData[1]?.availablecount || 0}
                          />
                        </a>
                      )
                    }
                  </div>
                  {/* end::Col */}
                  {/* begin::Col */}
                  <div className="mx-3" key={index} style={{ boxShadow: 'none' }}>
                    {
                      (fetchedData) && (
                        <a onClick={() => setIndex(2)} className="" data-bs-toggle="collapse" style={{ boxShadow: 'none' }} href="#Sick_Leave_Dates_Table" role="button" aria-expanded="false" aria-controls="Sick_Leave_Dates_Table">
                          <MixedWidget16
                            className='h-md-20 d-flex justify-content-center align-items-center  '
                            chartProgressColor={`#6255a3`}
                            chartTrackColor={`#D1CCEC`}
                            chartHeight='250px'
                            labelColor={`${(fetchedData[2]?.availablecount < fetchedData[2]?.totalcount) ? "red" : 'gray'}`}
                            chartTitle={fetchedData[2]?.policytitle}
                            chartCompleted={fetchedData[2]?.totalcount || 0}
                            chartTotal={fetchedData[2]?.availablecount || 0}
                          />
                        </a>
                      )
                    }
                  </div>
                  {/* end::Col */}

                  {/* begin::Row */}

                  {/* begin::Col */}
                  <div className="mx-3" key={index} style={{ boxShadow: 'none' }} >
                    {
                      fetchedData && (
                        <a onClick={() => setIndex(3)} className="" data-bs-toggle="collapse" style={{ boxShadow: 'none' }} href="#Sick_Leave_Dates_Table" role="button" aria-expanded="false" aria-controls="Sick_Leave_Dates_Table">
                          <MixedWidget16
                            className='h-md-20 d-flex justify-content-center align-items-center  '
                            chartProgressColor={`#B24C37`}
                            chartTrackColor={`#ECA191`}
                            chartHeight='250px'
                            labelColor={`${(fetchedData[3]?.availablecount < fetchedData[3]?.totalcount) ? "red" : 'gray'}`}
                            chartTitle={fetchedData[3]?.policytitle}
                            chartCompleted={fetchedData[3]?.totalcount || 0}
                            chartTotal={fetchedData[3]?.availablecount || 0}
                          />
                        </a>
                      )
                    }
                  </div>
                  {/* end::Col */}
                  {/* begin::Col */}
                  <div className="mx-3" key={index} style={{ boxShadow: 'none' }}>
                    {
                      fetchedData && (
                        <a onClick={() => setIndex(4)} className="" data-bs-toggle="collapse" style={{ boxShadow: 'none' }} href="#Sick_Leave_Dates_Table" role="button" aria-expanded="false" aria-controls="Sick_Leave_Dates_Table">
                          <MixedWidget16
                            className='h-md-20 d-flex justify-content-center align-items-center  '
                            chartProgressColor={`#ED62E2`}
                            chartTrackColor={`#FFA7F8`}
                            chartHeight='250px'
                            labelColor={`${(fetchedData[4]?.availablecount < fetchedData[4]?.totalcount) ? "red" : 'gray'}`}
                            chartTitle={fetchedData[4]?.policytitle}
                            chartCompleted={fetchedData[4]?.totalcount || 0}
                            chartTotal={fetchedData[4]?.availablecount || 0}
                          />
                        </a>
                      )
                    }
                  </div>
                  {/* end::Col */}

                  {/* begin::Col */}
                  <div className="mx-3" key={index} style={{ boxShadow: 'none' }}>
                    {
                      fetchedData && (
                        <a onClick={() => setIndex(5)} className="" data-bs-toggle="collapse" style={{ boxShadow: 'none' }} href="#Sick_Leave_Dates_Table" role="button" aria-expanded="false" aria-controls="Sick_Leave_Dates_Table">
                          <MixedWidget16
                            className='h-md-20 d-flex justify-content-center align-items-center  '
                            chartProgressColor={`#355e3b`}
                            chartTrackColor={`#addfad`}
                            chartHeight='250px'
                            labelColor={`${(fetchedData[5]?.availablecount < fetchedData[5]?.totalcount) ? "red" : 'gray'}`}
                            chartTitle={fetchedData[5]?.policytitle}
                            chartCompleted={fetchedData[5]?.totalcount || 0}
                            chartTotal={fetchedData[5]?.availablecount || 0}
                          />
                        </a>
                      )
                    }
                  </div>
                  {/* end::Col */}
                  {/* begin::Row */}
                  {/* begin::Col */}
                  <div className="mx-3" key={index} style={{ boxShadow: 'none' }}>
                    {
                      fetchedData && (
                        <a onClick={() => setIndex(6)} className="col-md-6 col-xxl-3" style={{ boxShadow: 'none' }} data-bs-toggle="collapse" href="#Sick_Leave_Dates_Table" role="button" aria-expanded="false" aria-controls="Sick_Leave_Dates_Table">
                          <MixedWidget16
                            className='h-md-20 d-flex justify-content-center align-items-center  '
                            chartProgressColor={`#FFC300`}
                            chartTrackColor={`#FFDF75`}
                            chartHeight='250px'
                            labelColor={`${(fetchedData[6]?.availablecount < fetchedData[6]?.totalcount) ? "red" : 'gray'}`}
                            chartTitle={fetchedData[6]?.policytitle}
                            chartCompleted={fetchedData[6]?.totalcount || 0}
                            chartTotal={fetchedData[6]?.availablecount || 0}
                          />
                        </a>
                      )
                    }
                  </div>
                  {/* end::Col */}

                  {/* begin::Col */}
                  <div className="mx-3" key={index} style={{ boxShadow: 'none' }}>
                    {
                      fetchedData && (
                        <a onClick={() => setIndex(7)} className="col-md-6 col-xxl-3" style={{ boxShadow: 'none' }} data-bs-toggle="collapse" href="#Sick_Leave_Dates_Table" role="button" aria-expanded="false" aria-controls="Sick_Leave_Dates_Table">
                          <MixedWidget16
                            className='h-md-20 d-flex justify-content-center align-items-center  '
                            chartProgressColor={`#5A7FFF`}
                            chartTrackColor={`#B7C7FF`}
                            chartHeight='250px'
                            labelColor={`${(fetchedData[7]?.availablecount < fetchedData[7]?.totalcount) ? "red" : 'gray'}`}
                            chartTitle={fetchedData[7]?.policytitle}
                            chartCompleted={fetchedData[7]?.totalcount || 0}
                            chartTotal={fetchedData[7]?.availablecount || 0}
                          />
                        </a>
                      )
                    }
                  </div>
                  {/* end::Col */}

                  {/* begin::Col */}
                  <div className="mx-3" key={index} style={{ boxShadow: 'none' }}>
                    {
                      fetchedData && (
                        <a onClick={() => setIndex(8)} className="col-md-6 col-xxl-3" data-bs-toggle="collapse" style={{ boxShadow: 'none' }} href="#Sick_Leave_Dates_Table" role="button" aria-expanded="false" aria-controls="Sick_Leave_Dates_Table">
                          <MixedWidget16
                            className='h-md-20 d-flex justify-content-center align-items-center  '
                            chartProgressColor={`#FC3C85`}
                            chartTrackColor={`#FFC0D8`}
                            chartHeight='250px'
                            labelColor={`${(fetchedData[8]?.availablecount < fetchedData[8]?.totalcount) ? "red" : 'gray'}`}
                            chartTitle={fetchedData[8]?.policytitle}
                            chartCompleted={fetchedData[8]?.totalcount || 0}
                            chartTotal={fetchedData[8]?.availablecount || 0}

                          />
                        </a>
                      )
                    }
                  </div>
                  {/* end::Col */}
                  {/* begin::Col */}
                  <div className="mx-3" key={index} style={{ boxShadow: 'none' }}>
                    {
                      fetchedData && (
                        <a onClick={() => setIndex(9)} className="col-md-6 col-xxl-3" data-bs-toggle="collapse" style={{ boxShadow: 'none' }} href="#Sick_Leave_Dates_Table" role="button" aria-expanded="false" aria-controls="Sick_Leave_Dates_Table">
                          <MixedWidget16
                            className='h-md-20 d-flex justify-content-center align-items-center  '
                            chartProgressColor={`#FC3C85`}
                            chartTrackColor={`#FFC0D8`}
                            chartHeight='250px'
                            labelColor={`${(fetchedData[9]?.availablecount < fetchedData[9]?.totalcount) ? "red" : 'gray'}`}
                            chartTitle={fetchedData[9]?.policytitle}
                            chartCompleted={fetchedData[9]?.totalcount || 0}
                            chartTotal={fetchedData[9]?.availablecount || 0}

                          />
                        </a>
                      )
                    }
                  </div>
                  {/* end::Col */}
                </Carousel>}
              </div>
            </div>
            {
              Data && (
                <div className="d-flex justify-content-center align-items-center" >
                  <div className="collapse w-sm-75 w-md-50 w-100" id="Sick_Leave_Dates_Table">
                    <div className="table-responsive">
                      <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 bg-white gy-5 gs-7 shadow">
                        <thead>
                          <tr className="fw-bold fs-5 bg-primary rounded text-white">
                            <th><i className="bi bi-calendar2-day-fill fs-3 me-2 svg-icon-muted text-white"></i>Leave Type:</th>
                            <th><i className="bi bi-calendar2-day-fill fs-3 me-2 svg-icon-muted text-white"></i>First day of Leave:</th>
                            <th><i className="bi bi-calendar2-day-fill fs-3 me-2 svg-icon-muted text-white"></i>Return to work date:</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Data?.sort((a, b) => new Date(b?.startDate) - new Date(a?.startDate)).map((data, index) => (
                            <tr key={index}>
                              <td className="text-primary fw-bold"><span className="badge p-2">{data?.leaveType}</span></td>
                              <td className="text-primary fw-bold"><span className="badge badge-primary p-2">{data?.startDate}</span></td>
                              <td className="text-primary fw-bold"><span className="badge badge-light-primary p-2">{data?.ReturnDate}</span></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
} 