import { Typography } from "@mui/material"

type Props = {
    className: string
    color: string
    img: string
    title: string
    description: string
    url?: string
    availablecount?: number
    totalcount?:number
    count?:number
    onClick?: (() => void),
}

const CardsWidget21 = ({ className, color, img, title, description, url, onClick, availablecount,totalcount ,count}: Props) => (

    <a href={url}
        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end py-4 ${className}`}
        style={{
            overflow: 'hidden',
            backgroundColor: color,
            backgroundImage: `url('${img}')`,
            backgroundSize: 'cover',
            cursor: 'pointer', width: '90%',
            margin: '0 auto'
        }}
        onClick={onClick}
    >
        <div style={{ width: '100%' }} className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
                {count && <span
                    style={{ fontSize: '3rem',whiteSpace: 'nowrap' }}
                    className=' fw-bold text-white me-2 lh-1 '
                >{count}</span>}

                {!count && <span
                    style={{ fontSize: '1.3rem',whiteSpace: 'nowrap' }}
                    className=' fw-bold text-white me-2 lh-1'
                >{availablecount && totalcount ? `${totalcount}/${availablecount}` : `0/${availablecount}`}</span>}

                <span
                    className='text-white pt-5 fw-semibold fs-9'
                    style={{ fontSize: '2rem' }}
                >{title}</span>

            </div>
        </div>

    </a>
)
export { CardsWidget21 }



// const CardsWidget21 = ({ className, color, img, title, description, url, onClick, count }) => (
//   <a href={url}
//     className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
//     style={{
//       overflow: 'hidden',
//       backgroundColor: color,
//       backgroundImage: `url('${img}')`,
//       backgroundSize: 'cover',
//       cursor: 'pointer',
//     }}
//     onClick={onClick}
//   >
//     <div className='card-header pt-5'>
//       <div className='card-title d-flex flex-column'>
//         <span className='fs-2x fw-bold text-white me-2 lh-1 ls-n3'>{count}</span>

//         <span className='text-white pt-5 fw-semibold fs-8'>{title}</span>
//       </div>
//     </div>
//   </a>
// );

// export { CardsWidget21 };

