import React, { useState } from 'react';
import { KTIcon, KTSVG } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import useFetchData from '../../hooks/getData';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import { message } from "antd";
import { format } from 'formik';
import moment from 'moment';


export default function EditInsuranceTable({ selectedData }) {


    const [gender, setGender] = useState()
    const [maritalStatus, setMeritalSatus] = useState();
    const [nationality, setNationality] = useState()

    const [employeId, setEmployeName] = useState()

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    // const employeList = useFetchData('getEmployeesList').fetchedData;

    // const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getEmployeeDetailsForInsurance&employeeId=${employeId}&userId=${id}`, [shouldRefetch])

    // const details = fetchedData ? fetchedData[0] : '';



    const d = '2000-12-04'

    const originalDate = "8/4/1990";
    const formattedDate = moment(selectedData?.dateOfBirth, "M/D/YYYY").format("YYYY-MM-DD");


    const initialValues = {
        policyNumber: selectedData ? selectedData?.policyNumber : '',
        insuranceName:selectedData ? selectedData?.insuranceName:'',
        insuranceAmount:selectedData ? selectedData?.insuranceAmount:'',
        familyMemberId:selectedData ? selectedData?.familyMemberId:""
    };

    const showMessage = () => {
        message.success({
            content: 'Updated insurance staff',
            duration: 1
        });
    };

    const a = () => {
        message.info({
            content: 'Failed to update',
            duration: 1
        });
    };

    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            axios.post(`${BASE_URL_TWO}`, {
                sp: 'updInsuredstaffDetails',
                userId: id,
                policyNumber:  values.policyNumber,
                insuranceName: values.insuranceName,
                insuranceAmount: values.insuranceAmount,
                familyMemberId: selectedData?.familyMemberId
            }, { headers }).then(() => {
                showMessage()
                setShouldRefetch(prevState => !prevState)
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            }).catch((err) => {
                a()
            })
        },
        enableReinitialize: true
    });

    const [showModal, setShowModal] = useState(true);

    return (
        <div className="modal fade" id="kt_modal_3" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">



            <form style={{ width: '100%' }} onSubmit={formik.handleSubmit} action="">

                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Employee Insurance Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className='row mb-1'>
                                <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Policy Number</label>
                                <div className='col-lg-6 fv-row'>
                                    <input
                                        type='number'
                                        className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                                        placeholder=''
                                        name='policyNumber'
                                        value={formik.values.policyNumber}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.policyNumber}</span>
                                </div>
                            </div>


                            <div className='row mb-1'>
                                <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Insurance Name</label>
                                <div className='col-lg-6 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                                        placeholder=''
                                        name="insuranceName"
                                        value={formik.values?.insuranceName}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.insuranceName}</span>
                                </div>
                            </div>

                            <div className='row mb-1'>
                                <label className='col-lg-4 col-form-label fw-bold fs-8 text-light-emphasis'>Insurance Amount</label>
                                <div className='col-lg-6 fv-row'>
                                    <input
                                        type='number'
                                        className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
                                        placeholder=''
                                        name="insuranceAmount"
                                        value={formik.values?.insuranceAmount}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.insuranceAmount}</span>

                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary" >
                                Add
                            </button>
                        </div>
                    </div>
                </div >
            </form >
        </div >
    )
}
