import React, { FC, useEffect, useState } from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { ErrorMessage, Field, Formik } from 'formik';
import { useFormik } from 'formik'
import { isSetAccessor } from 'typescript';
import { Grid } from '@mui/material';
import useFetchCommon from '../../../../../hooks/getCommonData';
import useGetDataWithFullUrl from '../../../../../hooks/getDataWithFullUrl';
import CommonDataRefetch from '../../../../../hooks/CommonDataRefetch';
import { BASE_URL, BASE_URL_TWO } from '../../../../../../utils';
import * as Yup from 'yup';
import axios from 'axios';
import Cookies from 'js-cookie';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


const checkEmailExists = async (email) => {
  const id = Cookies.get('userId');
  const token = Cookies.get("token")
  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.get(`${BASE_URL}spCheckEmailExists&userId=${id}&email=${email}&employeeId=`, { headers });
    return response?.data; // Assuming the API returns { exists: true/false }
  } catch (error) {

    return error?.response?.data;
  }
};

const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employe id required'),
  dateOFJoining: Yup.string().required('Date of joining required'),
  workPlace: Yup.string().required('work place required'),
  carInsurancePolicyNumber: Yup.string(),
  visaType: Yup.string().required('visa type is required'),
  visaExpDate: Yup.date(),
  visaNumber: Yup.string(),
  Department: Yup.string().required('Department is required'),
  internalJobTitle: Yup.string().required('Jobtitle is required'),
  mpJobTitle: Yup.string().required('mp jobtitle is required'),
  mpJoiningDate: Yup.string().required('mp joining date is required'),
  mpJobNumber: Yup.string().required('mp job number is required'),
  totlaSalary: Yup.string().required('totlaSalary is required'),
  basicSalary: Yup.string().required('basic is required'),
  position: Yup.string().required('Position is required'),
  // healthInsurancePolicyNumber: Yup.string().required('insurance police number is required'),
  emailId: Yup.string()
    .email('Invalid email address')
    .required('Required')
    .test('checkEmailExists', 'Email already exists', async function (value) {
      if (value) {
        const isEmailTaken = await checkEmailExists(value);
        console.log('checkEmailExists result:', isEmailTaken); // Debugging line

        if (isEmailTaken.Message !== "Success") {
          return false; // Email exists, trigger validation error
        }
        return true; // Email does not exist, pass validation
      }

      return true; // If no value is present, pass validation
    }),
  Division: Yup.string().required('Division is required'),
  contactFrom: Yup.string(),
  linemanager1: Yup.string().required('Linemanger1 is required'),
  linemanager2: Yup.string().required('Linemanger2 is required'),
  contactTo: Yup.string(),
  employeeWorkStatus: Yup.string().required('work status is required'),
})

const Step2 = (props) => {

  const { setSteps, corporateDetails, setCorporateDetails } = props

  const [employeId, setEmployeId] = useState('')
  const [division, setDivision] = useState('');
  const [workStatus, setWorkStatus] = useState('')

  // state variables for dropdowns
  const [lineManager1, setLineManager1] = useState();
  const [lineManager2, setLineManager2] = useState();
  const [deptId, setDeptId] = useState();
  const [visaType, setVisaType] = useState();
  const [mpId, setMpId] = useState();
  const [position, setPostion] = useState()
  const [d, setD] = useState()

  const [shouldRefetch, setShouldRefetch] = useState(false);

  const getDepartmentList = CommonDataRefetch(`${BASE_URL}getDepartmentList`)?.fetchedData

  const fetchOnece = () => setShouldRefetch(prevState => !prevState)

  const localStorageData = JSON.parse(localStorage.getItem('corparateDetails'))

  let mpJobTitlAndPosition = CommonDataRefetch(`${BASE_URL}getPositionListByDeptId&deptId=${localStorageData?.Department || deptId}`, [shouldRefetch])?.fetchedData

  let one = CommonDataRefetch(`${BASE_URL}getPositionListByDeptId&deptId=${localStorageData?.Department || deptId}`, [shouldRefetch])?.fetchedData

  let divisions = CommonDataRefetch(`${BASE_URL}getDivisionListByPositionId&positionId=${localStorageData?.position || position}`, [shouldRefetch])?.fetchedData

  /// dropdown api options fetching
  const [linemanagers1, setLinemanagers1] = useState([]);
  const [linemanagers2, setLinemanagers2] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);
  const [employeeIds, setEmployeeIds] = useState([]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      const response1 = await axios(`${BASE_URL}getLineManager1ForDropdown`);
      const response2 = await axios(`${BASE_URL}getLineManager2ForDropdown`);
      const response3 = await axios(`${BASE_URL}getvisaTypeForDropdown`);
      const response4 = await axios(`${BASE_URL}GetAvilableEmployeeId`)
      setEmployeeIds(response4?.data)
      setLinemanagers1(response1?.data);
      setLinemanagers2(response2?.data)
      setVisaTypes(response3?.data);
    }
    fetchDropdownOptions()
  }, [])

  const initialValues = {
    employeeId: localStorageData?.employeeId ? localStorageData?.employeeId : '',
    dateOFJoining: localStorageData?.dateOFJoining ? localStorageData?.dateOFJoining : '',
    workPlace: localStorageData?.workPlace ? localStorageData?.workPlace : '',
    carInsurancePolicyNumber: localStorageData?.carInsurancePolicyNumber ? localStorageData?.carInsurancePolicyNumber : '',
    visaType: localStorageData?.visaType ? localStorageData?.visaType : '',
    visaExpDate: localStorageData?.visaExpDate ? localStorageData?.visaExpDate : '',
    visaNumber: localStorageData?.visaNumber ? localStorageData?.visaNumber : '',
    Department: localStorageData?.Department ? localStorageData?.Department : '',
    internalJobTitle: localStorageData?.internalJobTitle ? localStorageData?.internalJobTitle : '',
    mpJobTitle: localStorageData?.mpJobTitle ? localStorageData?.mpJobTitle : '',
    mpJoiningDate: localStorageData?.mpJoiningDate ? localStorageData?.mpJoiningDate : '',
    mpJobNumber: localStorageData?.mpJobNumber ? localStorageData?.mpJobNumber : '',
    totlaSalary: localStorageData?.totlaSalary ? localStorageData?.totlaSalary : '',
    basicSalary: localStorageData?.basicSalary ? localStorageData?.basicSalary : '',
    position: localStorageData?.position ? localStorageData?.position : '',
    healthInsurancePolicyNumber: localStorageData?.healthInsurancePolicyNumber ? localStorageData?.healthInsurancePolicyNumber : '',
    emailId: localStorageData?.emailId ? localStorageData?.emailId : '',
    Division: localStorageData?.Division ? localStorageData?.Division : '',
    contactFrom: localStorageData?.contactFrom ? localStorageData?.contactFrom : '',
    contactTo: localStorageData?.contactTo ? localStorageData?.contactTo : '',
    linemanager1: localStorageData?.linemanager1 ? localStorageData?.linemanager1 : '',
    linemanager2: localStorageData?.linemanager2 ? localStorageData?.linemanager2 : '',
    workExpTDay: localStorageData?.workExpTDay ? localStorageData?.workExpTDay : '',
    workExpTYear: localStorageData?.workExpTYear ? localStorageData?.workExpTYear : '',
    workExpTMonth: localStorageData?.workExpTMonth ? localStorageData?.workExpTMonth : '',
    workExpGYear: localStorageData?.workExpGYear ? localStorageData?.workExpGYear : '',
    workExpGMonth: localStorageData?.workExpGMonth ? localStorageData?.workExpGMonth : '',
    workExpGDay: localStorageData?.workExpGDay ? localStorageData?.workExpGDay : '',
    employeeWorkStatus: localStorageData?.employeeWorkStatus ? localStorageData?.employeeWorkStatus : '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      localStorage.setItem('corparateDetails', JSON.stringify(values))
      // console.log("first>>>>>>>>>>>>>>>>>>>>", values)
      setCorporateDetails({
        employeeId: employeId,
        dateOFJoining: values?.dateOFJoining,
        workPlace: values?.workPlace,
        carInsurancePolicyNumber: values?.carInsurancePolicyNumber,
        visaType: visaType,
        visaExpDate: values?.visaExpDate,
        visaNumber: values?.visaNumber,
        Department: deptId,
        internalJobTitle: values?.internalJobTitle,
        mpJobTitle: mpId,
        mpJoiningDate: values?.mpJoiningDate,
        mpJobNumber: values?.mpJobNumber,
        totlaSalary: values?.totlaSalary,
        basicSalary: values?.basicSalary,
        position: position,
        healthInsurancePolicyNumber: values?.healthInsurancePolicyNumber,
        emailId: values?.emailId,
        Division: d,
        contactFrom: values?.contactFrom,
        contactTo: values?.contactTo,
        linemanager1: lineManager1,
        linemanager2: lineManager2,
        workExpTYear: values?.workExpTYear,
        workExpTMonth: values?.workExpTMonth,
        workExpTDay: values?.workExpTDay,
        workExpGYear: values?.workExpGYear,
        workExpGMonth: values?.workExpGMonth,
        workExpGDay: values?.workExpGDay,
        employeeWorkStatus: workStatus,
      })
      setSteps('step3')
    },
  });

  console.log("formik.errors", formik.errors);

  useEffect(() => {
    setVisaType(formik.values.visaType);
    setLineManager1(formik.values.linemanager1)
    setLineManager2(formik.values.linemanager2)
    setDeptId(formik.values.Department)
    setPostion(formik.values.position)
    setMpId(formik.values.mpJobTitle)
    setWorkStatus(formik.values.employeeWorkStatus)
    setD(formik.values.Division)
    setEmployeId(formik.values.employeeId);
  }, [corporateDetails])

  useEffect(() => {
    formik.setFieldValue('visaType', visaType);
  }, [visaType])


  return (
    <div className='w-100'>

      <div className='pb-10 pb-lg-15'>
        <h4 className='fw-bolder text-danger'>Employee Corporate Details</h4>

        <div className='text-gray-400 fw-bold fs-7'>
          You need to enter all the information for the employee that is related to the organisation.
        </div>
      </div>

      <form action="" >

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Employee ID <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>

            <select name="employeeId" onChange={(e) => {
              setEmployeId(e.target.value)
              formik.handleChange(e);
            }} value={formik.values.employeeId || employeId} className="form-select form-select-solid fs-7 bg-body-secondary" aria-label="Select example">
              <option value=''>Select an option</option>
              {
                employeeIds?.Data?.map((data, index) =>

                  <option value={data?.Number}>{data?.Number}</option>

                )
              }
            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.employeeId}</span>

          </div>


          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Date Of joining<span className='text-danger'>*</span></label>

          <div className='col-lg-3 fv-row'>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='dateOFJoining'
              value={formik.values.dateOFJoining}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.dateOFJoining}</span>

          </div>
        </div>


        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Workplace<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              // placeholder='Ooredoo, TM, TD'
              name='workPlace'
              value={formik.values.workPlace}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.workPlace}</span>

          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Email ID<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='email'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='emailId'
              value={formik.values.emailId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.emailId}</span>
          </div>
        </div>


        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Visa Type</label>

          <div className='col-lg-3 fv-row'>
            <select name="visaType" onChange={(e) => {
              setVisaType(e.target.value)
              formik.handleChange(e);
            }} value={formik.values.visaType || visaType} className="form-select form-select-solid fs-7 bg-body-secondary" aria-label="Select example">
              <option>Select an option</option>
              {
                visaTypes?.Data?.map((data, index) =>

                  <option value={data?.visaTypeId}>{data?.visaType}</option>

                )
              }
            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.visaType}</span>

          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Visa Number</label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='visaNumber'
              value={formik.values.visaNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.visaNumber}</span>

          </div>
        </div>


        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Visa Exp date</label>
          <div className='col-lg-3 fv-row'>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='visaExpDate'
              value={formik.values.visaExpDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              min={new Date().toLocaleDateString('en-CA')}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.visaExpDate}</span>

          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Department<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <select name="Department" onChange={(e) => {
              setDeptId(e.target.value)
              fetchOnece()
              formik.handleChange(e);
            }} value={deptId} className="form-select form-select-solid fs-7 bg-body-secondary" aria-label="Select example">
              <option value=''>Select Department</option>
              {
                getDepartmentList?.map((data, index) => <option value={data?.deptId}>{data?.deptName}</option>)
              }

            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.Department}</span>

          </div>
        </div>


        <div className='row mb-1'>

          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Internal Job Title<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='internalJobTitle'
              value={formik.values.internalJobTitle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.internalJobTitle}</span>

          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'> MP JobTitle<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <select name="mpJobTitle" onChange={(e) => {
              setMpId(e.target.value)
              fetchOnece()
              formik.handleChange(e);
            }} value={formik.values.mpJobTitle || mpId} className="form-select form-select-solid fs-7 bg-body-secondary" aria-label="Select example">
              <option value=''>select an option</option>
              {
                one?.map((data, index) => <option value={data?.positionId}>{data?.positionName}</option>)
              }

            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.mpJobTitle}</span>

          </div>
        </div>


        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>MP Job Number<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='mpJobNumber'
              value={formik.values.mpJobNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.mpJobNumber}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>MP Joining Date<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='mpJoiningDate'
              value={formik.values.mpJoiningDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.mpJoiningDate}</span>

          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Total Salary<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='totlaSalary'
              value={formik.values.totlaSalary}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.totlaSalary}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Basic Salary<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='basicSalary'
              value={formik.values.basicSalary}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.basicSalary}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Position<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>

            <select
              name="position"
              onChange={(e) => {
                setPostion(e.target.value)
                fetchOnece()
                formik.handleChange(e)
              }}
              className="form-select form-select-solid fs-7 bg-body-secondary" aria-label="Select example"
              value={formik.values.position || position}
            >
              <option value="">select an option</option>
              {
                mpJobTitlAndPosition?.map((data, index) => <option value={data?.positionId} >{data?.positionName}</option>)
              }

            </select>

            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.position}</span>

          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Division<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <select name="Division" onChange={(e) => {
              fetchOnece()
              setD(e.target.value)
              formik.handleChange(e)
            }} value={formik.values.Division || d} className="form-select form-select-solid fs-7 bg-body-secondary" aria-label="Select example">

              <option value="">Select</option>
              {
                divisions?.map((data, index) =>

                  <option value={data?.divisionId}>{data?.divisionName}</option>

                )
              }
            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.Division}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Health Insurance Policy no</label>

          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='healthInsurancePolicyNumber'
              value={formik.values.healthInsurancePolicyNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.healthInsurancePolicyNumber}</span>

          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Car Insurance Policy no.</label>
          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='carInsurancePolicyNumber'
              value={formik.values.carInsurancePolicyNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.carInsurancePolicyNumber}</span>

          </div>
        </div>


        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Contract (From)</label>

          <div className='col-lg-3 fv-row'>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid fs-8 bg-body-secondary'
              placeholder=''
              name='contactFrom'
              value={formik.values.contactFrom}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.contactFrom}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>(To)</label>
          <div className='col-lg-3 fv-row'>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='contactTo'
              value={formik.values.contactTo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.contactTo}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Work Exp Tamimah(Years)</label>

          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-8 bg-body-secondary'
              placeholder=''
              name='workExpTYear'
              value={formik.values.workExpTYear}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.workExpTYear}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Work Exp Tamimah(Months)</label>
          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='workExpTMonth'
              value={formik.values.workExpTMonth}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.workExpTMonth}</span>
          </div>
        </div>
        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Work Exp Tamimah (Days)</label>

          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-8 bg-body-secondary'
              placeholder=''
              name='workExpTDay'
              value={formik.values.workExpTDay}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.workExpTDay}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Work Exp General(Years)</label>
          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='workExpGYear'
              value={formik.values.workExpGYear}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.workExpGYear}</span>
          </div>
        </div>
        <div className='row mb-1'>
        <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Work Exp General (Months)</label>
          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='workExpGMonth'
              value={formik.values.workExpGMonth}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.workExpGMonth}</span>
          </div>
           <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Work Exp General (Days)</label>

          <div className='col-lg-3 fv-row'>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid fs-8 bg-body-secondary'
              placeholder=''
              name='workExpGDay'
              value={formik.values.workExpGDay}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.workExpGDay}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Line Manager 1</label>
          <div className='col-lg-3 fv-row'>
            <select name="linemanager1" onChange={(e) => {
              fetchOnece()
              setLineManager1(e.target.value)
              formik.handleChange(e)
            }} value={formik.values.linemanager1 || lineManager1} className="form-select form-select-solid fs-7 bg-body-secondary" aria-label="Select example">

              <option value="">Select</option>
              {
                linemanagers1?.Data?.map((data, index) =>

                  <option value={data?.userId}>{data?.Name}</option>

                )
              }
            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.linemanager1}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Line Manager 2</label>
          <div className='col-lg-3 fv-row'>
            <select name="linemanager2" onChange={(e) => {
              fetchOnece();
              setLineManager2(e.target.value)
              formik.handleChange(e)
            }} value={formik.values.linemanager2 || lineManager2} className="form-select form-select-solid fs-7 bg-body-secondary" aria-label="Select example">

              <option value="">Select</option>
              {
                linemanagers2?.Data?.map((data, index) =>

                  <option value={data?.userId}>{data?.Name}</option>

                )
              }
            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.linemanager2}</span>
          </div>


        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Employee Work Status<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <select name="employeeWorkStatus" onChange={(e) => {
              setWorkStatus(e.target.value)
              formik.handleChange(e)
            }} value={formik.values.employeeWorkStatus || workStatus} className="form-select form-select-solid fs-7 bg-body-secondary" aria-label="Select example">
              <option value="">Select</option>
              <option value="On Leave">On Leave</option>
              <option value="Inactive">Inactive</option>
              <option value="Active">Active</option>
              <option value="Retired">Retired</option>
            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.employeeWorkStatus}</span>

          </div>
          <label className='col-lg-1'></label>

        </div>

        <div className='row mb-1'>

          <label className='col-lg-1'></label>

        </div>

        <Grid container justifyContent="space-between" sx={{ mt: 4, mb: 7 }}>

          <div className='mr-2'>
            <button
              onClick={() => setSteps('step1')}
              type='button'
              className='btn btn-lg btn-light-primary me-3'
            >
              <KTIcon iconName='arrow-left' className='fs-4 me-1 mb-1' />
              Back
            </button>
          </div>



          <button onClick={formik.handleSubmit} type='button' className='btn btn-lg btn-primary'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className='indicator-label'>
                Continue
              </span>
              <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0 mt-1' />
            </div>
          </button>

        </Grid>

      </form >


    </div >
  )
}

export { Step2 }

