import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { MaterialReactTable, useTable } from 'material-react-table';
import { Autocomplete, Box, ListItemIcon, MenuItem, TextField, Typography } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import { KTIcon } from '../../../_metronic/helpers';
import { data } from './makeData';
import Cookies from 'js-cookie';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import axios from 'axios';

const RenewalStatusTable = () => {
    const id = Cookies.get('userId');
    const token = Cookies.get("token")
    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const [insuredStaffs, setInsuredStaffs] = useState([]);
    const [employeName, setEmployeName] = useState('')

    const dateFilter = (row, columnId, filterValue) => {
        const cellValue = new Date(row.getValue(columnId)); // Get the date value from the cell
        const filterArray = filterValue.replace(/\s+/g, '').split(';'); // Split filter values
        return filterArray.some(filter => {
            const [day, month, year] = filter.toLowerCase().split('-');
            return (
                cellValue.getFullYear() === parseInt(year) &&
                cellValue.getMonth() === getMonthFromString(month) &&
                cellValue.getDate() === parseInt(day)
            );
        });
    };
    
    const getMonthFromString = (month) => {
        const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        return months.indexOf(month);
    };

    const multipleFilter = (row, columnId, filterValue) => {
        const cellValue = row.getValue(columnId).toString().toLowerCase(); // Get the text value from the cell
        const filterArray = filterValue.toLowerCase().split(';'); // Split filter values
        return filterArray.some(filter => cellValue.includes(filter.trim())); // Check if any filter matches
    };

    const fetchInsuredStaff = () => {
        axios.get(`${BASE_URL}getEmployeeDropdownForInsured&userId=${id}`, { headers }).then((response) => {
            setInsuredStaffs(response?.data?.Data)
        })
    }

    useEffect(() => {
        fetchInsuredStaff()
    }, [])

    const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getEmployeeDocRenewalStatus&userId=${id}` + `${employeName ? `&employeeName=${employeName}` : '&employeeName='}`);

    const tableData = fetchedData || [];

    const columns = useMemo(() => [
        {
            id: "empName",
            header: 'Name',
            columns: [
                {
                    id: 'name',
                    header: 'Name',
                    accessorKey: 'employeeName',
                    size: 300,
                    enableGrouping: false,
                    enablePinning: true,
                    filterFn: 'multipleFilter',
                    muiTableHeadCellProps: {
                        style: {
                            paddingLeft: '1rem',
                        },
                    },
                    Cell: ({ renderedCellValue, row }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                                paddingLeft: '1rem',
                            }}
                        >
                            <img
                                alt="avatar"
                                height={30}
                                src={row.original.Avatar
                                    ?`${BASE_URL_TWO}${row.original.Avatar}`: 'https://sipl.ind.in/wp-content/uploads/2022/07/dummy-user.png'}
                                loading="lazy"
                                style={{ borderRadius: '50%',width:"40px",height:"40px"}}
                            />
                            <span>{row.original.employeeName}</span>
                        </Box>
                    ),
                }
            ]
        },
        {
            id: 'visaRenewal',
            header: 'Visa/RC Renewal',
            columns: [
                {
                    accessorKey: 'documentDetails',
                    id: 'visaRenewalDate',
                    header: 'Date',
                    filterFn: 'dateFilter',
                    sortingFn: 'datetime',
                    Cell: ({ cell }) => {
                        const expiryDate = JSON.parse(cell?.getValue())?.[1]?.expiryDate;
                        if (expiryDate) {
                            const date = new Date(expiryDate);
                            return date.toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            });
                        } else {
                            return 'N/A';
                        } 
                    },
                    Header: ({ column }) => <em>{column.columnDef.header}</em>,
                },
                {
                    accessorKey: 'documentDetails',
                    id: 'visaRenewalStatus',
                    header: 'Status',
                    filterFn: 'contains',
                    Cell: ({ renderedCellValue, row }) => {
                        const status = JSON.parse(row?.original?.documentDetails);
                        const st = status?.[1]?.status ? status[1]?.status : 'N/A';
    
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    paddingLeft: '1rem',
                                }}
                            >
                                <Typography sx={{
                                    color: st == "Completed" ? 'green' : (st?.split(' ')[1] == 'in' ? "#143983" : (st?.split(' ')[1] == 'by' ? "#ffce23" : '#f14573')),
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                }}>
                                    {st}
                                </Typography>
                            </Box>
                        )
                    },
                },
            ],
        },
        {
            id: 'passportRenewal',
            header: 'Passport Renewal',
            columns: [
                {
                    accessorKey: 'documentDetails',
                    id: 'passportRenewalDate',
                    header: 'Date',
                    filterFn: 'dateFilter',
                    sortingFn: 'datetime',

                    Cell: ({ cell }) => {
                        const expiryDate = JSON.parse(cell?.getValue())?.[0]?.expiryDate;
                        if (expiryDate) {
                            const date = new Date(expiryDate);
                            return date.toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            });
                        } else {
                            return 'N/A';
                        }                    },
                    Header: ({ column }) => <em>{column.columnDef.header}</em>,
                },
                {
                    accessorKey: 'documentDetails',
                    id: 'passportRenewalStatus',
                    header: 'Status',
                    filterFn: 'contains',
                    Cell: ({ renderedCellValue, row }) => {
                        const status = JSON.parse(row?.original?.documentDetails);
                        const st = status?.[0]?.status ? status[0]?.status : 'N/A';
    
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    paddingLeft: '1rem',
                                }}
                            >
                                <Typography sx={{
                                    color: st == "Completed" ? 'green' : 'red',
                                    fontWeight: 'bold'
                                }}>
                                    {st}
                                </Typography>
                            </Box>
                        )
                    },
                },
            ],
        },
        {
            id: 'idRenewal',
            header: 'ID/RC Renewal',
            columns: [
                {
                    accessorKey: 'documentDetails',
                    id: 'idRenewalDate',
                    header: 'Date',
                    filterFn: 'dateFilter',
                    sortingFn: 'datetime',
                    Cell: ({ cell }) => {
                        const expiryDate = JSON.parse(cell?.getValue())?.[2]?.expiryDate;
                        if (expiryDate) {
                            const date = new Date(expiryDate);
                            return date.toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            });
                        } else {
                            return 'N/A';
                        } 
                    },
                    Header: ({ column }) => <em>{column.columnDef.header}</em>,
                },
                {
                    accessorKey: 'documentDetails',
                    id: 'idRenewalStatus',
                    header: 'Status',
                    filterFn: 'contains',
                    Cell: ({ renderedCellValue, row }) => {
                        const status = JSON.parse(row?.original?.documentDetails);
                        const st = status?.[2]?.status ? status[2]?.status : 'N/A';
    
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    paddingLeft: '1rem',
                                }}
                            >
                                <Typography sx={{
                                    color: st == "Completed" ? 'green' : 'red',
                                    fontWeight: 'bold'
                                }}>
                                    {st}
                                </Typography>
                            </Box>
                        )
                    },
                },
            ],
        },
        {
            id: 'contractRenewal',
            header: 'Contract Renewal',
            columns: [
                {
                    accessorKey: 'documentDetails',
                    id: 'contractRenewalDate',
                    header: 'Date',
                    filterFn: 'dateFilter',
                    sortingFn: 'datetime',
                    Cell: ({ cell }) => {
                        const expiryDate = JSON.parse(cell?.getValue())?.[3]?.expiryDate;
                        if (expiryDate) {
                            const date = new Date(expiryDate);
                            return date.toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            });
                        } else {
                            return 'N/A';
                        }                     },
                    Header: ({ column }) => <em>{column.columnDef.header}</em>,
                },
                {
                    accessorKey: 'documentDetails',
                    id: 'contractRenewalStatus',
                    header: 'Status',
                    filterFn: 'contains',
                    Cell: ({ renderedCellValue, row }) => {
                        const status = JSON.parse(row?.original?.documentDetails);
                        const st = status?.[3]?.status ? status[3]?.status : 'N/A';
    
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    paddingLeft: '1rem',
                                }}
                            >
                                <Typography sx={{
                                    color: st == "Completed" ? 'green' : 'red',
                                    fontWeight: 'bold'
                                }}>
                                    {st}
                                </Typography>
                            </Box>
                        )
                    },
                },
            ],
        },
    ], []);
    

    const exportToExcel = () => {
        const headers = columns.map(column => column.header); // Extract headers
        const data = tableData.map(row => {
            const employeeName = row.employeeName;

            const documentDetails = JSON.parse(row.documentDetails);

            const passport = documentDetails && documentDetails[0] ? {
                passportExpiryDate: documentDetails[0]?.expiryDate || '',
                passportStatus: documentDetails[0]?.status || '',
            } : {
                passportExpiryDate: '',
                passportStatus: '',
            };

            const visaRc = documentDetails && documentDetails[1] ? {
                visaRcExpiryDate: documentDetails[1]?.expiryDate || '',
                visaRcStatus: documentDetails[1]?.status || '',
            } : {
                visaRcExpiryDate: '',
                visaRcStatus: '',
            };

            const idRc = documentDetails && documentDetails[2] ? {
                idRcExpiryDate: documentDetails[2]?.expiryDate || '',
                idRcStatus: documentDetails[2]?.status || '',
            } : {
                idRcExpiryDate: '',
                idRcStatus: '',
            };

            const contract = documentDetails && documentDetails[3] ? {
                contractExpiryDate: documentDetails[3]?.expiryDate || '',
                contractStatus: documentDetails[3]?.status || '',
            } : {
                contractExpiryDate: '',
                contractStatus: '',
            };

            return {
                employeeName,
                passportExpiryDate: passport.passportExpiryDate,
                passportStatus: passport.passportStatus,
                visaRcExpiryDate: visaRc.visaRcExpiryDate,
                visaRcStatus: visaRc.visaRcStatus,
                idRcExpiryDate: idRc.idRcExpiryDate,
                idRcStatus: idRc.idRcStatus,
                contractExpiryDate: contract.contractExpiryDate,
                contractStatus: contract.contractStatus,
            };
        });

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Document Renewal Status');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'DocumentRenewalStatus.xlsx');
    };


    return (
        <>
            <h5 className="text-black-50 mb-10">
                <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>
                Employee Document Renewal Status
            </h5>
            <div className={`card`}>
                <div className='card-header border-0 pt-5 pb-2'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='text-muted mt-1 fw-semibold fs-7'>[{data.length} entries]</span>
                    </h3>

                    {/* <div className='card-toolbar'>
                        <a href='/' className='btn btn-sm btn-light-primary'>
                            <KTIcon iconName='plus' className='fs-2' />
                            New Entry
                        </a>
                    </div> */}
                    <div className='card-toolbar'>
                        <a onClick={exportToExcel} className='btn btn-sm btn-primary'>
                            <KTIcon iconName='file-down' className='fs-2' />
                            Export to Excel
                        </a>
                    </div>
                </div>
                <div className='col-sm-3 ms-8'>
                    <h2 className='mb-5' style={{ fontSize: "18px" }}>Enter the employee name</h2>
                    <Autocomplete
                        id='name'
                        options={insuredStaffs}
                        getOptionLabel={(option) => option?.Name}
                        getOptionSelected={(option, value) => option?.employeeName === value?.employeeName}
                        onChange={(event, value) => {
                            if (value === null) {
                                // Perform action when selection is removed
                                console.log("Selection cleared");
                                setEmployeName('');
                            } else {
                                // Handle selected value
                                setEmployeName(value?.employeeName);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                focused
                                value={employeName}
                                onChange={(event) => setEmployeName(event.target.value)}
                                label="employeeName"
                            />
                        )}
                    />

                </div>
                <div className='card-body'>
                    <MaterialReactTable
                        columns={columns}
                        data={tableData}
                        enableDensityToggle={false}
                        enableColumnFilters
                        enableColumnOrdering
                        initialState={{ showColumnFilters: false }}
                        positionToolbarAlertBanner="bottom"
                        renderRowActionMenuItems={({ closeMenu }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {
                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                Edit Profile
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {
                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <Send />
                                </ListItemIcon>
                                Delete Profile
                            </MenuItem>,
                        ]}
                        filterFns={{
                            dateFilter: dateFilter,
                            multipleFilter: multipleFilter
                        }}
                        muiTablePaperProps={{
                            sx: {
                                backgroundColor: 'var(--bs-card-bg)',
                                border: 'none',
                                boxShadow: 'none',
                            },
                        }}
                        muiTableProps={{
                            className: 'table',
                            sx: {
                                borderCollapse: 'separate',
                                borderSpacing: '0px 2px',
                            },
                        }}
                        muiTableContainerProps={{
                            className: 'mb-2 p-5',
                            sx: {
                                backgroundColor: 'var(--bs-light)',
                                borderRadius: 'var(--bs-card-border-radius)',
                            },
                        }}
                        muiTableHeadRowProps={{
                            className: 'fw-bold bg-light',
                            sx: {
                                boxShadow: 'none',
                            },
                        }}
                        muiTableHeadCellProps={{
                            className: 'px-7 py-3 text-black',
                            sx: {
                                verticalAlign: 'middle',
                                backgroundColor: '#B7CAF5 !important',
                                outline: '6px',
                                outlineStyle: 'solid',
                                outlineColor: 'var(--bs-light)',
                                outlineOffset: '-5px',
                                borderRadius: '20px',
                            },
                        }}
                        muiTableBodyRowProps={{
                            hover: false,
                            sx: {
                                backgroundColor: 'transparent',
                            },
                        }}
                        muiTableBodyCellProps={{
                            className: 'bg-light bg-hover-secondary',
                        }}
                        muiTopToolbarProps={{
                            className: 'card text-light mb-2',
                            sx: {
                                boxShadow: 'none',
                                backgroundColor: '#143983',
                            },
                        }}
                        muiBottomToolbarProps={{
                            className: 'text-light mb-5 d-flex',
                            sx: {
                                boxShadow: 'none',
                                backgroundColor: '#143983',
                                borderRadius: 'var(--bs-card-border-radius)',
                                minHeight: '50px',
                            },
                        }}
                        muiSearchTextFieldProps={{
                            className: 'bg-light text-light text-muted',
                        }}
                        muiTableHeadCellFilterTextFieldProps={{
                            helperText: false,
                            focused: true,
                            inputProps: {
                                className: 'text-black',
                            },
                            sx: {
                                color: 'black',
                            },
                        }}
                        muiTableHeadCellColumnActionsButtonProps={{
                            sx: {
                                color: 'black',
                            },
                        }}
                        muiTableHeadCellDragHandleProps={{
                            sx: {
                                color: 'black',
                            },
                        }}
                        icons={{
                            FullscreenIcon: () => <KTIcon iconName='maximize' className='fs-2 text-white' />,
                            FullscreenExitIcon: () => <KTIcon iconName='cross-circle' className='fs-2 text-white' />,
                            ViewColumnIcon: () => <KTIcon iconName='status' className='fs-2 text-white' />,
                            FilterListIcon: () => <KTIcon iconName='filter-search' className='fs-2 text-white' />,
                            FilterListOffIcon: () => <KTIcon iconName='filter-search' className='fs-2 text-white' />,
                            SearchIcon: () => <KTIcon iconName='search-list' className='fs-2 text-white' />,
                            CloseIcon: () => <KTIcon iconName='cross' className='fs-2 text-black' />,
                        }}
                        muiTablePaginationProps={{
                            className: 'text-white',
                        }}
                        enableGlobalFilter={false}
                        muiToolbarAlertBannerProps={{
                            className: 'text-white',
                            sx: {
                                backgroundColor: '#143983',
                            },
                        }}
                        muiToolbarAlertBannerChipProps={{
                            className: 'text-white',
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default RenewalStatusTable;