import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { TextField, Autocomplete } from '@mui/material';
import { addDays } from 'date-fns';
import { CardsWidget21 } from '../../../../_metronic/partials/widgets';
import Calendar from '../../../components/Calendar/MonthView';
import Data from './data/data.json';
import useFetchDataByYear from '../../../hooks/getDataByYear';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import { BASE_URL } from '../../../../utils';
import Cookies from 'js-cookie';
import useFetchData from '../../../hooks/getData';
import useFetchDataByCheck from '../../../hooks/getDataByCheck';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';

const data = Data;

const LeaveTrackerPage = () => {
    const presentYear = new Date().getFullYear();
    const [year, setYear] = useState(presentYear);
    const [employeeLeaveDetails, setEmployeeLeaveDetails] = useState([]);
    const [type, setType] = useState("All");
    const col1 = useRef(null);
    const col2 = useRef(null);
    const col3 = useRef(null);
    const col4 = useRef(null);
    const [days, setDays] = useState({
        Sick: 0,
        Marriage: 0,
        Maternity: 0,
        Annual: 0,
        Compassionate: 0,
        Unpaid: 0,
        Hajj: 0,
        Others: 0
    });

    const currentYear = new Date().getFullYear();
    const lastFiveYears = Array.from({ length: 6 }, (_, index) => currentYear - index);

    const yearOptions = useMemo(() => lastFiveYears, []);

    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    useEffect(() => {
        const listofLeaveDetails = [];
        const noOfDays = {
            Sick: 0,
            Marriage: 0,
            Maternity: 0,
            Annual: 0,
            Compassionate: 0,
            Unpaid: 0,
            Hajj: 0,
            Others: 0
        };
        for (let item of data) {
            if (item.name === 'employee1') {
                const startDate = new Date(item.startDate);
                const endDate = new Date(item.endDate);
                let date = startDate;
                if (startDate.getFullYear() === year || endDate.getFullYear() === year) {
                    while (date < endDate) {
                        if (date.getFullYear() === year) {
                            const ld = { date: date, type: item.type };
                            listofLeaveDetails.push(ld);
                            noOfDays[item.type] = noOfDays[item.type] + 1;
                        }
                        date = addDays(date, 1);
                    }
                }
            }
        }
        setDays(noOfDays);
        setEmployeeLeaveDetails(listofLeaveDetails);
    }, [year]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                col1.current && !col1.current.contains(event.target) &&
                col2.current && !col2.current.contains(event.target) &&
                col3.current && !col3.current.contains(event.target) &&
                col4.current && !col4.current.contains(event.target)
            ) {
                setType("All");
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const needRefetch = () => {
        setShouldRefetch(prevState => !prevState)
    }

    const id = Cookies.get('userId')

    const { fetchedData, refetch } = useGetDataWithFullUrl(`${BASE_URL}getLeaveTrackerDetails&userId=${id}&year=${year}`, [year])

    const detailedArray = []

    fetchedData?.map((data, index) => { detailedArray.push(data?.leaveDetails) })

    const [clickedItem, setClickedItem] = useState()

    const filteredData = clickedItem
        ? fetchedData?.filter((data) => clickedItem === data?.policytitle)
        : fetchedData;

    const A = fetchedData ? fetchedData[0]?.leaveDetails : ''

    const resultArray = filteredData?.map((data, index) =>
        JSON.parse(data?.leaveDetails)?.map((data, index) => data)
    )

    let obj = {}
    let arr = []

    const personalDetails = useFetchDataByCheck('getEmployeeDetails')?.fetchedData

    const empName = personalDetails?.result1 ? personalDetails?.result1[0] : ''

    const colours = ["#34a8cb", "#6255a3", "#143983", "#6255a3", "#143983", "#6255a3", "#143983", "#6255a3", "#34a8cb", "#6255a3"]

    resultArray?.map((data) => {

        data?.map((data, index) => {
            const endDate = new Date(data?.ReturnDate);
            endDate.setDate(endDate.getDate() - 1);
            const updatedObj = {
                ...obj, startedDate: data?.startDate,
                ReturnDate: endDate.toLocaleDateString('en-CA')

            };
            arr.push(updatedObj)
            return updatedObj;
        })

    });

    const downloadReport = async () => {
        const input = document.getElementById('calendarReport');
        const canvas = await html2canvas(input, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const pdf = new jsPDF({
            orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
            unit: 'pt',
            format: [imgWidth, imgHeight]
        });
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save(`Calendar_Report_${new Date().getFullYear()}.pdf`);
    };
    

    return (
        <>
            <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>  Employee Leave Tracker</h5>

            <div className='row g-5 g-xl-8 mb-md-5 mb-xl-5 d-flex justify-content-evenly'>
                <div className='col-md-5 col-xl-5 me-13'>
                    <div className='card h-md-auto mb-8'>
                        <div className='card-header'>
                            <h2 className='p-3 mt-5 text-primary'><i className="bi bi-person-fill fs-1 me-3 svg-icon-muted text-primary"></i> {empName?.firstName} {empName?.lastName}</h2>
                        </div>
                        <div className='card-body'>
                            <Autocomplete
                                disablePortal
                                fullWidth
                                id='yearField'
                                color="primary"
                                options={yearOptions}
                                getOptionLabel={(option) => option.toString()}
                                onInputChange={(event, value) => {
                                    setYear(parseInt(value ?? presentYear.toString()))
                                    needRefetch()
                                    setClickedItem('');
                                }}
                                onChange={(event, value) => {
                                    setYear(parseInt(value ?? presentYear.toString()))
                                    needRefetch()
                                    setClickedItem('');

                                }}
                                classes={{
                                    input: 'text-primary'
                                }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Year"
                                    />
                                }
                            />
                            <div className='mt-6'>
                                <span className="badge badge-primary fs-7 p-2 mb-5 me-5"><i className="bi bi-airplane-fill fs-2 svg-icon-muted text-white me-5"></i>Tickets Compensated: 1</span>
                                <span className="badge badge-primary fs-7 p-2"><i className="bi bi-cash-coin fs-2 svg-icon-muted text-white me-5"></i> Amount Compensation: 150 OMR</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-xl-6 me-13'>
                    <div className='row col-md-12 g-6 g-xl-30 mb-5 mb-xl-2 justify-content-md-center'>
                        {fetchedData?.map((data, index) => {

                            return (
                                <div key={data?.policytitle} onClick={() => setClickedItem(data?.policytitle)} className='col-md-4 col-sm-6 mb-md-2 bg-red-400'>
                                    <CardsWidget21
                                        className='h-md-0'
                                        color={colours[index]}
                                        img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                                        title={data?.policytitle}
                                        description={days.Sick.toString()}
                                        onClick={() => setType("Sick")}
                                        availablecount={data?.availablecount}
                                        totalcount={data?.totalcount}
                                    />
                                </div>)
                        })}
                    </div>
                </div>

                <div className='card'>
                    <div className='row g-5 g-xl-8 mx-5 mx-xl-10' id="calendarReport">
                        {months.map((value) => (
                            <div className='col-md-6 col-xl-4' key={value}>
                                <Calendar
                                    className='m-3'
                                    year={year > 2000 && year < 2100 ? year : presentYear}
                                    month={value}
                                    type={type}
                                    employeeLeaveDetails={employeeLeaveDetails}
                                    dateDetails={arr}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <button onClick={downloadReport} className="btn btn-primary mt-4">Download Calendar Report as PDF</button>
        </>
    );
};

const EmployeeLeaveTracker = () => {
    const intl = useIntl();
    return (
        <>
            <LeaveTrackerPage />
        </>
    );
};

export { EmployeeLeaveTracker };
