import React, { useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useFetchData from '../../hooks/getData';
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import Cookies from 'js-cookie';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import { message } from "antd";
import moment from 'moment';


const validationSchema = Yup.object().shape({
    otherAllowance: Yup.string().required('Allowance amount is required'),
    overTimeSalary: Yup.string().required('Over time amount is required'),
    appraisal: Yup.string().required('Appraisal amount is required'),
    deductionAmount: Yup.string().required('Deduction amount amount is required'),
    incentiveAmount: Yup.string().required('Incentive amount is required'),
    comments:Yup.string().required('comment is required')
})

export default function PayRollEditForm({ selectedData,month }) {
     console.log("selected data",selectedData)
    selectedData =selectedData?.length > 0 ? selectedData[0] :''
    const [bankAccount, setBankAccount] = useState()

    const formattedDate = moment(selectedData?.manPowerEndDtae, "M/D/YYYY").format("YYYY-MM-DD");


    const initialValues = {
        otherAllowance: selectedData ? selectedData?.otherAllowance : '',
        overTimeSalary: selectedData ? selectedData?.overtimeSalary : '',
        appraisal: selectedData ? selectedData?.appraisal : '',
        deductionAmount: selectedData ? selectedData?.deductionAmount : '',
        incentiveAmount: selectedData ? selectedData?.incentiveAmount : '',
        comments:selectedData?selectedData?.comments:""
    }

    const showMessage = () => {
        message.success({
            content: 'Successfully added new payroll',
            duration: 1
        });
    };

    const employeList = useFetchData('getEmployeesList').fetchedData;

    const [employeId, setEmployeName] = useState()

    const [shouldRefetch, setShouldRefetch] = useState(false);

    console.log("employeId", employeId);

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };


    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            axios.post(`${BASE_URL_TWO}`, {
                sp: 'updEmployeePayRoll',
                empPayRollId: selectedData?.payrollId,
                userId: id,
                month:month,
                otherAllowance: values?.otherAllowance,
                overTimeSalary: values?.overTimeSalary,
                appraisal: values?.appraisal,
                deductionAmount: values?.deductionAmount,
                incentiveAmount: values?.incentiveAmount,
                comments:values?.comments,
                employeeId:selectedData?.employeeId
            }, { headers }).then(() => {
                showMessage()
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            })

        },
        enableReinitialize: true
    });

    return (
        <div className="modal fade" id="kt_modal_3" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">


            <form action="" onSubmit={formik.handleSubmit}>

                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Employee Payroll Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-3">

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Other Allowance</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="otherAllowance"
                                        autoComplete='off'
                                        value={formik.values.otherAllowance}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.otherAllowance}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Overtime Salary</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="overTimeSalary"
                                        autoComplete='off'
                                        value={formik.values.overTimeSalary}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.overTimeSalary}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Appraisal</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="appraisal"
                                        autoComplete='off'
                                        value={formik.values.appraisal}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.appraisal}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Deduction Amount</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="deductionAmount"
                                        autoComplete='off'
                                        value={formik.values.deductionAmount}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.deductionAmount}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Incentive Amount</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="incentiveAmount"
                                        autoComplete='off'
                                        value={formik.values.incentiveAmount}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.incentiveAmount}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-8 text-light-emphasi">Comments</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="comments"
                                        autoComplete='off'
                                        value={formik.values.comments}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.comments}</span>
                                </div>


                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Add
                            </button>
                        </div>
                    </div>
                </div>

            </form>

        </div>
    )
}
