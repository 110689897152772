import React, { FC, useState } from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { BASE_URL, BASE_URL_TWO } from '../../../../../../utils';
import Cookies from 'js-cookie';
import { message } from 'antd';
import axios from 'axios';

// interface StepProps {
//   setSteps: (step: string) => void;
//   // Define other props if needed
// }

const Step5 = (props) => {

  const { setSteps, employeDet, personalDetails, corporateDetails, jobDescriptionBenifits, familyDetails,status } = props
  const id = Cookies.get('userId');
  const token = Cookies.get("token")
  const [deactivateStatus, setDeactivateStatus] = useState(status);

  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };
  const postData = {
    personal: personalDetails,
    corporateDetails: corporateDetails,
    jobDescriptionBenifits: jobDescriptionBenifits,
    familyDetails: JSON.parse(localStorage.getItem('familyMembers2')),
    status:deactivateStatus
  };

  const showMessage = () => {
    message.success({
      content: 'successfully updated user',
      duration: 4 // Duration in seconds
    });
  };

  const handleSubmit =()=>{
    axios.post(`${BASE_URL_TWO}`,{
      sp:"updEmployee",
      userId:id,
      jsonData: postData
    },{headers}).then((response)=>{
      localStorage.removeItem('jobDescription2')
      localStorage.removeItem('personalDetails2')
      localStorage.removeItem('corparateDetails2')
      localStorage.removeItem('familyMembers2')
      localStorage.removeItem('memberCount2')
      setTimeout(()=>{
        window.location.reload()
      },1000)
      showMessage()
    })
  }

  return (
    <div className='w-100'>
      <div className='mb-0'>
        <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-5'>
          <KTIcon iconName='information-5' className='fs-1 text-danger me-4' />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-danger fw-bolder fs-6'>You Are Deactivating This Account</h4>
              <div className='fs-8 text-gray-600'>
                Username ({employeDet?.userName}), Employee Full Name ({employeDet?.firstName} {employeDet?.lastName}), Employee ID (0{employeDet?.employeeId}).
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-check form-switch form-check-custom form-check-solid me-10 mt-10">
        <input className="form-check-input h-30px w-50px" type="checkbox" value="" id="flexSwitch40x60" 
        checked={deactivateStatus}
        onChange={(e) => setDeactivateStatus(e.target.checked)} />
        <label className="form-check-label fw-bold text-primary" htmlFor="flexSwitch40x60">
          Deactivate Account
        </label>
      </div>

      <Grid container sx={{ bgcolor: '', justifyContent: 'space-between', mt: 4 }}>

        <div className='mr-2'>
          <button
            // onClick={prevStep}
            onClick={() => setSteps('step4')}
            type='button'
            className='btn btn-lg btn-light-primary me-3'
          // data-kt-stepper-action='previous'
          >
            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
            Back
          </button>
        </div>

        <div>
          <button type='submit' onClick={() => handleSubmit()} className='btn btn-lg btn-primary'>
            <span className='indicator-label'>
              Save All
              <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
            </span>
          </button>
        </div>

      </Grid>


    </div>
  )
}

export { Step5 }
