import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../../helpers';
import { IconButton } from '@mui/material';
import { DocumentScanner, Delete } from '@mui/icons-material';
import DocumentDigitalModal from '../../../../app/components/EmployeeDocumentRenewalTable/DocumentDigitalModal';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import axios from 'axios';
import { message } from 'antd';
import Cookies from 'js-cookie';
import PopUp from '../../../../app/components/Modal/Modal';

const ListsWidget10 = ({ className, details, setShouldRefetch }) => {

  const [show, setShow] = useState();
  const [actionStatus, setActionStatus] = useState(false);
  const [deletId, setDeletId] = useState()


  const id = Cookies.get('userId');
  const token = Cookies.get('token');
  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };
  const documentDetails = JSON.parse(details.documentDetails);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (id) => {
    setSelectedCompanyId(id);
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const showMessage = () => {
    message.success({
      content: 'Document Deleted Successfully',
      duration: 1 // Duration in seconds
    });
  };

  useEffect(()=>{
    if(actionStatus && deletId){
      axios.post(`${BASE_URL}`, {
        "sp": "delRenewalDocument",
        "documentId": deletId,
        "userId": parseInt(id)
      }, { headers }).then(response => {
        setShouldRefetch(prevState => !prevState);
        showMessage();
      });
    }
  },[actionStatus,deletId])

  const handleDelete = (documentId) => {
    setShow(true)
    setDeletId(documentId)
  };

  return (
    <>
      <PopUp
        show={show}
        setShow={setShow}
        setActionStatus={setActionStatus}
        title="Delete The Document"
        message="Are you sure you want to delete"
        btnName="Delete"
      />
      <div className={`card ${className}`}>
        <div className='card-header border-0'>
          <h3 className='card-title fw-bold text-primary'>{details?.companyName}</h3>
          <div className='card-toolbar'>
            <button
              onClick={() => handleOpenModal(details.companyId)}
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-primary me-2'
            >
              <KTIcon iconName='plus' className='fs-2' />
            </button>
            {isModalOpen && selectedCompanyId === details.companyId && (
              <DocumentDigitalModal
                companyId={selectedCompanyId}
                onClose={handleCloseModal}
                setShouldRefetch={setShouldRefetch}// Pass a close function to the modal
              />
            )}

          </div>
        </div>
        <div className='card-body pt-2'>
          {documentDetails?.map((item) => {
            return (
              <div key={item.documentId} className='d-flex align-items-center mb-8'>
                <span className='bullet bullet-vertical h-40px bg-primary'></span>
                <div className='flex-grow-1 mx-3'>
                  <span className='text-gray-800 text-hover-primary fw-bold fs-6'>
                    {item?.docTitle}
                  </span>
                  <span className='datafield text-muted fw-semibold d-block' >{item?.expiryDate}</span>
                </div>
                <div className='d-flex justify-content-between' style={{ flex: '0 0 35%' }}>
                  {item.status.includes('Overdue') && <div style={{ width: '40%', fontWeight: 'bold', lineHeight: 1, backgroundColor: "#f3e8ea", color:"red", height: 'fit-content',padding:"3px", borderRadius:"6px" }}>
                    {item.status}
                  </div>}
                  {!item.status.includes('Overdue') && <div style={{ width: '40%', fontWeight: 'bold', lineHeight: 1, backgroundColor: "#fff", height: 'fit-content',padding:"3px", borderRadius:"6px" }}>
                    {item.status}
                  </div>}
                  <div>
                    <a href={`${BASE_URL_TWO}${item?.filePath}`} target='_blank'>
                      <IconButton className='text-primary'>
                        <DocumentScanner />
                      </IconButton>
                    </a>
                    <IconButton className='text-primary' onClick={() => handleDelete(item?.documentId)}>
                      <Delete />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export { ListsWidget10 };
