import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { message } from 'antd';
import Cookies from 'js-cookie';
import { BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current Password is required'),
  newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('New Password is required'),
  confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm Password is required')
});

const ChangePassword = () => {

  const [submitting, setSubmitting] = useState(false);

    const showMessage = (content) => {
        message.success({
            content: content,
            duration: 2
        });
    };

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting,resetForm }) => {
            setSubmitting(true);
            const token = Cookies.get("token");

            const userId = Cookies.get("userId");

            axios.post(`${BASE_URL_TWO}`, {
              "currentPassword": values.currentPassword,
              "newPassword": values.newPassword,
              "sp": "spChangePassword",
              "userId": userId,
            }, {
                headers: {
                    'token': `${token}`,
                    'Content-Type': 'application/json'
                }
            }).then(() => {
                showMessage('Password changed successfully');
                resetForm();
            }).catch(error => {
                console.error('Error occurred during POST request:', error);
                setStatus({ success: false });
                message.error(error?.response?.data?.Message ? error?.response?.data?.Message : 'Failed to change password');
            }).finally(() => {
                setSubmitting(false);
            });
        },
    });

    return (
      <>
      <h5 className="text-black-50 mb-10">
          <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>
          Change Password
      </h5>
      <div className="container-fluid mt-5">
          <div className="d-flex justify-content-center">
              <div className='card mb-5 mb-xl-10 w-75'>
                  <div
                      className='card-header border-0 cursor-pointer bg-primary'
                      role='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#kt_account_profile_details'
                      aria-expanded='true'
                      aria-controls='kt_account_profile_details'
                  >
                      <div className='card-title m-0'>
                          <h3 className='fw-bolder m-0 text-white'>Change Password</h3>
                      </div>
                  </div>
                  <div id='kt_account_profile_details' className='collapse show'>
                      <form onSubmit={formik.handleSubmit} className='form'>
                          <div className='card-body border-top p-9 text-primary'>
                              <div className='row mb-6'>
                                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                      <span className='required text-primary'>
                                          <i className="bi bi-key-fill text-primary me-3 fs-3"></i>Current Password
                                      </span>
                                  </label>
                                  <div className='col-lg-8'>
                                      <input
                                          id='currentPassword'
                                          name='currentPassword'
                                          type='password'
                                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary'
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.currentPassword}
                                      />
                                      {formik.touched.currentPassword && formik.errors.currentPassword && (
                                          <div className="text-danger fw-bold fs-8">{formik.errors.currentPassword}</div>
                                      )}
                                  </div>
                              </div>

                              <div className='row mb-6'>
                                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                      <span className='required text-primary'>
                                          <i className="bi bi-lock-fill text-primary me-3 fs-3"></i>New Password
                                      </span>
                                  </label>
                                  <div className='col-lg-8'>
                                      <input
                                          id='newPassword'
                                          name='newPassword'
                                          type='password'
                                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary'
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.newPassword}
                                      />
                                      {formik.touched.newPassword && formik.errors.newPassword && (
                                          <div className="text-danger fw-bold fs-8">{formik.errors.newPassword}</div>
                                      )}
                                  </div>
                              </div>

                              <div className='row mb-6'>
                                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                      <span className='required text-primary'>
                                          <i className="bi bi-lock-fill text-primary me-3 fs-3"></i>Confirm New Password
                                      </span>
                                  </label>
                                  <div className='col-lg-8'>
                                      <input
                                          id='confirmPassword'
                                          name='confirmPassword'
                                          type='password'
                                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary'
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.confirmPassword}
                                      />
                                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                          <div className="text-danger fw-bold fs-8">{formik.errors.confirmPassword}</div>
                                      )}
                                  </div>
                              </div>
                          </div>

                          <div className='card-footer d-flex py-6 px-9 justify-content-end'>
                              <button type="submit" className="btn btn-primary" disabled={submitting}>
                                  {submitting ? 'Changing...' : 'Change Password'}
                              </button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </>
    );
};

export { ChangePassword };
