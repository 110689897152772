import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { BankGuarantee } from '../../pages/EmployerPages/Organisation/BankGaurentee';
import { useFormik } from 'formik';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import * as Yup from 'yup'
import { Button, message } from "antd";

import moment from 'moment';

const validationSchema = Yup.object().shape({

    clientName: Yup.string().required('Customer name required'),
    description: Yup.string().required('Description required'),
    startDate: Yup.string().required('Start date is required'),
    endDate: Yup.string()
        .required('End date is required')
        .test('endDate', 'End date must be after the start date', function (value) {
            const { startDate } = this.parent;
            return !startDate || !value || new Date(value) > new Date(startDate);
        }),
    amount: Yup.string().required('Amount is required'),
    status: Yup.string().required('Select status'),
    category:Yup.string().required('Category is required')
})


export default function AmcEditForm({ onAdd, selectedData }) {

    const handleAddClick = () => { if (onAdd) { onAdd(); } };

    const [status, setStatus] = useState()

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'successfully Updated',
            duration: 1 // Duration in seconds
        });
    };

    
const [category,setCategory] = useState();
const [categories,setCategories] = useState([])

useEffect(()=>{
    axios.get(`${BASE_URL}getProductCategory`).then((response)=>{
        setCategories(response?.data?.Data)
    })
},[])

    const start = moment(selectedData?.startDate, "M/D/YYYY").format("YYYY-MM-DD");
    const end = moment(selectedData?.endDate, "M/D/YYYY").format("YYYY-MM-DD");

    const initialValues = {
        clientName: selectedData ? selectedData.customerName : '',
        description: selectedData ? selectedData.serviceDescription:"",
        startDate: start,
        endDate: end,
        amount: selectedData ? selectedData.amount : '',
        status: selectedData ? selectedData.status : '',
        category:selectedData?selectedData.categoryId:""
    }


    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            axios.post(`${BASE_URL_TWO}`, {
                userId: parseInt(id),
                sp: 'updProductsOrServices',
                productServiceId:selectedData?.productServiceId,
                customerName: values?.clientName,
                serviceDescription: values?.description,
                startDtae: values?.startDate,
                endDate: values?.endDate,
                amount: values?.amount,
                status: status || selectedData?.status,
                category:parseInt(category) || selectedData?.categoryId
            }, { headers }).then(() => {
                showMessage();
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            })

        },
        enableReinitialize: true
    });

    return (
        <div className="modal fade" id="kt_modal_3" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">

            <form action="" onSubmit={formik.handleSubmit}>

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Client Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='clientName'
                                        autoComplete='off'
                                        value={formik.values.clientName}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.clientName}</span>
                                </div>
                                
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Description</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='description'
                                        autoComplete='off'
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.description}</span>
                                </div>


                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Start Date</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='startDate'
                                        autoComplete='off'
                                        value={formik.values.startDate} // Update this line
                                        onChange={formik.handleChange}
                                        min={new Date().toLocaleDateString('en-CA')}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.startDate}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">End Date</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='endDate'
                                        autoComplete='off'
                                        value={formik.values.endDate}
                                        onChange={formik.handleChange}
                                        min={new Date().toLocaleDateString('en-CA')}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.endDate}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Amount</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='amount'
                                        autoComplete='off'
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.amount}</span>
                                </div>

                                <div className='col-md-6 fv-row'>
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Status</label>
                                    <select
                                        name="status"
                                        value={formik.values.status}
                                        onChange={(e) => {
                                            setStatus(e.target.value)
                                            formik.handleChange(e);
                                        }}
                                        className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'>
                                        <option>Select Status</option>
                                        <option value='under-Process'>Under Process</option>
                                        <option value='Active'>Active</option>
                                        <option value='Expired'>Expired</option>
                                        <option value='Completed'>Completed</option>
                                        <option value='Extended'>Extended</option>
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.status}</span>

                                </div>
                                <div className='col-md-6 fv-row'>
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Products Category</label>
                                    <select
                                        name="category"
                                        value={formik.values.category}

                                        onChange={(e) => {
                                            setCategory(e.target.value)
                                            formik.handleChange(e);
                                            formik.setFieldValue('category', e.target.value)
                                        }}
                                        className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'>
                                        <option>Select Category</option>
                                        {categories?.map((item)=>(
                                            <option value={item?.ProductCategoryId}>{item?.ProductCategoryName}</option>
                                        ))}
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.category}</span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Update
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )
}
