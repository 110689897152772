import React, { useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import useFetchData from '../../hooks/getData';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import { message } from "antd";


const validationSchema = Yup.object().shape({
    fineAmount: Yup.string().required('Fine amount is  required'),
    fineDate: Yup.string().required('Fine Date is required'),
    status: Yup.string().required('Status is required'),
})

const VehicleFineEdit = ({vehicleId}) => {

    const [fineAmount, setFineAmount] = useState()

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const employeList = useFetchData('getEmployeesList').fetchedData;

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'Added Car Fine ',
            duration: 1
        });
    };

    const initialValues = {
        fineAmount: "",
        fineDate: "",
        status : ""
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            axios.post(`${BASE_URL_TWO}`, {
                sp: 'updVehicleFineDetails',
                userId: id,
                vehicleId:vehicleId,
                fineAmount: values?.fineAmount,
                fineDate: values?.fineDate,
                status : values?.status
    
            }, { headers }).then(() => {
                showMessage()
                setShouldRefetch(prevState => !prevState)
                setTimeout(function () {
                    window.location.reload()
                }, 2000);
            })
        },
    });

    return (
        <div className="modal fade" id="kt_modal_7" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
            <form action="" onSubmit={formik.handleSubmit}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Car Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-3">
                                {/* Employee Name and Plate Number */}
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Fine Amount<span className='text-danger'>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='fineAmount'
                                        value={formik.values.fineAmount}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.fineAmount}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Fine Date<span className='text-danger'>*</span></label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='fineDate'
                                        value={formik.values.fineDate}
                                        onChange={formik.handleChange}
                                        min={`${new Date().getFullYear()}-01-01`}
                                        max={`${new Date().getFullYear()}-12-31`}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.fineDate}</span>
                                </div>

                                {/* Car Model and Year */}
                                <div className="col-md-6 fv-row">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Status</label>
                                    <select
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Paid">Paid</option>
                                        <option value="Pending">Pending</option>
                                    </select>
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.status}</span>
                                </div>

                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default VehicleFineEdit;