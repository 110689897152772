import React, { FC, useState } from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
// import { axios } from 'axios';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL, BASE_URL_TWO } from '../../../../../../utils';
import { Button, message } from "antd";
import * as Yup from 'yup';
import PopUp from '../../../../../components/Modal/Modal';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const checkEmailExists = async (email) => {
  const id = Cookies.get('userId');
  const token = Cookies.get("token")
  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.get(`${BASE_URL}spCheckEmailExists&userId=${id}&email=${email}`, { headers });
    return response?.data; // Assuming the API returns { exists: true/false }
  } catch (error) {

    return error?.response?.data;
  }
};


const validationSchema = Yup.object().shape({
  userName:Yup.string()
  .email('Username')
  .required('Required')
  .test('checkEmailExists', 'Username already exists', async function (value) {
    if (value) {
      const isEmailTaken = await checkEmailExists(value);
      console.log('checkEmailExists result:', isEmailTaken); // Debugging line

      if (isEmailTaken.Message !== "Success") {
        return false; // Email exists, trigger validation error
      }
      return true; // Email does not exist, pass validation
    }

    return true; // If no value is present, pass validation
  }),
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
});

const Step5 = (props) => {

  const { personalDetails, corporateDetails, jobDescriptionBenifits, familyDetails, setSteps } = props

  const [usp, setUsp] = useState({})


  const id = Cookies.get('userId');
  const token = Cookies.get("token")

  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };

  const initialValues = {

    userName: '',
    password: ''

  }

  const showMessage = () => {
    message.success({
      content: 'successfully created new user',
      duration: 4 // Duration in seconds
    });
  };

  const [show, setShow] = useState()
  const [actionStatus, setActionStatus] = useState()

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {

      setUsp(prevUsp => ({
        ...prevUsp,
        userName: values?.userName,
        password: values?.password
      }));

      setUsp((prevUsp) => {

        const postData = {
          personal: personalDetails,
          corporateDetails: corporateDetails,
          jobDescriptionBenifits: jobDescriptionBenifits,
          familyDetails: familyDetails,
          usp: prevUsp // Use the updated usp object here
        };

        axios.post(`${BASE_URL_TWO}`, {
          sp: "insEmployee",
          userId: 12,
          jsonData: postData
        }, { headers }).then(() => {
          setSteps('step1')
          showMessage()
          localStorage.removeItem("personalDetails")
          localStorage.removeItem("corparateDetails")
          localStorage.removeItem('familyMembers')
          localStorage.removeItem('jobDescription')
        })
      })


    }
  });

  return (
    <div className='w-100'>

      <PopUp
        title="Create new employee"
        message="Are you sure you want to create new employee"
        btnName="Create"
        setActionStatus={setActionStatus}
        show={show}
        setShow={setShow}
      />

      <div className='pb-10 pb-lg-15'>
        <h4 className='fw-bolder text-danger'>Create Employee Username & Password</h4>
      </div>

      <div className='mb-0'>
        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-4'>
          <KTIcon iconName='information-5' className='fs-1 text-warning me-4' />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder fs-6'>Note!</h4>
              <div className='fs-8 text-gray-600'>
                An email and a SMS message will be sent to the employee which contains his username and password.
              </div>
            </div>
          </div>
        </div>
      </div>

      <form action="" onSubmit={formik.handleSubmit}>

        <div className='row mb-5 mt-10'>
          <label className='col-lg-2 col-form-label fw-bold fs-8 text-light-emphasis'><i className="bi bi-person-fill fs-3 me-2"></i>Username</label>
          <div className='col-lg-3 fv-row'>
            <input
              type='email'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='userName'
              value={formik.values.userName}
              onChange={formik.handleChange}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.userName}</span>
          </div>

          <label className='col-lg-1'></label>

          <label className='col-lg-2 col-form-label fw-bold fs-8 text-light-emphasis'><i className="bi bi-lock-fill fs-3 me-2"></i>Password</label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='password'
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.password}</span>
          </div>
        </div>
        <Grid container justifyContent="space-between" sx={{ mt: 4, mb: 7 }}>

          <div className='mr-2'>

            <button
              onClick={() => setSteps('step4')}
              type='button'
              className='btn btn-lg btn-light-primary '
            >
              <KTIcon iconName='arrow-left' className='fs-4 me-1 mb-1' />
              Back
            </button>

          </div>

          <button type='submit' className='btn btn-lg btn-primary'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className='indicator-label'>
                Submit
              </span>
            </div>
          </button>
        </Grid>
      </form>

    </div>
  )
}

export { Step5 }

// submit arrow btn <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0 ' />
