import { FC, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import RenewalStatusTable from '../../../components/RenewalCompanyDocument/DocumentRenewalTable'
import { ListsWidget10 } from '../../../../_metronic/partials/widgets/lists/ListWidget10'
import { ListsWidget11 } from '../../../../_metronic/partials/widgets/lists/ListWidget11'
import { TablesWidget15 } from '../../../../_metronic/partials/widgets'
import Cookies from 'js-cookie'
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl'
import { BASE_URL } from '../../../../utils'


const RenewalStatusPage = () => {


  const id = Cookies.get('userId');
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getRenewalCompanyDocuments&userId=${id}`, [shouldRefetch]);


  return (
    <>
      <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>  {/* icon  */} Renewal Company Documents</h5>
      <div className='row g-5 g-xl-10 mb-4 mb-xl-10 d-flex flex-wrap align-content-stretch'>
        {
          fetchedData?.map((data) =>
            <div className="col-md-12 col-lg-6">
              <ListsWidget10 className='h-100 bg-primary-subtle' name='Tamimah Telecom' details={data} setShouldRefetch={setShouldRefetch}/>
            </div>
          )
        }

      </div>

      <div className='row g-5 g-xl-10 mb-md-4 mb-xl-10'>
        <div className='col-12'>
          <TablesWidget15 className='bg-body-secondary' data={fetchedData} />
        </div>
      </div>
    </>
  )
}

const DocumentRenewal: FC = () => {
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>Company Documents Renewal Notification</PageTitle> */}
      <RenewalStatusPage />
    </>
  )
}

export { DocumentRenewal }