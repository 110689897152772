
import { useMemo, FC, useState, useEffect } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';

import { Box, IconButton } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { data } from './makeData';

import { KTIcon } from '../../../_metronic/helpers'
import { Archive, Delete, Edit } from '@mui/icons-material';
import Cookies from 'js-cookie';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import axios from 'axios';
import AMCform from './AMCform';
import { Button, message } from "antd";
import PopUp from '../Modal/Modal';
import EditDepartment from '../../pages/SuperAdminPages/Department/EditDepartment';
import AmcEditForm from './AmcEditForm';

export const ProductsDetails = {
    customerName: '',
    serviceDesc: '',
    startDate: '',
    endDate: '',
    amount: '',
    category: '',
    status: '',
};

const ProductsServicesTable = () => {

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };


    const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getProductsOrServicesDetails&userId=${id}`, [shouldRefetch])
    const tableData = fetchedData || [];




    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.customerName}`, //accessorFn used to join multiple data into a single cell
                id: 'name',
                header: 'Client Name',
                size: 200,
                filterFn: 'multipleFilter',
                enablePinning: true,
                muiTableHeadCellProps: {
                    style: {
                        paddingLeft: '1rem'
                    }
                },
                muiTableBodyCellProps: {
                    style: {
                        paddingLeft: '1rem'
                    }
                }
            },
            {
                accessorKey: 'category',
                header: 'Product Category',
                enablePinning: false,
                size: 200,
            },
            {
                accessorKey: 'serviceDescription',
                header: 'Description',
                enablePinning: false,
                size: 250,
            },
            {
                accessorFn: (row) => new Date(row.startDate), //convert to Date for sorting and filtering
                id: 'StartDate',
                header: 'Start Date',
                filterFn: 'dateFilter',
                sortingFn: 'datetime',
                size: 200,
                enablePinning: false,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return date.toLocaleDateString('en-GB',{
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                }, //render Date as a string
                Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
            },
            {
                accessorFn: (row) => new Date(row.endDate), //convert to Date for sorting and filtering
                id: 'EndDate',
                header: 'End Date',
                filterFn: 'dateFilter',
                sortingFn: 'datetime',
                size: 200,
                enablePinning: false,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return date.toLocaleDateString('en-GB',{
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                }, //render Date as a string
                Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
            },
            {
                accessorKey: 'amount',
                filterFn: 'between',
                header: 'Amount',
                enablePinning: false,
                //custom conditional format and styling
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() < 50_000
                                    ? theme.palette.error.dark
                                    : cell.getValue() >= 50_000 &&
                                        cell.getValue() < 75_000
                                        ? theme.palette.warning.dark
                                        : theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()?.toLocaleString?.('en-US', {
                            style: 'currency',
                            currency: 'OMR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })}
                    </Box>
                ),
            },
            {
                accessorKey: 'status',
                header: 'Status',
                enablePinning: false,
                size: 200,
            },

        ], []);

    const showMessage = (msg) => {
        message.success({
            content: msg,
            duration: 1 // Duration in seconds
        });
    };

    const a = (msg) => {
        message.info({
            content: msg,
            duration: 1 // Duration in seconds
        });
    }

    const [show, setShow] = useState();
    const [actionStatus, setActionStatus] = useState();
    const [deletId, setDeletId] = useState()

    useEffect(() => {
        if (actionStatus && deletId) {
            axios.post(`${BASE_URL_TWO}`, {
                "sp": "delProductsOrServices",
                "userId": id,
                "productServiceId": deletId
            }, { headers })
                .then(() => {
                    showMessage("Deleted successfully");
                    setShouldRefetch(prevState => !prevState);
                    setActionStatus(false)
                })
                .catch(error => {
                    if (error.response) {
                        console.error("Server Error:", error.response.data);
                        a("Item not deleted");
                    } else if (error.request) {
                        console.error("Network Error:", error.request);
                        a("Item not deleted");
                    } else {
                        console.error("Error:", error.message);
                        a("Item not deleted");
                    }
                });
        }
    }, [deletId, actionStatus]);

    const exportToExcel = () => {
        const filteredData = fetchedData.map(({ productServiceId, categoryId, customerName, category, ...rest }) => ({
            customerName,
            category,
            ...rest,
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `amcreport.xlsx`);
    };    

    const [amcId, setAmcId] = useState()

    const filterData = fetchedData?.filter((data, index) => data?.productServiceId == amcId)

    const selectedData = filterData ? filterData[0] : []




    return (
        <div className={`card`}>

            <AmcEditForm selectedData={selectedData} />

            <PopUp
                show={show}
                setShow={setShow}
                setActionStatus={setActionStatus}
                title="Delete Department"
                message="Are you sure you want to delete"
                btnName="Delete"
            />

            {/* begin::Header */}
            <div className='card-header border-0 pt-5 pb-2'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>AMC Details Report</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>[{fetchedData?.length} entries]</span>
                </h3>
                <div className='card-toolbar'>

                    <div>
                        <div className="card-toolbar me-5">
                            {/* Button to open the modal */}
                            <a href="/"
                                className="btn btn-sm btn-light-primary border"
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                                title="New Entry"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_2">
                                <KTIcon iconName="plus" className="fs-2" />
                                New Entry
                            </a>
                        </div>

                        {/* Include the modal form */}
                        <AMCform onAdd={() => console.log('Add button clicked')} />
                    </div>
                    <div className='card-toolbar'>
                        <a onClick={exportToExcel} className='btn btn-sm btn-primary'>
                            <KTIcon iconName='file-down' className='fs-2' />
                            Export to Excel
                        </a>
                    </div>
                </div>
            </div>
            {/* end::Header */}

            <div className='card-body'>

                <MaterialReactTable
                    columns={columns}
                    data={tableData}
                    enableDensityToggle={false}
                    enableColumnFilters
                    enableColumnOrdering
                    enablePinning
                    enableRowActions
                    initialState={{
                        showColumnFilters: false,
                        // grouping: ["approval"], 
                        sorting: [
                            { id: "name", desc: false },
                        ],
                        expanded: true,
                        columnPinning: { left: ["name"] }
                    }}
                    positionToolbarAlertBanner="bottom"
                    positionActionsColumn='last'
                    renderRowActions={({ row, table }) => [
                        <Box sx={{ justifyContent: 'center' }}>


                            <a href="/"
                                onClick={() => setAmcId(fetchedData[row.index]?.productServiceId)}
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                                title="New Entry"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_3">
                                <IconButton
                                >
                                    <Edit className='text-success' />
                                </IconButton>
                            </a>

                            <IconButton
                                onClick={() => {
                                    setShow(true)
                                    setDeletId(fetchedData[row.index]?.productServiceId)

                                }}
                            >
                                <Delete className='text-danger' />
                            </IconButton>
                        </Box>
                    ]}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Approval Action',
                            size: 100,
                            muiTableHeadCellProps: {
                                className: 'px-7 py-4 text-black',
                                sx: {
                                    justifyContent: 'center',
                                    verticalAlign: 'middle',
                                    backgroundColor: '#EBEBD3 !important',
                                    outline: '6px',
                                    outlineStyle: 'solid',
                                    outlineColor: 'var(--bs-light)',
                                    outlineOffset: '-5px',
                                    borderRadius: '20px',
                                }
                            }
                        },
                    }}
                    sortingFns={{
                        status: (rowA, rowB, columnID) => {
                            const valA = rowA.getValue < String > (columnID)
                            const valB = rowB.getValue < String > (columnID)
                            if (valA === 'Pending' && valB !== 'Pending') {
                                return 1
                            }
                            else if (valB === 'Pending' && valA !== 'Pending') {
                                return -1
                            }
                            else {
                                return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1
                            }
                        }
                    }}
                    filterFns={{
                        dateFilter: (row, columnId, filterValue) => {
                            const filterArray = filterValue.replaceAll(',', ';').split(';');
                            const cellVal = row.getValue(columnId);
                        
                            if (typeof cellVal === 'string' || cellVal instanceof String) {
                                // Convert the cell value to a Date object
                                const cellDate = new Date(cellVal);
                        
                                if (!isNaN(cellDate.getTime())) { // Check if it's a valid date
                                    for (let item of filterArray) {
                                        const trimmedItem = item.trim();
                                        const filterDate = new Date(trimmedItem);
                        
                                        if (!isNaN(filterDate.getTime())) { // Check if filter date is valid
                                            // Compare the dates
                                            if (
                                                cellDate.getFullYear() === filterDate.getFullYear() &&
                                                cellDate.getMonth() === filterDate.getMonth() &&
                                                cellDate.getDate() === filterDate.getDate()
                                            ) {
                                                return true;
                                            }
                                        }
                                    }
                                }
                            }
                        
                            return false;
                        }
                        ,
                        multipleFilter: (row, columnId, filterValue) => {
                            const filterArray = filterValue.replaceAll(',', ';').split(';');
                            const cellVal = row.getValue(columnId);
                            const cellValStr = typeof cellVal === 'string' ? cellVal : String(cellVal);
                            for (let item of filterArray) {
                                if (cellValStr.toLowerCase().includes(item.toLowerCase())) {
                                    return true;
                                }
                            }
                            return false;
                        }
                    }}

                    // enableEditing
                    // editingMode='modal'
                    muiTablePaperProps={{
                        sx: {
                            backgroundColor: 'var(--bs-card-bg)',
                            border: 'none',
                            boxShadow: 'none'
                        }
                    }}
                    muiTableProps={{
                        className: 'table',
                        sx: {
                            borderCollapse: 'separate',
                            borderSpacing: '0px 4px',
                        }
                    }}
                    muiTableContainerProps={{
                        className: 'mb-2',
                        sx: {
                            backgroundColor: 'var(--bs-light)',
                            borderRadius: 'var(--bs-card-border-radius)',
                        }
                    }}
                    muiTableHeadRowProps={{
                        className: 'fw-bold bg-light',
                        sx: {
                            boxShadow: 'none'
                        }
                    }}
                    muiTableHeadCellProps={{
                        className: 'px-7 py-3 text-black',
                        sx: {
                            verticalAlign: 'middle',
                            backgroundColor: '#EBEBD3 !important',
                            outline: '10px',
                            outlineStyle: 'solid',
                            outlineColor: 'var(--bs-light)',
                            outlineOffset: '-5px',
                            borderRadius: '20px',
                        }
                    }}
                    muiTableBodyRowProps={{
                        hover: false,
                        sx: {
                            backgroundColor: 'transparent'
                        }
                    }}
                    muiTableBodyCellProps={{
                        className: 'bg-light bg-hover-secondary'
                    }}
                    muiTopToolbarProps={{
                        className: 'card text-light mb-2',
                        sx: {
                            boxShadow: 'none',
                            backgroundColor: '#143983'
                        },
                    }}
                    muiBottomToolbarProps={{
                        className: 'text-light mb-5 d-flex',
                        sx: {
                            boxShadow: 'none',
                            backgroundColor: '#143983',
                            borderRadius: 'var(--bs-card-border-radius)',
                            minHeight: '50px'
                        },
                    }}
                    muiSearchTextFieldProps={{
                        className: 'bg-light text-light text-muted'
                    }}
                    muiTableHeadCellFilterTextFieldProps={{
                        helperText: false,
                        focused: true,
                        inputProps: {
                            className: "text-black",
                        },
                        sx: {
                            color: 'black'
                        }
                    }}
                    muiTableHeadCellColumnActionsButtonProps={{
                        sx: {
                            color: 'black'
                        }
                    }}
                    muiTableHeadCellDragHandleProps={{
                        sx: {
                            color: 'black'
                        }
                    }}
                    icons={{
                        FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
                        FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
                        ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
                        FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                        FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                        SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
                        CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
                    }}
                    muiTablePaginationProps={{
                        className: 'text-white',

                    }}
                    enableGlobalFilter={false}
                    muiToolbarAlertBannerProps={{
                        className: 'text-white',
                        sx: {
                            backgroundColor: '#143983',
                        }
                    }}
                    muiToolbarAlertBannerChipProps={{
                        className: 'text-white'
                    }}
                />
            </div>
        </div >
    );
};

export default ProductsServicesTable;
















































































// import { useMemo, FC, useState } from 'react';
// import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';

// import { Box, IconButton } from '@mui/material';

// import { data } from './makeData';

// import { KTIcon } from '../../../_metronic/helpers'
// import { Archive, Delete, Edit } from '@mui/icons-material';
// import Cookies from 'js-cookie';
// import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
// import { BASE_URL } from '../../../utils';

// export const ProductsDetails = {
//     customerName: '',
//     serviceDesc: '',
//     startDate: '',
//     endDate: '',
//     amount: '',
//     category: '',
//     status: '',
// };

// const ProductsServicesTable = () => {


//     const [recievedData, setData] = useState(data)

//     const id = Cookies.get('userId');

//     const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getProductsOrServicesDetails&userId=${id}`);

//     const tableData = fetchedData || [];

//     console.log("tableData", tableData)


//     const columns = useMemo(
//         () => [
//             {
//                 accessorFn: (row) => `${row.customerName}`, //accessorFn used to join multiple data into a single cell
//                 id: 'name',
//                 header: 'Customer Name',
//                 size: 200,
//                 filterFn: 'multipleFilter',
//                 enablePinning: true,
//                 muiTableHeadCellProps: {
//                     style: {
//                         paddingLeft: '1rem'
//                     }
//                 },
//                 muiTableBodyCellProps: {
//                     style: {
//                         paddingLeft: '1rem'
//                     }
//                 }
//             },
//             {
//                 accessorKey: 'serviceDescription',
//                 header: 'Service Description',
//                 enablePinning: false,
//                 size: 250,
//             },
//             {
//                 accessorFn: (row) => new Date(row.startDate), //convert to Date for sorting and filtering
//                 id: 'StartDate',
//                 header: 'Start Date',
//                 filterFn: 'dateFilter',
//                 sortingFn: 'datetime',
//                 size: 200,
//                 enablePinning: false,
//                 Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
//                 Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
//             },
//             {
//                 accessorFn: (row) => new Date(row.endDate), //convert to Date for sorting and filtering
//                 id: 'EndDate',
//                 header: 'End Date',
//                 filterFn: 'dateFilter',
//                 sortingFn: 'datetime',
//                 size: 200,
//                 enablePinning: false,
//                 Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
//                 Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
//             },
//             {
//                 accessorKey: 'amount',
//                 filterFn: 'between',
//                 header: 'Amount',
//                 enablePinning: false,
//                 //custom conditional format and styling
//                 Cell: ({ cell }) => (
//                     <Box
//                         component="span"
//                         sx={(theme) => ({
//                             backgroundColor:
//                                 cell.getValue() < 50_000
//                                     ? theme.palette.error.dark
//                                     : cell.getValue() >= 50_000 &&
//                                         cell.getValue() < 75_000
//                                         ? theme.palette.warning.dark
//                                         : theme.palette.success.dark,
//                             borderRadius: '0.25rem',
//                             color: '#fff',
//                             maxWidth: '9ch',
//                             p: '0.25rem',
//                         })}
//                     >
//                         {cell.getValue()?.toLocaleString?.('en-US', {
//                             style: 'currency',
//                             currency: 'OMR',
//                             minimumFractionDigits: 0,
//                             maximumFractionDigits: 0,
//                         })}
//                     </Box>
//                 ),
//             },
//             {
//                 accessorKey: 'category',
//                 header: 'Category',
//                 enablePinning: false,
//                 size: 200,
//             },
//             {
//                 accessorKey: 'status',
//                 header: 'Status',
//                 enablePinning: false,
//                 size: 200,
//             },
//             {
//                 accessorKey: 'status',
//                 header: 'Status',
//                 enablePinning: false,
//                 size: 200,
//             },

//         ], []);

//     return (
//         <div className={`card`}>
//             {/* begin::Header */}
//             <div className='card-header border-0 pt-5 pb-2'>
//                 <h3 className='card-title align-items-start flex-column'>
//                     {/* <span className='card-label fw-bold fs-3 mb-1'>Leave Notifications</span>
//                 <span className='text-muted mt-1 fw-semibold fs-7'>[{data.length} entries]</span> */}
//                 </h3>
//                 <div className='card-toolbar'>
//                     <a href='/' className='btn btn-sm btn-light-primary'>
//                         <KTIcon iconName='plus' className='fs-2' />
//                         New Entry
//                     </a>
//                 </div>
//                 <div className='card-toolbar'>
//                     <a href='/' className='btn btn-sm btn-primary'>
//                         <KTIcon iconName='file-down' className='fs-2' />
//                         Export to Excel
//                     </a>
//                 </div>
//             </div>
//             {/* end::Header */}

//             <div className='card-body'>

//                 <MaterialReactTable
//                     columns={columns}
//                     data={tableData}
//                     enableDensityToggle={false}
//                     enableColumnFilters
//                     enableColumnOrdering
//                     enablePinning
//                     enableRowActions
//                     initialState={{
//                         showColumnFilters: false,
//                         // grouping: ["approval"],
//                         sorting: [
//                             { id: "name", desc: false },
//                         ],
//                         expanded: true,
//                         columnPinning: { left: ["name"] }
//                     }}
//                     positionToolbarAlertBanner="bottom"
//                     positionActionsColumn='last'
//                     renderRowActions={({ row, table }) => [
//                         <Box sx={{ justifyContent: 'center' }}>


//                             <IconButton
//                                 onClick={() => {
//                                     console.log("amount", tableData?.amount);
//                                 }}
//                             >
//                                 <Edit className='text-success' />
//                             </IconButton>

//                             <IconButton
//                                 onClick={() => {
//                                     tableData.splice(row.index, 1); //assuming simple data table
//                                     setData([...data]);
//                                     console.log("data.splice(row.index, 1", data);
//                                 }}
//                             >
//                                 <Delete className='text-danger' />
//                             </IconButton>
//                             <IconButton
//                                 onClick={() => {
//                                     data.splice(row.index, 1); //assuming simple data table
//                                     setData([...data]);
//                                 }}
//                             >
//                                 <Archive className='text-primary' />
//                             </IconButton>
//                         </Box>
//                     ]}
//                     displayColumnDefOptions={{
//                         'mrt-row-actions': {
//                             header: 'Approval Action',
//                             size: 100,
//                             muiTableHeadCellProps: {
//                                 className: 'px-7 py-4 text-black',
//                                 sx: {
//                                     justifyContent: 'center',
//                                     verticalAlign: 'middle',
//                                     backgroundColor: '#EBEBD3 !important',
//                                     outline: '6px',
//                                     outlineStyle: 'solid',
//                                     outlineColor: 'var(--bs-light)',
//                                     outlineOffset: '-5px',
//                                     borderRadius: '20px',
//                                 }
//                             }
//                         },
//                     }}
//                     sortingFns={{
//                         status: (rowA, rowB, columnID) => {
//                             const valA = rowA.getValue < String > (columnID)
//                             const valB = rowB.getValue < String > (columnID)
//                             if (valA === 'Pending' && valB !== 'Pending') {
//                                 return 1
//                             }
//                             else if (valB === 'Pending' && valA !== 'Pending') {
//                                 return -1
//                             }
//                             else {
//                                 return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1
//                             }
//                         }
//                     }}
//                     filterFns={{
//                         dateFilter: (row, columnId, filterValue) => {
//                             const filterArray = filterValue.replaceAll(',', ';').split(';')
//                             const cellVal = row.getValue < Date > (columnId)
//                             const monthName = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec']
//                             for (let item of filterArray) {
//                                 const itemArray = item.replaceAll(/\s+/g, '#').split('#')
//                                 console.log(filterArray, item, itemArray)
//                                 if (cellVal.getFullYear().toString() === itemArray[itemArray.length - 1])
//                                     for (let i = 0; i < itemArray.length - 1; i++) {
//                                         if (itemArray[i].includes(monthName[cellVal.getMonth()]))
//                                             return true
//                                     }
//                             }
//                             return false
//                         },
//                         multipleFilter: (row, columnId, filterValue) => {
//                             const filterArray = filterValue.replaceAll(',', ';').split(';')
//                             const cellVal = row.getValue < String > (columnId)
//                             for (let item of filterArray) {
//                                 if (cellVal.toLocaleLowerCase().includes(item.toLocaleLowerCase()))
//                                     return true
//                             }
//                             return false
//                         }
//                     }}

//                     // enableEditing
//                     // editingMode='modal'
//                     muiTablePaperProps={{
//                         sx: {
//                             backgroundColor: 'var(--bs-card-bg)',
//                             border: 'none',
//                             boxShadow: 'none'
//                         }
//                     }}
//                     muiTableProps={{
//                         className: 'table',
//                         sx: {
//                             borderCollapse: 'separate',
//                             borderSpacing: '0px 4px',
//                         }
//                     }}
//                     muiTableContainerProps={{
//                         className: 'mb-2',
//                         sx: {
//                             backgroundColor: 'var(--bs-light)',
//                             borderRadius: 'var(--bs-card-border-radius)',
//                         }
//                     }}
//                     muiTableHeadRowProps={{
//                         className: 'fw-bold bg-light',
//                         sx: {
//                             boxShadow: 'none'
//                         }
//                     }}
//                     muiTableHeadCellProps={{
//                         className: 'px-7 py-3 text-black',
//                         sx: {
//                             verticalAlign: 'middle',
//                             backgroundColor: '#EBEBD3 !important',
//                             outline: '10px',
//                             outlineStyle: 'solid',
//                             outlineColor: 'var(--bs-light)',
//                             outlineOffset: '-5px',
//                             borderRadius: '20px',
//                         }
//                     }}
//                     muiTableBodyRowProps={{
//                         hover: false,
//                         sx: {
//                             backgroundColor: 'transparent'
//                         }
//                     }}
//                     muiTableBodyCellProps={{
//                         className: 'bg-light bg-hover-secondary'
//                     }}
//                     muiTopToolbarProps={{
//                         className: 'card text-light mb-2',
//                         sx: {
//                             boxShadow: 'none',
//                             backgroundColor: '#143983'
//                         },
//                     }}
//                     muiBottomToolbarProps={{
//                         className: 'text-light mb-5 d-flex',
//                         sx: {
//                             boxShadow: 'none',
//                             backgroundColor: '#143983',
//                             borderRadius: 'var(--bs-card-border-radius)',
//                             minHeight: '50px'
//                         },
//                     }}
//                     muiSearchTextFieldProps={{
//                         className: 'bg-light text-light text-muted'
//                     }}
//                     muiTableHeadCellFilterTextFieldProps={{
//                         helperText: false,
//                         focused: true,
//                         inputProps: {
//                             className: "text-black",
//                         },
//                         sx: {
//                             color: 'black'
//                         }
//                     }}
//                     muiTableHeadCellColumnActionsButtonProps={{
//                         sx: {
//                             color: 'black'
//                         }
//                     }}
//                     muiTableHeadCellDragHandleProps={{
//                         sx: {
//                             color: 'black'
//                         }
//                     }}
//                     icons={{
//                         FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
//                         FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
//                         ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
//                         FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
//                         FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
//                         SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
//                         CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
//                     }}
//                     muiTablePaginationProps={{
//                         className: 'text-white',

//                     }}
//                     enableGlobalFilter={false}
//                     muiToolbarAlertBannerProps={{
//                         className: 'text-white',
//                         sx: {
//                             backgroundColor: '#143983',
//                         }
//                     }}
//                     muiToolbarAlertBannerChipProps={{
//                         className: 'text-white'
//                     }}
//                 />
//             </div>
//         </div>
//     );
// };

// export default ProductsServicesTable;