import { useEffect, useRef, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import { Step5 } from './steps/Step5'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { Form, Formik, FormikValues } from 'formik'
import { createAccountSchemas, ICreateAccount, inits } from './CreateAccountWizardHelper'
import { Grid } from '@mui/material'

const Register = () => {


  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)

  const [steps, setSteps] = useState("step1")
  const [personalDetails, setPersonalDetails] = useState()
  const [corporateDetails, setCorporateDetails] = useState()
  const [jobDescriptionBenifits, setJobDescriptionBenifits] = useState();
  const [familyDetails, setFamilyDetails] = useState<string[]>([]);


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])



  return (
    <>
      <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-1 me-2 svg-icon-muted text-black-50"></i>Registering a New Employee</h5>

      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >

        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-200px w-xxl-400px me-5'>

          <div className='card-body px-6 px-lg-6 px-xxl-6 py-20'>

            <div className='stepper-nav'>

              <div className='stepper-item current' data-kt-stepper-element='nav'>

                <div className='stepper-wrapper'>

                  <div style={{
                    background: steps == "step1" ? '#143983' : '#f1faff',
                    color: steps == "step1" ? '#fff' : 'red', display: 'flex', alignItems: 'center', justifyContent: 'center'
                  }} className='stepper-icon w-30px h-30px'>

                    <i className='stepper-check fas fa-check'></i>
                    <span style={{ color: steps == "step1" ? '#fff' : '#143983' }} className=''>1</span>

                  </div>
                  <div className='stepper-label'>
                    <h6 className='stepper-title fs-7'>Personal Details</h6>
                    <div className='stepper-desc fw-semibold fs-8'>Enter Employee Personal Details</div>
                  </div>
                  {/* end::Label*/}
                </div>

                <div className='stepper-line h-40px'></div>

              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>

                <div className='stepper-wrapper'>

                  <div style={{
                    background: steps == "step2" ? '#143983' : '#f1faff',
                    color: steps == "step2" ? '#143983' : '#fff'
                  }} className='stepper-icon w-30px h-30px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span style={{ color: steps == "step2" ? '#fff' : '#143983' }} className='stepper-number fs-7'>2</span>
                  </div>

                  <div className='stepper-label'>
                    <h6 className='stepper-title fs-7'>Corporate Details</h6>
                    <div className='stepper-desc fw-semibold fs-8'>Enter Employee Corporate Details</div>
                  </div>

                </div>

                <div className='stepper-line h-40px'></div>

              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>

                <div className='stepper-wrapper'>

                  <div style={{
                    background: steps == "step3" ? '#143983' : '#f1faff',

                  }} className='stepper-icon w-30px h-30px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span style={{ color: steps == "step3" ? '#fff' : '#143983' }} className='stepper-number fs-7'>3</span>
                  </div>

                  <div className='stepper-label'>
                    <h6 className='stepper-title fs-7'>Job Description & Benefits</h6>
                    <div className='stepper-desc fw-semibold fs-8'>Set Responsibilities & Benefits</div>
                  </div>

                </div>

                <div className='stepper-line h-40px'></div>

              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>

                <div className='stepper-wrapper'>

                  <div style={{
                    background: steps == "step4" ? '#143983' : '#f1faff',
                    color: steps == "step4" ? '#fff' : '#143983'
                  }} className='stepper-icon w-30px h-30px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span style={{ color: steps == "step4" ? '#fff' : '#143983' }} className='stepper-number fs-7'>4</span>
                  </div>

                  <div className='stepper-label'>
                    <h6 className='stepper-title fs-7'>Add Family Members</h6>
                    <div className='stepper-desc fw-semibold fs-8'>Enter Employee Family Members Details</div>
                  </div>

                </div>

                <div className='stepper-line h-40px'></div>

              </div>

              <div className='stepper-item' data-kt-stepper-element='nav'>

                <div className='stepper-wrapper'>

                  <div style={{
                    background: steps == "step5" ? '#143983' : '#f1faff',
                    color: steps == "step5" ? '#143983' : '#143983'
                  }} className='stepper-icon w-30px h-30px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span style={{ color: steps == "step5" ? '#fff' : '#143983' }} className='stepper-number fs-7'>5</span>
                  </div>

                  <div className='stepper-label'>
                    <h6 className='stepper-title fs-7'>Set Username & Password</h6>
                    <div className='stepper-desc fw-semibold fs-8'>Create System Username & Password</div>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

        <div className='card d-flex flex-row-fluid flex-center bg-white rounded '>
          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {
              () => (

                <>

                  <Grid container justifyContent="center" sx={{ mt: { xs: 1, sm: 1, md: 1, lg: 1 } }}>

                    <Grid container xs={11} sm={11} md={11} lg={11} sx={{ mt: 3 }}>

                      {
                        steps == "step1" && (

                          <Step1 setSteps={setSteps} personalDetails={personalDetails} setPersonalDetails={setPersonalDetails} />

                        )
                      }

                      {
                        steps === 'step2' && (
                          <Step2 setSteps={setSteps} corporateDetails={corporateDetails} setCorporateDetails={setCorporateDetails} />
                        )
                      }

                      {
                        steps === 'step3' && (
                          <Step3 setSteps={setSteps} setJobDescriptionBenifits={setJobDescriptionBenifits} />
                        )
                      }

                      {
                        steps === 'step4' && (
                          <Step4 setFamilyDetails={setFamilyDetails} setSteps={setSteps} />
                        )
                      }

                      {
                        steps === 'step5' && (
                          <Step5
                            setSteps={setSteps}
                            personalDetails={personalDetails}
                            corporateDetails={corporateDetails}
                            jobDescriptionBenifits={jobDescriptionBenifits}
                            familyDetails={familyDetails}
                          // setSteps={setSteps}
                          />
                        )
                      }

                    </Grid>
                  </Grid>

                </>
              )
            }
          </Formik>
        </div>
      </div >
    </>
  )
}

export { Register }
