
// import { useState, useEffect } from "react";
// import axios from "axios";
// import { BASE_URL } from "../../utils";


// const useFetchCommon = (url) => {
//     const [commonDataFetch, setFetchedData] = useState();

//     useEffect(() => {
//         const fetchData = async () => {

//             const response = await axios.get(`${BASE_URL + url}`,
//             ).then((res) => {
//                 // console.log("response...............from hook;", res)
//                 setFetchedData(res?.data?.Data)
//             })

//         };

//         fetchData();
//     }, []);

//     return { commonDataFetch };
// };

// export default useFetchCommon;


import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils";

const useFetchCommon = (url, dependencies = []) => {
    const [commonDataFetch, setFetchedData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL + url}`);
                setFetchedData(response?.data?.Data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [url, ...dependencies]); // Include dependencies in the useEffect dependency array

    return { commonDataFetch };
};

export default useFetchCommon;
