import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils";
import Cookies from "js-cookie";

const useFetchData = (url, dependencies = []) => {

    const [fetchedData, setFetchedData] = useState();
    const [isLoading,setIsloading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const id = Cookies.get('userId');
            const token = Cookies.get("token");

            const headers = {
                'token': token,
                'Content-Type': 'application/json'
            };

            try {
                const response = await axios.get(`${BASE_URL + url}&userId=${id}`, { headers });
                setFetchedData(response?.data?.Data);
                setIsloading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, dependencies);

    return { fetchedData, refetch: fetchedData,isLoading };
};

export default useFetchData;
