import React, { useEffect, useState } from 'react'
import { GET_ALL_EMPLOYEE_FILES, GET_EMPLOYEE_FAMILYMEMBERS_FOLDERS } from '../../../../apis';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Card4 } from '../../../../_metronic/partials/content/cards/Card4';
import { KTIcon } from '../../../../_metronic/helpers';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import NoFiles from '../../../../_metronic/helpers/components/NoFiles';


const ViewEmployeeFiles = () => {
    const [employeesFolders, setEmployeesFolders] = useState([])
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredFiles = employeesFolders?.filter((file) =>
        file?.fileName?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const { id } = useParams()

    useEffect(() => {
        axios.get(`${GET_ALL_EMPLOYEE_FILES}&familyMemberId=${id}`).then((response) => {
            if (response.data && response.data.Data) {
                setEmployeesFolders(response?.data?.Data)
            }
        });
    }, []);

    return (
        <>
            <div className='d-flex flex-wrap flex-stack mb-6'>
                <div className='d-flex my-2'>
                    <div className='d-flex align-items-center position-relative me-4'>
                        <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                        <input
                            type='text'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder='Search'
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
                <button className='btn btn-primary btn-sm' onClick={()=>navigate(-1)}>Back</button>
            </div>

            {!filteredFiles || filteredFiles?.length==0 && <NoFiles/>}

            <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
                {filteredFiles?.map((file, index) => (
                    <div className='col-12 col-sm-12 col-xl' key={index} >
                        <Card4
                            icon='/media/svg/files/pdf.svg'
                            title={file?.fileName}
                            link={`${BASE_URL_TWO}${file?.filePath}`}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default ViewEmployeeFiles